import React from "react";
import { withNavBars } from "../../HOCs";
import Diagnosis from "./diagnosis";
import { Grid, withTheme } from "@material-ui/core";
import Patiantprofile from "./patientprofile";
import Cards from "./cards";

import PatientHistory from "./patientHistory";
import { Paper } from "@material-ui/core";
function PatientContent(props) {
  const { patientId } = props;
  return (
    <div>
       <Grid container >
        
      
        <Grid item xs={6}style={{display:"flex", justifyContent:"center"}}>
      
          <Patiantprofile patientId={patientId} />
       
        </Grid>
        <Grid item xs={6}style={{ justifyContent:"center"}}>
        <Diagnosis
          PatientName={props?.selectedPatient?.PatientName}
          patientId={props?.selectedPatient?.patientId}
          clientId={props?.selectedPatient?.clientId}
        />
        </Grid>
      </Grid>
      
      <Grid container >
        <Grid item xs={6} style={{display:"flex", justifyContent:"center"}}>
          <PatientHistory patientdata={props?.selectedPatient} />
        </Grid>
        <Grid item xs={6} style={{display:"flex", justifyContent:"center"}}>
          <Cards patientId={patientId}/>
        </Grid>
      </Grid>
      
    </div>
  );
}

export default PatientContent;
