import React from "react";
import { withNavBars } from "../../HOCs";
import { withStyles } from "@material-ui/core";
import { actions } from "surveillance-binder";
import { connect } from "react-redux";
import { Notify } from "./notify";
import { giveMeRoleDataSecurity } from "../../utils";

const styles = (theme) => ({
  root: {
    backgroundColor: "#F8F8F8",
  },
});

class NotifyParent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sort: "all",
      icd10: [],
      classification: [],
      isFilterApplied: false,
    };
  }

  async componentDidMount() {
    await this.props.GET_FORM_DATA();
    this.getNotificationForms();
  }

  getNotificationForms = (searchKey = "", created_at = false) => {
    const roleDataSecurity = giveMeRoleDataSecurity(this.props.userData);
    const createdBy = this.props.userData.user_profile_id;

    const { classification, icd10 } = this.state;

    let sort = {
      sortField: "form_name",
      sortDirection: "ASC",
      forms: roleDataSecurity.form?.[0] === "all" ? [] : roleDataSecurity.form,
      countryIds:
        roleDataSecurity.country?.[0] === "all" ? [] : roleDataSecurity.country,
    };

    if (created_at) {
      sort.sortField = "updated_at";
      sort.sortDirection = "DESC";
    }

    let formTypeId = "";

    if (this.state.sort !== "all") {
      formTypeId = this.state.sort;
    }

    if (icd10.length) sort.icd10 = icd10.map((_) => _.value);

    if (classification.length)
      sort.classification = classification.map((_) => _.value);

    if (createdBy) {
      sort.created_by = createdBy;
    }

    this.props.GET_ALL_NOTIFIABLE_FORMS({
      formName: searchKey.trim(),
      formTypeId,
      ...sort,
    });
  };

  applyFilter = (data, isFilterApplied = false) => {
    this.setState(
      {
        ...data,
        isFilterApplied,
      },
      () =>
        this.getNotificationForms(
          document.getElementById("ippc_search_bar").value
        )
    );
  };

  render() {
    const { classes, getAllNotifiableForms, userData } = this.props;
    return (
      <div className={classes.root}>
        <Notify
          {...getAllNotifiableForms}
          {...this.state}
          userData={userData.data}
          applyFilter={this.applyFilter}
          getNotificationForms={this.getNotificationForms}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    getAllNotifiableForms: state?.workflowSlice?.getAllNotifiableForms,
    // userData: state.authSlice.login.data ?? {},
    userData: state.formSlice.getFormData.data ?? {},
  };
};

export default connect(
  mapStateToProps,
  actions
)(withNavBars(withStyles(styles)(NotifyParent)));
