import React from 'react'
import { SvgIcon, } from '@material-ui/core'


export function Enterprise() {

    return (
        <SvgIcon viewBox="0 0 9.62 14.921" style={{ fontSize: '1.25rem', marginRight: "8px" }}>
            <g xmlns="http://www.w3.org/2000/svg" id="Group_90906" data-name="Group 90906" transform="translate(-104 -146)">
                <path id="icons8-organization_3_" data-name="icons8-organization (3)" d="M3.5,3A1.53,1.53,0,0,0,2,4.556V17H17V7.667a1.54,1.54,0,0,0-1.5-1.556h-3V4.556A1.53,1.53,0,0,0,11,3ZM5,6.111H6.5V7.667H5Zm3,0H9.5V7.667H8Zm4.5,1.556h3v7.778h-3V13.889H14V12.333H12.5V10.778H14V9.222H12.5ZM5,9.222H6.5v1.556H5Zm3,0H9.5v1.556H8ZM5,12.333H6.5v1.556H5Zm3,0H9.5v1.556H8Z" transform="translate(99 143)" />
            </g>
        </SvgIcon>
    )
}