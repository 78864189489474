import * as React from "react";
import PropTypes from "prop-types";

const HoldingHandIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={17.705}
    height={8.545}
    {...props}
  >
    <path
      d="M5.373 0q-.2 0-.4.015a7.3 7.3 0 0 0-3.1.922l-.026.016-1.572 1a.557.557 0 1 0 .6.938l1.553-.988a6.186 6.186 0 0 1 5.333-.347l.018.007 3.734 1.352a.575.575 0 0 1 .32.344 1.044 1.044 0 0 1 .051.515.589.589 0 0 1-.233.326.686.686 0 0 1-.563.058l-4.022-.945a.556.556 0 1 0-.255 1.082l4.018.943a1.854 1.854 0 0 0 1.4-.2 1.631 1.631 0 0 0 .271-.216.556.556 0 0 0 .2-.072l2.882-1.712A.555.555 0 0 0 15.638 3a.594.594 0 0 1 .734.934l-3.759 2.909a1.806 1.806 0 0 1-1.535.326h-.006l-6.86-1.583a.556.556 0 0 0-.4.056L.482 7.495a.556.556 0 1 0 .54.971L4.17 6.718l6.652 1.535h-.007a2.921 2.921 0 0 0 2.479-.528l3.765-2.913h.006a1.706 1.706 0 0 0-2.108-2.683l.056-.038-2.038 1.215a2.056 2.056 0 0 0-.121-.457 1.733 1.733 0 0 0-.919-.952l-.027-.011L8.165.526A7.3 7.3 0 0 0 5.373 0Z"
      fill={props.color}
    />
  </svg>
);

export default HoldingHandIcon;

HoldingHandIcon.defaultProps = {
  color: "#2a3c50",
};

HoldingHandIcon.propTypes = {
  color: PropTypes.string,
};
