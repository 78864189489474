import * as React from "react";
import PropTypes from "prop-types";

const HandWithPillIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={14} {...props}>
    <path
      data-name="icons8-hand-with-a-pill (2)"
      d="M9.811 0a2.209 2.209 0 0 1 0 4.417H4.35A2.209 2.209 0 0 1 4.35 0ZM6.534 1.1H4.35a1.104 1.104 0 0 0 0 2.209h2.184ZM1.578 5.529a1.935 1.935 0 0 1 1.139.426c.369.277 1.48 1.116 2.442 1.842a1.645 1.645 0 0 1 1.557-1.17h1.6a8.156 8.156 0 0 0 1.911-.172 6 6 0 0 1 1.468-.2 4.25 4.25 0 0 1 3.179 1.764 8.339 8.339 0 0 1 1.077 1.691.555.555 0 0 1-.271.732.544.544 0 0 1-.724-.274 7.245 7.245 0 0 0-.922-1.441 3.237 3.237 0 0 0-2.341-1.367 4.778 4.778 0 0 0-1.242.172 9.522 9.522 0 0 1-2.138.2h-1.6a.541.541 0 0 0-.546.552.548.548 0 0 0 .193.422l.015.011.031.026a.547.547 0 0 0 .307.092h2.549a.552.552 0 0 1 0 1.1H6.716a1.609 1.609 0 0 1-.925-.295.546.546 0 0 1-.065-.042S2.611 7.25 2.066 6.841a.879.879 0 0 0-.5-.207.415.415 0 0 0-.335.155.546.546 0 0 0 0 .771c1.95 1.658 3.176 2.762 3.986 3.409s1.164.812 1.5.812c1.33 0 2.8-.368 4.369-.368a2.346 2.346 0 0 1 1.893.957 2.9 2.9 0 0 1 .457.934.556.556 0 0 1-.144.54.542.542 0 0 1-.537.136.55.55 0 0 1-.378-.408 1.727 1.727 0 0 0-.271-.538 1.152 1.152 0 0 0-1.02-.515c-1.386 0-2.853.368-4.369.368a3.577 3.577 0 0 1-2.178-1.049c-.862-.688-2.073-1.782-4.011-3.429a.548.548 0 0 1-.067-.056s0-.006-.006-.009l-.019-.02a.553.553 0 0 1-.12-.2 1.657 1.657 0 0 1 .147-2.119 1.572 1.572 0 0 1 1.115-.476Z"
      fill={props.color}
    />
  </svg>
);

export default HandWithPillIcon;

HandWithPillIcon.defaultProps = {
  color: "#6a7888",
};

HandWithPillIcon.propTypes = {
  color: PropTypes.string,
};
