import * as React from "react";
import PropTypes from "prop-types";

const ReplyArrowIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={9.502}
    height={10.035}
    {...props}
  >
    <path
      d="M6.873 0a.528.528 0 0 1 .363.16l2.112 2.111a.528.528 0 0 1 0 .746L7.236 5.129a.528.528 0 1 1-.747-.746l1.21-1.21H3.168a2.1 2.1 0 0 0-2.112 2.111V9.51A.528.528 0 1 1 0 9.51V5.284a3.175 3.175 0 0 1 3.168-3.167H7.7L6.489.91a.528.528 0 0 1 .384-.906Z"
      fill={props.color}
    />
  </svg>
);

export default ReplyArrowIcon;

ReplyArrowIcon.defaultProps = {
  color: "#fff",
};

ReplyArrowIcon.propTypes = {
  color: PropTypes.string,
};
