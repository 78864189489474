import React from 'react'
import { SvgIcon, } from '@material-ui/core'



export function FacilityCode(props) {

    return (
        <SvgIcon viewBox="0 0 9.62 14.921" style={{ fontSize: '1rem', marginRight: "8px" }}>
            <path d="M9,4A1.716,1.716,0,0,0,7.286,5.705v.568h-3A1.289,1.289,0,0,0,3,7.553v5.968A1.289,1.289,0,0,0,4.286,14.8h9.429A1.289,1.289,0,0,0,15,13.521V7.553a1.289,1.289,0,0,0-1.286-1.279h-3V5.705A1.716,1.716,0,0,0,9,4Zm0,.853a.849.849,0,0,1,.857.853V6.984a.136.136,0,0,1-.143.142H8.286a.136.136,0,0,1-.143-.142V5.705A.849.849,0,0,1,9,4.853ZM4.286,7.126H7.3a1,1,0,0,0,.985.853H9.714a1,1,0,0,0,.985-.853h3.015a.421.421,0,0,1,.429.426v5.968a.421.421,0,0,1-.429.426H4.286a.421.421,0,0,1-.429-.426V7.553A.421.421,0,0,1,4.286,7.126ZM7.714,9.4a.426.426,0,1,0,0,.853h2.857a.426.426,0,1,0,0-.853Zm0,1.989a.426.426,0,1,0,0,.853h2.857a.426.426,0,1,0,0-.853Z" transform="translate(-2.875 -3.875)" fill="#4448C2" />
        </SvgIcon>
    )
}