import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { ClientDetails, ClientMapping } from "./steps";
import TaskAndDocument from "../taskAndDocument";
import { Box, Tooltip } from "@material-ui/core";
import { AlertContext, BackdropContext, DialogContext } from "../../contexts";
import { AlertProps } from "../../utils";
import { actions } from "surveillance-binder";
import { useDispatch } from "react-redux";
import ManageFacility from "../manageFacility";
import { CreateClientAdmin } from "./steps/CreateClientAdmin";
import ManageLevel from "../manageLevel";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
  },
  stepper: {
    backgroundColor: "transparent",
    padding: "8px",
    // height: "100%",
  },
  button: {
    // marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
}));

function getSteps() {
  return [
    "Client Details",
    "Level",
    "Manage Facility",
    "Task and Workflow",
    "Disease And Form Mapping",
    "Create Client Admin",
  ];
}

function getStepContent(
  step,
  state,
  updateState,
  isEdit,
  clientId,
  editData,
  mappingEdit,
  clientAdmin,
  setState,
  setClientEdit,
  upsertDiseaseMappedWithClient
) {
  switch (step) {
    case 0:
      return (
        <ClientDetails
          state={state}
          updateState={updateState}
          isEdit={isEdit}
          editData={editData}
        />
      );
    case 1:
      return <ManageLevel clientId={clientId} tenantid={editData?.tenantid} />;
    case 2:
      return (
        <ManageFacility clientId={clientId} tenantid={editData?.tenantid} />
      );
    case 3:
      return (
        <TaskAndDocument clientId={clientId} tenantid={editData?.tenantid} />
      );
    case 4:
      return (
        <ClientMapping
          isEdit={isEdit}
          clientId={clientId}
          upsertDiseaseMappedWithClient={upsertDiseaseMappedWithClient}
          tenantid={editData?.tenantid}
        />
      );
    case 5:
      return (
        <>
          <CreateClientAdmin
            state={clientAdmin}
            setState={setState}
            clientId={clientId}
            isEdit={isEdit}
            setClientEdit={setClientEdit}
          />
        </>
      );
    default:
      return "Unknown step";
  }
}

export default function VerticalLinearStepper(props) {
  const classes = useStyles();
  const {
    state,
    updateState = () => false,
    isEdit,
    setClientId = () => false,
    clientId,
    editData,
    onBackBtnClicked = () => false,
    setClientEdit = () => false,
  } = props;
  const [activeStep, setActiveStep] = React.useState(0);
  // const [isEdit, setClientEdit] = React.useState(false);
  const steps = getSteps();
  const dispatch = useDispatch();
  const alertContext = React.useContext(AlertContext);
  const backdropContext = React.useContext(BackdropContext);

  const [skipped, setSkipped] = React.useState(new Set());

  const isStepOptional = (step) => {
    return step === (3 || 4 || 5);
  };
  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const [mappingEdit, setMappingEdit] = React.useState(false);

  const [clientAdmin, setState] = React.useState({
    prefix: "",
    firstName: "",
    middleName: "",
    lastName: "",
    gender: "",
    email: "",
    role: [],
    tenantid: "",
    clientid: "",
  });

  React.useEffect(() => {
    if (editData) {
      setState({
        ...clientAdmin,
        tenantid: editData?.tenantid,
        clientid: editData?._id,
      });
    }
    //eslint-disable-next-line
  }, [editData]);

  const handleNext = async () => {
    // debugger;
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    if (!isEdit && !isEdit) {
      let moveNextStep = await performActionOfEachStep(activeStep);
      if (moveNextStep) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
      }
    } else if (activeStep === 4 && !mappingEdit) {
      let moveNextStep = await performActionOfEachStep(activeStep);
      if (moveNextStep) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
      }
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped(newSkipped);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const performActionOfEachStep = async (step) => {
    // debugger;
    switch (step) {
      case 0:
        let canIMoveNxtStep = await upsertUpdateClient();
        return canIMoveNxtStep;
      case 1:
        return true;

      case 2:
        return true;

      case 3:
        return true;

      case 4:
        // let canIMoveNextStep = await upsertDiseaseMappedWithClient(row);
        return true;

      case 5:
        return true;

      default:
        return true;
    }
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  const validArrayOfObj = (list) => {
    let haslist = list.map((l) => {
      delete l.msg;
      let isValid = Object.values(l).map((val) =>
        val?.trim()?.length === 0 ? false : true
      );
      let val = !isValid?.includes(false);
      return val;
    });
    return !haslist?.includes(false);
  };

  const Valid = () => {
    let hasClientName = state?.client_name.trim().length === 0 ? false : true;
    let hasClientType = state?.client_name.trim().length === 0 ? false : true;
    // let hasContact = state?.contact_details?.length === 0 ? false : true;
    let hasContact = validArrayOfObj(state?.contact_details);
    let hasAddress = state?.address?.length === 0 ? false : true;

    if (hasClientName && hasClientType && hasContact && hasAddress) {
      return true;
    } else {
      return false;
    }
  };
  const upsertUpdateClient = async () => {
    let valid = Valid(state);
    if (
      (state?.contact_details.filter((e) => e?.error === "true")).length === 0
    ) {
      backdropContext.setBackDrop({
        ...backdropContext,
        open: true,
        message: `${isEdit || isEdit ? "Updating" : "Creating new"} client....`,
      });
    }
    if (
      (state?.contact_details.filter((e) => e?.error === "true")).length === 0
    ) {
      if (valid) {
        try {
          // debugger;
          let checkDuplicates = await checkDuplicatesClient(
            state?.client_name,
            editData?.client_name
          );

          if (checkDuplicates) {
            let res = await dispatch(actions.UPSERT_UPDATE_CLIENT({ state }));
            // have to store client id
            if (!res.payload.error) {
              alertContext.setSnack({
                ...alertContext,
                open: true,
                severity: AlertProps.severity.success,
                msg: isEdit
                  ? "Client datails updated successfully"
                  : "Client details added successfully",
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.center,
              });
              let clientId = res?.payload?.data?._id;

              if (!isEdit) {
                let clientKey = res?.payload?.data?._key;
                let tenantid = res?.payload?.data?.tenantid;
                setClientId(clientId);
                updateState("_key", clientKey);
                updateState("tenantid", tenantid);
                setClientEdit(true);
              }
              await dispatch(actions.GET_SINGLE_CLIENT({ id: clientId }));
              backdropContext.setBackDrop({
                ...backdropContext,
                open: false,
              });
              return true;
            } else {
              alertContext.setSnack({
                ...alertContext,
                open: true,
                severity: AlertProps.severity.error,
                msg: "Something went wrong! Try Again",
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.center,
              });
              backdropContext.setBackDrop({
                ...backdropContext,
                open: false,
              });
              return false;
            }
          } else {
            alertContext.setSnack({
              ...alertContext,
              open: true,
              severity: AlertProps.severity.error,
              msg: "Client name already exists !",
              vertical: AlertProps.vertical.top,
              horizontal: AlertProps.horizontal.center,
            });
            backdropContext.setBackDrop({
              ...backdropContext,
              open: false,
            });
          }
        } catch (error) {
          console.log(error);
          backdropContext.setBackDrop({
            ...backdropContext,
            open: false,
          });
        }
      } else {
        alertContext.setSnack({
          ...alertContext,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Required fields are empty",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
        backdropContext.setBackDrop({
          ...backdropContext,
          open: false,
        });
        return false;
      }
    } else {
      alertContext.setSnack({
        ...alertContext,
        open: true,
        severity: AlertProps.severity.error,
        msg: "Please Check The Value Before Moving To Next Page",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
      });
    }
  };

  const checkDuplicatesClient = async (client_name, existingName) => {
    try {
      if (state?._key) {
        let isClientnameChange = client_name === existingName ? false : true;
        if (isClientnameChange) {
          let res = await dispatch(
            actions.CHECK_DUPLICATE_DATA({ state: client_name })
          );

          let hasDuplicate = res?.payload?.data?.length > 0 ? false : true;
          return hasDuplicate;
        } else {
          return true;
        }
      } else {
        let res = await dispatch(
          actions.CHECK_DUPLICATE_DATA({ state: client_name })
        );

        let hasDuplicate = res?.payload?.data?.length > 0 ? false : true;
        return hasDuplicate;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const upsertDiseaseMappedWithClient = async (
    state,
    mappingMasterName,
    mappingMasterId,
    tenantid
  ) => {
    try {
      let param = {
        client_id: clientId,
        mappingTable: { ...state },
        mapping_id: "",
      };
      let repoData = {
        mappingMasterName,
        mappingMasterId,
      };
      let hasNoDuplicate = await checkDuplicatMappedDisease(state, clientId);

      if (hasNoDuplicate) {
        let res = await dispatch(
          actions.UPSERT_UPDATE_DISEASE_MAPPING({
            state: param,
            repoData: repoData,
            tenantid: tenantid,
          })
        );
        if (!res?.payload?.error) {
          alertContext.setSnack({
            ...alertContext,
            open: true,
            severity: AlertProps.severity.success,
            msg: "Mapping added successfully",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
          });
          await dispatch(
            actions.GET_MAPPED_DISEASE_WITH_CLIENT({ id: clientId })
          );
        } else {
          alertContext.setSnack({
            ...alertContext,
            open: true,
            severity: AlertProps.severity.error,
            msg: "Something went wrong! Try Again",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
          });
        }
      } else {
        alertContext.setSnack({
          ...alertContext,
          open: true,
          severity: AlertProps.severity.warning,
          msg: "Mapping already exist!",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      }

      // have to store client id

      // console.log(state);
    } catch (error) {
      console.log(error);
    }
  };

  const checkDuplicatMappedDisease = async (state, clientId) => {
    let diseaseId = state?.disease?._id;
    let selectedOrgId = state?.selectedOrg?._id;
    let formId = state?.clonedFormFrom?._id;

    let res = await dispatch(
      actions.CHECK_DUPLICATE_FOR_MAPPED_DISEASE({
        diseaseId,
        selectedOrgId,
        formId,
        clientId,
      })
    );

    if (res.payload.data.length > 0) {
      return false;
    } else {
      return true;
    }
  };

  const onFinishClicked = async (state) => {
    let valid = {
      prefix: state?.prefix,
      firstName: state?.firstName,
      //   middleName: state?.middleName,
      lastName: state?.lastName,
      gender: state?.gender,
      email: state?.email,
      //   role: data,
    };
    backdropContext.setBackDrop({
      ...backdropContext,
      open: true,
      message: `${isEdit ? "Updating" : "Creating new"} client admin....`,
    });
    if (
      Object.values(valid).every(
        (e) => e !== undefined && e.trim().length !== 0
      ) &&
      (state?.role?.length > 0 || isEdit)
    ) {
      let clientKey = editData?._key;
      let isResetEmail = !state?._key
        ? true
        : state?.editEmail === state?.email
        ? false
        : true;
      // let isResetEmail = true;
      let res = await dispatch(
        actions.UPSERT_UPDATE_PERSON({
          state: state,
          _key: clientKey,
          isResetEmail: isResetEmail,
        })
      );
      // console.log(res);
      if (res?.payload?.data?.Code === 201) {
        if (res?.payload?.additionalData) {
          let emailUpdate =
            res?.payload?.additionalData?.Code === "201" ? true : false;
          alertContext.setSnack({
            ...alertContext,
            open: true,
            severity: emailUpdate
              ? AlertProps.severity.info
              : AlertProps.severity.warning,
            msg: emailUpdate
              ? isEdit
                ? "Person updated successfully and check your email!"
                : "Person added successfully and check your email!"
              : `${res?.payload?.data?.Result ?? "Try again"} and ${
                  res?.payload?.additionalData?.error
                }`,
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
          });
          if (emailUpdate) {
            onBackBtnClicked();
          }

          backdropContext.setBackDrop({
            ...backdropContext,
            open: false,
          });
        } else {
          alertContext.setSnack({
            ...alertContext,
            open: true,
            severity: AlertProps.severity.success,
            msg: isEdit
              ? "Person updated successfully!"
              : "Person added successfully!",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
          });
          onBackBtnClicked();
          backdropContext.setBackDrop({
            ...backdropContext,
            open: false,
          });
        }
      } else {
        alertContext.setSnack({
          ...alertContext,
          open: true,
          severity: AlertProps.severity.error,
          msg: res?.payload?.data?.error,
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
        backdropContext.setBackDrop({
          ...backdropContext,
          open: false,
        });
      }
      //   console.log("client person", state);
    } else {
      if (state?.role?.length === 0) {
        alertContext.setSnack({
          ...alertContext,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Unable to get Role data",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
        backdropContext.setBackDrop({
          ...backdropContext,
          open: false,
        });
      } else {
        alertContext.setSnack({
          ...alertContext,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Required fields are empty",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
        backdropContext.setBackDrop({
          ...backdropContext,
          open: false,
        });
      }
    }
  };

  return (
    <div className={classes.root}>
      {/* <Stepper
        className={classes.stepper}
        activeStep={activeStep}
        orientation="vertical"
      >
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
            <StepContent style={{ height: "100%" }}>
              {getStepContent(index, state, updateState, isEdit)}
              <div className={classes.actionsContainer}>
                <div>
                  <Button
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    className={classes.button}
                  >
                    Back
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                    className={classes.button}
                  >
                    {activeStep === steps.length - 1 ? "Finish" : "Next"}
                  </Button>
                </div>
              </div>
            </StepContent>
          </Step>
        ))}
      </Stepper> */}

      <Stepper
        activeStep={activeStep}
        style={{ height: "52px", padding: "0px" }}
      >
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <Box
        style={{
          height: "calc(100% - 111px)",
          overflowY: "auto",
          boxSizing: "border-box",
        }}
      >
        {getStepContent(
          activeStep,
          state,
          updateState,
          isEdit,
          clientId,
          editData,
          mappingEdit,
          clientAdmin,
          setState,
          setClientEdit,
          upsertDiseaseMappedWithClient
        )}
      </Box>
      <Box
        style={{
          display: "flex",
          height: "52px",
          alignItems: "center",
        }}
      >
        <div style={{ flex: "1 1 auto" }}>
          <Button
            color="inherit"
            disabled={activeStep === 0}
            onClick={handleBack}
          >
            Back
          </Button>
        </div>

        {isStepOptional(activeStep) && (
          <Button color="inherit" onClick={handleSkip}>
            Skip
          </Button>
        )}

        {(isEdit || clientId || state?._key) && activeStep === 0 && (
          <Tooltip title="Update Client Details" placement="top" arrow>
            <Button
              variant="contained"
              color="primary"
              onClick={() => upsertUpdateClient()}
              style={{ marginRight: "4px" }}
            >
              Update
            </Button>
          </Tooltip>
        )}

        {/* {mappingEdit && activeStep === 4 && (
          <Tooltip title="Update Mapping Details" placement="top" arrow>
            <Button
              variant="contained"
              color="primary"
              onClick={() => upsertDiseaseMappedWithClient(row)}
              style={{ marginRight: "4px" }}
            >
              Update
            </Button>
          </Tooltip>
        )} */}

        <Button
          variant="contained"
          color="primary"
          onClick={
            activeStep === steps.length - 1
              ? () => onFinishClicked(clientAdmin)
              : handleNext
          }
          className={classes.button}
        >
          {activeStep === steps.length - 1 ? "Finish" : "Next"}
        </Button>
      </Box>
    </div>
  );
}
