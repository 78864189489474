import React, { useEffect, useState } from "react";
import { Grid, Card } from "@material-ui/core";
import { makeStyles, withTheme } from "@material-ui/core/styles";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import FastfoodIcon from "@material-ui/icons/Fastfood";
import LaptopMacIcon from "@material-ui/icons/LaptopMac";
import HotelIcon from "@material-ui/icons/Hotel";
import RepeatIcon from "@material-ui/icons/Repeat";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { withRouter } from "react-router-dom";
import Vital from "../../assets/Vitals.svg";
import Diagnosis from "../../assets/Diagnosis.svg";
import Treatment from "../../assets/Treatment.svg";
import MedicationOrder from "../../assets/Medical Order.svg";
import LabOrder from "../../assets/Lab Order.svg";
import Radiologyorder from "../../assets/Radiology Order.svg";
import { Dialog, IconButton } from "@material-ui/core";
import FormRenderer from "../Taskviewer-formRenderer";
import axios from "axios";
import "./patientHistory.css";
import { aqlQuery, months } from "../../utils";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import FilterListIcon from "@material-ui/icons/FilterList";
const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "6px 16px",
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
  timeline: {
    // transform: "rotate(90deg)",
    //width: "600px",
    display: "flow-root !important",
    height: "calc(100% - 50px)",
    overflowY: "scroll",
  },
  timelineContentContainer: {
    // textAlign: "left",
    // "& .MuiTimelineOppositeContent-root": {
    //     marginRight: "0px",
    //     flex: 0
    // },
    // flex: 0
  },
  timelineContent: {
    // display: "inline-block",
    // transform: "rotate(-90deg)",

    textAlign: "center",
    padding: "5px",
  },
  // timelineIcon: {
  //     transform: "rotate(-90deg)",
  // },
  TimelineOppositeContent: {
    flex: 0,
    display: "flex",
    alignItems: "center",
    //  marginRight: "0px",
    //  transform: "rotate(90deg)",
  },
  timelineConnector: {
    backgroundColor: theme.palette.primary.main,
  },
  Autocomplete: {
    "& .MuiFormControl-marginNormal": {
      marginTop: "0px",
      marginBottom: "0px",
    },
  },
  mainCard: {
    display: "flex",
    height: "28rem",
    //width: "37rem",
    margin: "0.8rem",
  },
  mainGrid: {
    display: "flex",
    flexGrow: 1,
    paddingBottom: "20px",
  },
  detailGrid: {
    display: "flex",
    justifyContent: "center",
    marginTop: "20px",
    gap: "10px",
    marginBottom: "5px",
    //justifyContent: "flex-start",
    //padding: "1rem 2rem 1rem 2rem ",
  },
  "& .MuiTimeline-root": {
    display: "flow-root !important",
  },
}));
const PatientHistory = (props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [formRendering, setFormRendering] = React.useState({});
  const [history, setHistory] = useState([]);
  const [data, setData] = useState({
    year: new Date().getFullYear(),
    month: months?.[new Date().getMonth()],
  });

  // console.log("months?.[new Date().getMonth() + 1]" ,new Date().getMonth())
  // console.log("data?.month" ,data?.month)
  // console.log("months.indexOf(data?.month)" ,months.indexOf(data?.month))

  function patientDtails(year, month) {
    let monthValue = months.indexOf(data?.month);
    monthValue = monthValue + 1;
    var config = {
      method: "POST",
      url: `${process.env.REACT_APP_REGISTRY_URL}/getpatienttimeline/v1`,
      headers: { "Content-Type": "application/json" },
      data: {
        db_name: process.env.REACT_APP_DBNAME,
        entity: "patients",
        patientid: props?.patientdata?.patientId,
        //    "patients/b6ba769e-fad8-4074-85ee-60c97b70a5a5",
        Year: year ?? data?.year,
        month: month ?? monthValue,
      },
    };
    axios(config)
      .then((res) => {
        debugger;
        setHistory(res?.data);
      })
      .catch((err) => {});
  }
  useEffect(async () => {
    patientDtails();
  }, []);

  const getFormFromExecuteAql = async (doc, ticketId) => {
    let params = {
      db_name: `${process.env.REACT_APP_DBNAME}`,
      query: aqlQuery.getFormData(doc.formId, ticketId),
    };
    let config = {
      method: "post",
      url: `${process.env.REACT_APP_READ_DOCUMENT}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: params,
    };

    await axios(config)
      .then((res) => {
        debugger;
        let aqldata = res.data[0];
        let answerArr = aqldata?.bulkAnswers?.answers ?? [];
        let answer = {};
        answerArr?.map((l) => (answer[l.question_id] = l.answer));
        let updateState = {
          ...doc,
          formData: aqldata,
          selected_form: aqldata?.form[0],
          answer: answer,
          _id: aqldata?.bulkAnswers?.entry_id,
          secName: aqldata?.bulkAnswers?.secName ?? 1,
        };
        setFormRendering(updateState);
        setOpen(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handledailog = (data) => {
    debugger;
    let doc = {
      formId: data?.form_id,
      formName: data?.form_name,
    };
    getFormFromExecuteAql(doc, data?.ticketId);
  };
  const getDate = (epoche) => {
    var myDate = new Date(Number(epoche) * 1000);

    return epoche
      ? `${myDate.getDate()}/${
          myDate.getMonth() + 1
        }/${myDate.getFullYear()}-${myDate.toLocaleTimeString("default")}`
      : "-";
  };

  const HandleChange = (key, e) => {
    setData({ ...data, [key]: e });
    if (key == "month") {
      let monthValue = months.indexOf(e);
      monthValue = monthValue + 1;
      patientDtails(data?.year, monthValue);
    } else {
      let monthValue = months.indexOf(data?.month);
      monthValue = monthValue + 1;
      patientDtails(e, data?.monthValue);
    }
  };

  return (
    <Card className={classes.mainCard}>
      <Grid container xs={12} spacing={2} className={classes.mainGrid}>
        <Grid container spacing={2} className={classes.detailGrid}>
          <Autocomplete
            className={classes?.Autocomplete}
            freeSolo
            id="free-solo-2-demo"
            disableClearable
            options={months}
            value={data?.month}
            onChange={(e, v) => HandleChange("month", v)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Month"
                margin="normal"
                variant="outlined"
                InputProps={{ ...params.InputProps, type: "search" }}
                size="small"
                style={{ minWidth: "150px" }}
              />
            )}
          />
          <TextField
            onInput={(e) => {
              e.target.value = Math.max(0, parseInt(e.target.value))
                .toString()
                .slice(0, 4);
            }}
            size="small"
            label="Year"
            type="number"
            variant="outlined"
            onChange={(e) => HandleChange("year", e.target.value)}
            value={data?.year}
            style={{ display: "inline-block", margin: "2px" }}
          />
          <Button
            size="small"
            color="primary"
            variant="text"
            startIcon={<FilterListIcon />}
            onClick={() => patientDtails()}
            style={{ margin: "2px" }}
          >
            Filter
          </Button>
        </Grid>
        {history?.length === 0 ? (
          <div
            style={{
              width: "75%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography variant="h5">
              Patient Time line not available!
            </Typography>
          </div>
        ) : (
          <Timeline className={classes.timeline}>
            {history?.map((v, i) => {
              return (
                <TimelineItem>
                  <TimelineOppositeContent
                    className={classes.TimelineOppositeContent}
                  >
                    <Typography variant="body2" color="textSecondary">
                      {getDate(v?.date)}
                    </Typography>
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot color={i % 2 === 0 ? "ternary" : "secondary"}>
                      <IconButton size="small" onClick={() => handledailog(v)}>
                        {`${v?.type?.replaceAll(" ", "")}` === "Vital" && (
                          <img
                            src={Vital}
                            alt={v?.type?.replaceAll(" ", "")}
                            className={classes.timelineIcon}
                          />
                        )}
                        {`${v?.type?.replaceAll(" ", "")}` === "Diagnosis" && (
                          <img
                            src={Diagnosis}
                            alt={v?.type?.replaceAll(" ", "")}
                            className={classes.timelineIcon}
                          />
                        )}
                        {`${v?.type?.replaceAll(" ", "")}` === "Treatment" && (
                          <img
                            src={Treatment}
                            alt={v?.type?.replaceAll(" ", "")}
                            className={classes.timelineIcon}
                          />
                        )}
                        {`${v?.type?.replaceAll(" ", "")}` ===
                          "MedicationOrder" && (
                          <img
                            src={MedicationOrder}
                            alt={v?.type?.replaceAll(" ", "")}
                            className={classes.timelineIcon}
                          />
                        )}
                        {`${v?.type?.replaceAll(" ", "")}` === "LabOrder" && (
                          <img
                            src={LabOrder}
                            alt={v?.type?.replaceAll(" ", "")}
                            className={classes.timelineIcon}
                          />
                        )}
                        {`${v?.type?.replaceAll(" ", "")}` ===
                          "Radiologyorder" && (
                          <img
                            src={Radiologyorder}
                            alt={v?.type?.replaceAll(" ", "")}
                            className={classes.timelineIcon}
                          />
                        )}
                      </IconButton>
                    </TimelineDot>
                    <TimelineConnector className={classes.timelineConnector} />
                  </TimelineSeparator>
                  <TimelineContent className={classes.timelineContentContainer}>
                    <Paper elevation={3} className={classes.timelineContent}>
                      <Typography variant="subtitle1" component="h1">
                        {v?.Procedure}
                      </Typography>
                      <Typography variant="caption" component="h1">
                        {v?.notifier_id?.name?.[0]?.prefix}.
                        {v?.notifier_id?.name?.[0]?.text}
                      </Typography>
                      <Typography variant="caption" component="h1">
                        {v?.Facility_name}
                      </Typography>
                    </Paper>
                  </TimelineContent>
                </TimelineItem>
              );
            })}
          </Timeline>
        )}

        <Dialog
          open={open}
          onClose={() => {
            setOpen(false);
          }}
          scroll={"paper"}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          // fullScreen={true}
          // fullWidth={true}
          maxWidth={true}
        >
          <FormRenderer
            verifier_comments_status={1}
            // onSaveForm={(doc) => {
            //     updateFormEntries(doc);
            // }}
            formid={formRendering?.formId}
            answer={formRendering?.answer}
            forms={formRendering?.formData?.form}
            form_detail={formRendering?.formData}
            entryId={formRendering?._id}
            btnName="save"
            submitBtnBottom="Right"
            negativeBtn="Cancel"
            secName={0}
            secToShow={[0, 1, null]}
            patient_details={formRendering?.patient}
            // UserRole={"verifier"}
            isInvestigator={false}
            allowEdit={false}
            state={[
              {
                form_id: formRendering?.formId,
              },
            ]}
            // onReloadActionPerformed={onReloadActionPerformed}
            isReadyOnly={true}
            goBack={() => {
              debugger;
              setOpen(false);
            }}
          />
        </Dialog>
      </Grid>
    </Card>
  );
};

export default PatientHistory;
