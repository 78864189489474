/**
 * @author AUTHOR_NAME
 * @email AUTHOR_EMAIL
 * @create date
 * @modify date
 * @desc Collection of all the routes used in the application.
 */

const Routes = {
  //Auth routes
  home: "/",
  dashboard: "/Home",
  login: "/login",
  AccessManagement: "/accessmanagement",
  Client: "/Client",
  CreateClient: "/CreateClient",
  EditClient: "/EditClient/:id",
  GeneralMaster: "/generalmaster",
  ReportViewer: "/reportViewer",
  FormRenderer: "/formrenderer",
  // taskDocument: "/taskdocument",
  taskViewer: "/taskviewer",
  Managefacility: "/managefacility",
  create_Facility: "/create_facility",
  managedisease: "/managedisease",
  // Manage Forms
  // notifications: "/notification",
  manage_forms: "/manage_forms",
  manage_form_detail: "/viewForm",
  notify: "/notify",
  ViewOrganization: "/vieworganization",
  ViewLevel: "/viewlevel",
  Report: "/report",
  notifierReport: "/notifierReport",
  verifiertReport: "/verifierReport",
  clientReport: "/clientReport",
  dateReport: "/dateReport",
  patientDashboard:"/patientdashboard"
};

export { Routes };
