import {
  Avatar,
  Card,
  Chip,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import VideoCallIcon from "@mui/icons-material/VideoCall";
import React, { useEffect, useState } from "react";

const useStyles = makeStyles((theme) => ({
  small: {
    width: theme.spacing(15),
    height: theme.spacing(15),
  },
  mainCard: {
    display: "flex",
    height: "28rem",
    //width: "37rem",
    margin: "0.8rem",
  },
  mainGrid: {
    display: "flex",
    flexGrow: 1,
  },
  secondaryGrid: {
    display: "flex",
    justifyContent: "center",
    padding: "1rem 0.5rem 0.5rem 0rem",
  },
  patientPresonal: {
    padding: "1rem 0.5rem 0.5rem 1rem",
  },
  vitalGrid: {
    display: "flex",
    justifyContent: "flex-start",
    padding: "1rem",
  },
  vitalChip: {
    width: "6rem",
    backgroundColor: "#E2EEFE",
    color: "#2962FA",
  },
  detailGrid: {
    display: "flex",
    justifyContent: "flex-start",
    padding: "1rem 2rem 1rem 2rem ",
  },
}));

const Patiantprofile = (props) => {
  const { patientId } = props;

  const [detail, setDetail] = React.useState({});
  const details = async () => {
    let param = {
      db_name: "SurveillancePlatform",
      entity: "patients",
      _id: props.patientId,
    };
    let call = await axios
      .post("https://registry.ainqaplatform.in/getpatientdetails", param)
      .then((res) => res);
    let result = call;
    setDetail({ ...result.data.result[0] });
  };

  React.useEffect(() => {
    details();
  }, []);

  const classes = useStyles();

//   const firstletter = 
  const Initial = (name) => {
    console.log("name",name)
  };
  console.log("initial", Initial);

  return (
    <div>
      <Card className={classes.mainCard}>
        <Grid container xs={12} className={classes.mainGrid}>
          <Grid item xs={5} className={classes.secondaryGrid}>
            <Avatar
              {...Initial(detail?.patient_name?.first_name)}
              className={classes.small}
            />
          </Grid>
          <Grid item xs={6} className={classes.patientPresonal}>
            <Typography variant="h4">
              {detail?.patient_name?.first_name}
            </Typography>
            <Typography variant="subtitle2" style={{ color: "#989898" }}>
              {detail?.email === 0 ? "-" : detail?.email}
            </Typography>
            <Typography variant="subtitle2" style={{ color: "#989898" }}>
              {detail?.addressline === 0 ? "-" : detail?.addressline}
            </Typography>
          </Grid>
          <Grid container xs={12} spacing={5} className={classes.vitalGrid}>
            <Grid item>
              <Chip
                label={detail?.gender === 0 ? "-" : detail?.gender}
                className={classes.vitalChip}
              />
            </Grid>
            <Grid item>
              <Chip
                label={
                  detail?.dob_age?.age === 0 ? "nope" : detail?.dob_age?.age
                }
                className={classes.vitalChip}
              />
            </Grid>
            <Grid item>
              <Chip
                label={
                  detail?.BloodGroup?.shortdesc.length === 0
                    ? "-"
                    : detail?.BloodGroup?.shortdesc
                }
                className={classes.vitalChip}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} className={classes.detailGrid}>
            <Grid item xs={6}>
              <Typography>Patient id</Typography>
              <Typography variant="body2" style={{ color: "#989898" }}>
                {detail?.ID === 0 ? "-" : detail?.ID}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>Phone Number</Typography>
              <Typography variant="body2" style={{ color: "#989898" }}>
                {detail?.mobile?.number === 0 ? "-" : detail?.mobile?.number}
              </Typography>
            </Grid>
            {/* <Grid item xs={6}>
                    <Typography>primary care physician</Typography>
                    <Typography variant="body2" style={{ color: "#989898" }}>
                      Dr.Moris
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>Emergency contact</Typography>
                    <Typography variant="body2" style={{ color: "#989898" }}>
                      saravanampatti,Coimbatore
                    </Typography>
                  </Grid> */}
            <Grid item xs={6}>
              <Typography>VideoCall</Typography>
              <IconButton>
                <VideoCallIcon fontSize="large" style={{ color: "#2962FA" }} />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </div>
  );
};

export default Patiantprofile;
