import React from "react";
import { Grid, TextField, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
  },
  gridItem: {
    padding: theme.spacing(2),
    "& input[type=number]::-webkit-inner-spin-button,input[type=number]::-webkit-outer-spin-button":
      {
        margin: 0,
        display: "none",
      },
  },
}));

export const DobValidations = (props) => {
  const { validationState, onChange } = props;

  const classes = useStyles(props);

  const stringValidation = (val) => {
    if (val === "minAge") {
      if (validationState?.maxAge?.length > 0) {
        if (
          parseInt(validationState?.maxAge) >
          parseInt(validationState?.minAge) + 1
        ) {
          return "";
        } else {
          return `Number should be less than "${parseInt(
            validationState?.maxAge - 1
          )}"`;
        }
      }
    } else {
      if (validationState?.minAge?.length > 0) {
        if (
          parseInt(validationState?.maxAge) >
          parseInt(validationState?.minAge) + 1
        ) {
          return "";
        } else {
          return `Number should be greater than "${
            parseInt(validationState?.minAge) + 1
          }"`;
        }
      }
    }
  };

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12} md={6} className={classes.gridItem}>
          <TextField
            value={validationState?.maxAge ?? ""}
            onChange={(e) => onChange(e.target.value, "maxAge")}
            fullWidth
            label="Max Age"
            id="dobValidationStatemaxAge"
            variant="outlined"
            size="small"
            type="number"
            // error={val?.error?.labelError ?? false}
            helperText={validationState?.maxAge ? stringValidation() : ""}
          />
        </Grid>
        <Grid item xs={12} md={6} className={classes.gridItem}>
          <TextField
            value={validationState?.minAge ?? ""}
            onChange={(e) => onChange(e.target.value, "minAge")}
            fullWidth
            label="Min Age"
            id="dobValidationStateminAge"
            variant="outlined"
            size="small"
            type="number"
            // error={val?.error?.labelError ?? false}
            helperText={
              validationState?.minAge ? stringValidation("minAge") : ""
            }
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default DobValidations;
