import * as React from "react";
import PropTypes from "prop-types";

const DollarIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={8.626}
    height={14.091}
    {...props}
  >
    <path
      d="M4.298 0a.98.98 0 0 0-.965.994v.648a3.21 3.21 0 0 0 .294 6.409h1.764a1.274 1.274 0 1 1 0 2.548h-2.43a1.267 1.267 0 0 1-1.119-.666.981.981 0 1 0-1.721.94 3.241 3.241 0 0 0 2.841 1.686h.371v.551a.98.98 0 1 0 1.96 0v-.551h.1a3.235 3.235 0 1 0 0-6.469H3.627a1.274 1.274 0 1 1 0-2.548h.527a.98.98 0 0 0 .315 0h.412A1.265 1.265 0 0 1 6 4.207a.98.98 0 1 0 1.721-.937 3.2 3.2 0 0 0-2.429-1.573v-.7a.98.98 0 0 0-1-.994Z"
      fill={props.color}
    />
  </svg>
);

export default DollarIcon;

DollarIcon.defaultProps = {
  color: "#2a3c50",
};

DollarIcon.propTypes = {
  color: PropTypes.string,
};
