import * as React from "react";
import PropTypes from "prop-types";

const CreateIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} {...props}>
    <path
      d="M17.055 4a2.938 2.938 0 0 0-2.084.86l-9.323 9.321a1.474 1.474 0 0 0-.375.638l-1.25 4.371a.632.632 0 0 0 .781.781l4.376-1.25a1.477 1.477 0 0 0 .637-.374l9.322-9.323A2.944 2.944 0 0 0 17.055 4Zm0 1.256a1.677 1.677 0 0 1 1.19.5 1.675 1.675 0 0 1 0 2.382l-.817.817-2.381-2.385.817-.817a1.679 1.679 0 0 1 1.191-.497Zm-2.9 2.207 2.382 2.382-7.613 7.613a.213.213 0 0 1-.091.053l-3.282.938.938-3.283a.206.206 0 0 1 .053-.09Z"
      fill={props.color}
      data-name="Group 94679"
    />
  </svg>
);

export default CreateIcon;

CreateIcon.defaultProps = {
  color: "#2a60bc",
};

CreateIcon.propTypes = {
  color: PropTypes.string,
};
