import * as React from "react";
import PropTypes from "prop-types";

const PrnIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12.667"
    height="9.5"
    viewBox="0 0 12.667 9.5"
  >
    <path
      id="icons8-pnr-code"
      d="M6.058,9a.475.475,0,0,0-.475.475,1.1,1.1,0,0,1-1.108,1.108A.475.475,0,0,0,4,11.058v5.383a.475.475,0,0,0,.475.475,1.1,1.1,0,0,1,1.108,1.108.475.475,0,0,0,.475.475h8.55a.475.475,0,0,0,.475-.475,1.1,1.1,0,0,1,1.108-1.108.475.475,0,0,0,.475-.475V11.058a.475.475,0,0,0-.475-.475,1.1,1.1,0,0,1-1.108-1.108A.475.475,0,0,0,14.608,9Zm.379.95h5.48v.158a.475.475,0,1,0,.95,0V9.95H14.23a2.015,2.015,0,0,0,1.487,1.487v4.626A2.015,2.015,0,0,0,14.23,17.55H12.867v-.158a.475.475,0,1,0-.95,0v.158H6.437A2.015,2.015,0,0,0,4.95,16.063V11.437A2.015,2.015,0,0,0,6.437,9.95Zm5.947,1.26a.475.475,0,0,0-.468.482v.317a.475.475,0,1,0,.95,0v-.317a.475.475,0,0,0-.482-.482Zm-5.376.64a.475.475,0,1,0,0,.95h3.167a.475.475,0,1,0,0-.95Zm5.376,1.26a.475.475,0,0,0-.468.482v.317a.475.475,0,1,0,.95,0v-.317a.475.475,0,0,0-.482-.482Zm0,1.9a.475.475,0,0,0-.468.482v.317a.475.475,0,1,0,.95,0v-.317a.475.475,0,0,0-.482-.482Z"
      transform="translate(-4 -9)"
      fill={props?.color}
    />
  </svg>
);

export default PrnIcon;

PrnIcon.defaultProps = {
  color: "white",
};

PrnIcon.propTypes = {
  color: PropTypes.string,
};
