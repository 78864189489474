import { Box, makeStyles } from "@material-ui/core";
import React from "react";
import PatientList from "./PatientList";
import Patient from "./patientDashboard";
import { withNavBars } from "../../HOCs";
import { Paper, withTheme } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    minHeight: "100%",
    //backgroundColor: "#F3F4F7",
    // padding: "8px",
    borderRadius: "0px",
  },
  body: {
    // padding: "8px 16px",
    // background: theme.palette.background.gray,
    height: "100%",
  },
}));

const PatientDashboard = (props) => {
  const classes = useStyles();

  const [showPatientOrders, setShowPatientOrder] = React.useState(false);

  const [selectedPatient, setSelectPatient] = React.useState({});

  const updateSelectedPatient = (l) => {
    let user = {
      patientId: l?._id,
      PatientName: l?.patient_name?.first_name,
      clientId: l?.client_ID,
    };
    setSelectPatient(user);
    updatepage();
  };

  const updatepage = () => {
    setShowPatientOrder(true);
  };

  const backToSearch = () => {
    setShowPatientOrder(false);
  };

  console.log("propsw332", props);
  return (
    <>
      {/* <Paper className={classes.root}> */}
        <Box className={classes.body}>
          {showPatientOrders ? (
            <PatientList
              fromKitchen={true}
              selectedPatient={selectedPatient}
              backToSearch={backToSearch}
            />
          ) : (
            <Patient
              selectedPatient={selectedPatient}
              updateSelectedPatient={updateSelectedPatient}
              updatepage={updatepage}
              backT0Search={backToSearch}
            />
          )}
        </Box>
      {/* </Paper> */}
    </>
  );
};

export default withNavBars(withTheme(PatientDashboard));
