/**
 * @author Kameshwaran Murugan
 * @email kamesh@qdmplatforms.com
 * @create date 2021-01-27
 * @modify date 2021-06-25
 * @desc Form Renderer
 */
import { makeStyles, Grid, Paper } from "@material-ui/core";
import React from "react";
import { NotFoundSection, SubHeader, CaseDefinition } from "../../components";
import { FormTreeView } from "./formTree";
import { FormBuilder } from "./formBuilder";
import { DrawerContext } from "../../contexts";
import { DrawerProps } from "../../utils";
import { PatientDetail } from "./patientDetail";
import { PatientDetailCard } from "../../components/patientDetailCard";
import { UserDetailCard } from "../../components/userDetailsCard";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Tooltip, Divider } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { actions, idmapis } from "surveillance-binder";
import { AxiosCall } from "../../utils";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: 16,
    [theme.breakpoints.only("xs")]: {
      padding: 10,
    },
  },
  sub: {},
  infocardGrid: {
    margin: "12px 0px -10px 20px",
  },
  infocard: {
    maxWidth: "97%",
    padding: "0px",
    margin: "12px",
  },
  cardContent: {
    //boxShadow: theme.shadows[10],
  },
  cardContent1: {
    float: "Right",
  },
  render: {
    // marginTop: "20px",
    padding: theme.spacing(4),
  },

  backBtn: {
    [theme.breakpoints.up("sm")]: {
      marginBottom: 12,
    },
    boxShadow: "0px 15px 25px #110f4712",
    backgroundColor: "#fff",
    paddingRight: theme.spacing(2),
    "&:hover": {
      boxShadow: "0px 15px 25px #110f4712",
      backgroundColor: "#fff",
      paddingRight: theme.spacing(2),
    },
  },
  treePaper: {
    padding: theme.spacing(1),
    marginBottom: 10,
    overflow: "auto",
    [theme.breakpoints.up("sm")]: {
      height: "calc(100vh - 150px)",
      marginBottom: 0,
      borderBottomRightRadius: 0,
      borderTopRightRadius: 0,
    },
    borderTopLeftRadius: 12,
    borderBottomLeftRadius: 12,
    borderBottomRightRadius: 12,
    borderTopRightRadius: 12,
    border: "1px solid #0000001A",
    boxShadow: "0px 15px 25px #110f4712",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },

  patientdetailpaper: {
    height: "100px",
  },
  formBuilderPaper: {
    [theme.breakpoints.up("sm")]: {
      height: "calc(100vh - 150px)",
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
    overflow: "auto",
    boxShadow: "0px 15px 25px #110f4712",
    border: "1px solid #0000001A",
  },
  subbacktext: {
    opacity: 0.6,
    fontSize: 12,
    paddingLeft: 4,
  },
}));

export const FormRenderer = ({
  form_detail,
  transferDetails,
  tree_view,
  forms,
  parent_form_id,
  entry_id,
  isEdit,
  answer,
  isInvestigator = false,
  secToShow = [],
  secName = "",
  isReadyOnly = false,
  toggleButtonStatus = {},
  getPatientDetails = {},
  entryPatientDetails = {},
  dataSecurityMappedCountryAndSelectedlevel = {},
  mappedLocations = {},
  goBack = () => false,
  hideBtn
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const drawerContext = React.useContext(DrawerContext);
  const [view, setView] = React.useState([]);
  const [historyOfViewedForms, setHistoryOfViewedForms] = React.useState([]);
  const [patientDetail, setPatientDetail] = React.useState(entryPatientDetails);
  const [showForm, setShowForm] = React.useState(isEdit);
  const [showuserDetails, setShowuserDetails] = React.useState(true);
  const [bloodGroup , setbloodGroup] = React.useState([])
  const [state, setState] = React.useState({
    selected_form: forms[0],
    form_id: forms[0]?.id,
    answer: answer,
  });

  const [hidebtnDEtails, setHideBTnDetails] = React.useState(true)

  const [client_ID, setClient_ID] = React.useState("");
  React.useEffect(() => {
    (async () => {
      let Client_ID = await idmapis.getclientif_from_tenantid(
        localStorage.getItem("tenentid")
      );
      setClient_ID(Client_ID?.result[0]?._id);
      showBTn();
    })();
    getBloodType()
  }, []);
  const onInfoClicked = (data) => {
    drawerContext.setDrawer({
      ...drawerContext,
      open: true,
      direction: DrawerProps.direction.right,
      variant: DrawerProps.variant.temporary,
      component: <CaseDefinition data={form_detail?.mapped_diseases} />,
      onClose: () => onDrawerClosed(),
    });
  };
  const getBloodType = async () => {

    let params = {
      db_name: process.env.REACT_APP_DB,
      entity: "CodeableConceptMaster",
      filter:
        `CodeableConceptMaster.Type=='Blood Group' && CodeableConceptMaster.status==true`,
      //sort: "CodeableConceptMaster.text",
      return_fields:
        "MERGE(FIRST(FOR cod IN CodeableConceptMaster.coding RETURN DOCUMENT(cod)))",
    };
    let res = await AxiosCall(
      "post",
      `${process.env.REACT_APP_ARANGO_URL_READ}`,
      params
    );
    let result = res?.result;
    
    setbloodGroup(result)
  }

  const onPatientInfoBtnClicked = () => {
    drawerContext.setDrawer({
      ...drawerContext,
      open: true,
      direction: DrawerProps.direction.right,
      variant: DrawerProps.variant.temporary,
      component: <PatientDetailCard {...patientDetail} />,
      onClose: () => onDrawerClosed(),
    });
  };

  const onDrawerClosed = () => {
    drawerContext.setDrawer({
      ...drawerContext,
      direction: DrawerProps.direction.right,
      open: false,
    });
  };

  const showBTn = () => {

    if (hideBtn) {
      setHideBTnDetails(true)
    }

    else {
      setHideBTnDetails(false)
    }

  }

  const getSectionId = (data) => {
    setState({
      ...state,
      selected_form: forms?.filter((_) => _.id === data.id)?.[0],
      form_id: data.id,
    });

    drawerContext.setDrawer({
      ...drawerContext,
      direction: DrawerProps.direction.left,
      open: false,
      onClose: () => onViewSectionDrawerClosed(),
    });
  };

  const onBackBtnClicked = () => {
    if (hideBtn) {
      if (!showForm) {
        goBack();
      }
      else {
        setShowForm(false);
        setHideBTnDetails(true)
      }
    }

    else {
      goBack();
    }
  };

  const viewSectionDrawer = () => {
    drawerContext.setDrawer({
      ...drawerContext,
      open: true,
      direction: DrawerProps.direction.right,
      variant: DrawerProps.variant.temporary,
      component: (
        <div style={{ padding: 20 }}>
          <FormTreeView
            tree_view={tree_view}
            getSectionId={getSectionId}
            selected_section_id={state.form_id}
            selected_form={state.selected_form}
            isEdit={isEdit}
            view={view}
            historyOfViewedForms={historyOfViewedForms}
            setHistoryOfViewedForms={setHistoryOfViewedForms}
            setView={setView}
            isReadyOnly={isReadyOnly}
            secToShow={secToShow}
          />
        </div>
      ),
      onClose: () => onViewSectionDrawerClosed(),
    });
  };

  const onViewSectionDrawerClosed = () => {
    drawerContext.setDrawer({
      ...drawerContext,
      direction: DrawerProps.direction.left,
      open: false,
    });
  };

  const updateAnswer = (answer) => {
    setState({
      ...state,
      answer,
    });
  };

  const onPatientSelected = async (data) => {
    data = { ...data, client_ID };
    if (!data.notify) {
      let patientdetails = await dispatch(
        actions.UPSERT_PATIENT({
          ...data.createPatientData,
          patient_id: false,
          client_ID,
        })
      );
      data = {
        ...patientdetails?.payload?.data?.Result[0]?.properties?.doc,
        patient_id:
          patientdetails?.payload?.data?.Result[0]?.properties?.doc?._id,
      };
    }
    setPatientDetail({
      ...patientDetail,
      ...data,
    });

    setShowForm(true);
    setHideBTnDetails(false);
  };

  const restForm = (data) => {
    setPatientDetail(null);
    setShowForm(false);
  };

  React.useEffect(() => {
    setHistoryOfViewedForms([...historyOfViewedForms, view]);
    // eslint-disable-next-line
  }, [view]);
  return (
    <div className={classes.root} id="frDiv1">
      <Grid
        container
        direction="column"
        justify="flex-start"
        alignItems="stretch"
      >
        {/* Header Section */}
        <Grid item>
          <SubHeader
            hideBackBtn={hidebtnDEtails}
            title={form_detail?.name}
            dataSecurityMappedCountryAndSelectedlevel={
              dataSecurityMappedCountryAndSelectedlevel
            }
            mappedLocations={mappedLocations}
            entry_id={entry_id}
            form_id={state.form_id}
            parent_form_id={parent_form_id}
            subTitle={`(${form_detail?.master_form_type?.form_type ?? "Form Type"
              })`}
            transferDetails={transferDetails}
            onBackBtnClicked={onBackBtnClicked}
            onActionIconBtnClicked={onInfoClicked}
            showInfoButton
            secName={secName}
            showToogleButton
            toggleButtonData={
              isInvestigator
                ? toggleButtonStatus.toggleButtonData?.filter(
                  (l) => l.value === 5 || l.value === 6
                )
                : toggleButtonStatus.toggleButtonData
            }
            selected={toggleButtonStatus.isSelected}
            exclusive={toggleButtonStatus.exclusive}
            onSelected={toggleButtonStatus.onSelected}
            statusUpdateData={toggleButtonStatus.statusUpdateData}
            statusPath={toggleButtonStatus.status}
            redirectPath={toggleButtonStatus?.redirectPath}
            showPatientDetails={true}
            getPatientDetails={patientDetail}
            view={view}
            setView={setView}
            isReadyOnly={isReadyOnly}
            secToShow={secToShow}
            onPatientInfoBtnClicked={onPatientInfoBtnClicked}
          />
        </Grid>
        {showForm && (
          <Grid item style={{ padding: "16px 32px" }}>
            {/* <Tooltip
              title={`${patientDetail?.patient_name?.title?.label} ${
                patientDetail?.patient_name?.first_name
              } ${patientDetail?.patient_name?.middle_name ?? ""} ${
                patientDetail?.patient_name?.last_name ?? ""
              }`}
            > */}
            <Card>
              <CardContent className={classes.cardContent}>
                <div
                  item
                  container
                  style={{
                    width: "100%",
                    display: "inline-flex",
                  }}
                >
                  <div style={{ width: "50%" }}>
                    <PatientDetailCard
                      {...patientDetail}
                      showuserDetails={showuserDetails}
                    />
                  </div>

                  {/* <Divider orientation="vertical" flexItem /> */}

                  <div
                    style={{
                      borderInlineStart: "2px solid #888",
                      width: "50%",
                    }}
                  >
                    <UserDetailCard
                      {...patientDetail}
                      parent_form_id={parent_form_id}
                    />
                  </div>
                </div>
              </CardContent>
            </Card>
            {/* </Tooltip> */}
          </Grid>
        )}
        {/* Main Content */}
        {forms.length > 0 && (
          <Grid
            item
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
            className={classes.render}
          >
            {/* Form View */}
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Paper className={classes.formBuilderPaper} id="paper">
                {!showForm && (
                  <PatientDetail
                    patientDetail={entryPatientDetails}
                    dataSecurityMappedCountryAndSelectedlevel={
                      dataSecurityMappedCountryAndSelectedlevel
                    }
                    mappedLocations={mappedLocations}
                    isEdit={isEdit}
                    onPatientSelected={onPatientSelected}
                    bloodGroup={bloodGroup}
                  />
                )}

                {showForm && (
                  <FormBuilder
                    getSectionId={getSectionId}
                    form_id={state.form_id}
                    forms={forms}
                    parent_form_id={parent_form_id}
                    isEdit={isEdit}
                    entry_id={entry_id}
                    answer={answer}
                    isInvestigator={isInvestigator}
                    isReadyOnly={isReadyOnly}
                    secToShow={secToShow}
                    secName={secName}
                    onAnswerUpdated={updateAnswer}
                    view={view}
                    setView={setView}
                    viewSection={viewSectionDrawer}
                    setHistoryOfViewedForms={setHistoryOfViewedForms}
                    statusUpdateData={toggleButtonStatus.statusUpdateData}
                    patientDetail={patientDetail}
                    restForm={restForm}
                  />
                )}
              </Paper>
            </Grid>
          </Grid>
        )}

        {forms.length === 0 && (
          <NotFoundSection message="No Forms Added Yet!" />
        )}
      </Grid>
    </div>
  );
};
