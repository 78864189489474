import * as React from "react";
import PropTypes from "prop-types";

const TickIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="11.266"
    height="10.098"
    viewBox="0 0 11.266 10.098"
  >
    <path
      id="icons8-checkmark_12_"
      data-name="icons8-checkmark (12)"
      d="M13.421,4.06l-.979-.63a.57.57,0,0,0-.79.149L7.145,10.661,4.177,8.506a.568.568,0,0,0-.8,0l-.8.8a.571.571,0,0,0,0,.8l4.047,2.994a1.1,1.1,0,0,0,.727.321.874.874,0,0,0,.686-.405l5.542-8.176A.567.567,0,0,0,13.421,4.06Z"
      transform="translate(-2.405 -3.331)"
      fill={props.color}
    />
  </svg>
);

export default TickIcon;

TickIcon.defaultProps = {
  color: "#fff",
};

TickIcon.propTypes = {
  color: PropTypes.string,
};
