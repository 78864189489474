import { Paper, withTheme } from "@material-ui/core";

import React, { Component } from "react";
import { withNavBars } from "../../HOCs";
import { TaskViewer } from "taskviewersurveillance";
// import { TaskViewer } from "./TaskViewer/index";
class TaskViewers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      PermissionData: [],
    };
  }
  componentDidMount() {
    var myHeaders = new Headers();
    myHeaders.append("jwttoken", localStorage.getItem("queue_token"));
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(process.env.REACT_APP_QUEUELIST, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        this.setState({ PermissionData: result.result });
        sessionStorage.setItem("queueVal", JSON.stringify(result.result));
      })
      .catch((error) => console.log("error", error));
  }
  render() {
    // console.log("props", this.props);
    return (
      <>
        <Paper
          style={{
            width: "100%",
            height: "100%",
            backgroundColor: "#F3F4F7",
          }}
          //   elevation={3}
        >
          <TaskViewer
            PermissionData={this.state.PermissionData}
            dbName={process.env.REACT_APP_DB}
            performUserAction={process.env.REACT_APP_PERFORMUSERACTION_API}
            auditdb={process.env.REACT_APP_AUDIT_DB}
            theme={this?.props?.theme}
          />
        </Paper>
      </>
    );
  }
}

export default withNavBars(withTheme(TaskViewers));
