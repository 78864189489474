import { Box, Grid, makeStyles, Paper, Typography } from "@material-ui/core";

import React from "react";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "8px",
    background: theme.palette.common.white,
    cursor: "pointer",
    transition: "all .4s",
    "&:hover": {
      boxShadow: `-6px 6px 0px 0px ${theme.palette.primary.main}`,
    },
  },
  cardheader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "4px 8px",
  },
  badge: {
    background: theme.palette.success.light,
    color: theme.palette.success.dark,
    border: "none",
    height: "25px",
    display: "inline-flex",
    fontSize: "0.8125rem",
    boxSizing: "border-box",
    alignItems: "center",
    whiteSpace: "nowrap",
    borderRadius: "16px",
    padding: "4px 8px",
    margin: "0 8px",
    verticalAlign: "middle",
    justifyContent: "center",
    textDecoration: "none",
  },
  flexRow: {
    display: "flex",
    alignItems: "center",
    padding: "4px",
  },
  captionBold: {
    marginLeft: "4px",
  },
  body1: {
    padding: "8px 4px",
  },
  titleBarLeft: {
    display: "flex",
    alignItems: "center",
  },
}));

export const PatientCard = (props) => {
  const {
    variant,
    field1,
    field2,
    field3,
    field4,
    field5,
    action1,
    action2,
    data,
    onCardClicked,
  } = props;
  const classes = useStyles();
  return (
    <Grid item xs={12} sm={6} md={3} lg={3}>
      {variant === "1" && (
        <Paper className={classes.paper} onClick={() => onCardClicked(data)}>
          <Box className={classes.cardheader}>
            {field1 && (
              <>
                <Box className={classes.titleBarLeft}>
                  <Typography
                    variant="subtitle1"
                    component="h6"
                    style={{ fontWeight: "600" }}
                  >
                    {field1?.title}
                  </Typography>
                  {field2?.title && (
                    <span className={classes.badge}>{field2?.title}</span>
                  )}
                </Box>

                <Box>
                  {action1 && <>{action1}</>} {action2 && <>{action2}</>}
                </Box>
              </>
            )}
          </Box>
          <Grid
            container
            xs={12}
            sm={12}
            md={12}
            lg={12}
            className={classes.body1}
          >
            {field3 && (
              <Grid item xs={4} sm={4} md={4} lg={4}>
                <Box className={classes.flexRow}>
                  {field3?.icon && <>{field3?.icon}</>}
                  {field3?.title && (
                    <Typography
                      variant="caption"
                      className={classes.captionBold}
                      
                    >
                      {field3?.title}
                    </Typography>
                  )}
                </Box>
              </Grid>
            )}

            {field4 && (
              <Grid item xs={4} sm={4} md={4} lg={4}>
                <Box className={classes.flexRow}>
                  {field4?.icon && <>{field4?.icon}</>}
                  {field4?.title && (
                    <Typography
                      variant="caption"
                      className={classes.captionBold}
                    >
                      {field4?.title}
                    </Typography>
                  )}
                </Box>
              </Grid>
            )}
            {/* {field5 && (
              <Grid item xs={4} sm={4} md={4} lg={4}>
                <Box className={classes.flexRow}>
                  {field5?.icon && <>{field5?.icon}</>}
                  {field5?.title && (
                    <Typography
                      variant="caption"
                      className={classes.captionBold}
                    >
                      {field5?.title}
                    </Typography>
                  )}
                </Box>
              </Grid>
            )} */}
          </Grid>
        </Paper>
      )}
    </Grid>
  );
};
