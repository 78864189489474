import {
  Card,
  CardContent,
  Grid,
  IconButton,
  makeStyles,
  Typography,
  Avatar,
} from "@material-ui/core";
import React from "react";
import { AxiosCall } from "../../utils";
import Chart from "./charts";
import { deepOrange, deepPurple } from "@material-ui/core/colors";
import { MdDoneAll } from "react-icons/md";
import { MdOutlineNotificationsActive } from "react-icons/md";
import { IoNewspaperOutline } from "react-icons/io5";
import { IoBusinessSharp } from "react-icons/io5";
import { FaUsersCog } from "react-icons/fa";

//success
// background-image: linear-gradient(to right bottom, #388e3c, #4b9c4e, #5daa60, #6fb972, #81c784);
//background-image: linear-gradient(to right bottom, #d65db5, #ea679f, #f3778e, #f48a84, #f09e81);

//info
//background-image: linear-gradient(to right bottom, #080a96, #373db1, #5c67ca, #8291df, #adbcf2);

//warning
//background-image: linear-gradient(to right bottom, #f94c0f, #f96431, #f8794d, #f58c67, #f09e81);
//background-image: linear-gradient(to right bottom, #f88b09, #f9a53e, #fabc66, #fcd28e, #ffe6b8);

const useStyles = makeStyles((theme) => ({
  cardRoot: {
    // color: "#ffffff",
    height: "100%",
    cursor: "pointer",
    // display: "flex",
  },
  avatarColor: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
  },
  cardFacility: {
    // color: "#000",
    // display: "flex",
    // backgroundImage:
    //   "linear-gradient(to right bottom, #2c73d7, #008ce0, #00a1e2, #0fb5df, #56c7dc)",
  },
  cardUser: {
    // backgroundImage:
    // "linear-gradient(to right bottom, #d65db5, #ea679f, #f3778e, #f48a84, #f09e81)",
  },
  cardNotify: {
    // backgroundImage:
    //   "linear-gradient(to right bottom, #004bff, #0062f7, #1e73ea, #4782da, #698fc7)",
  },
  cardVerify: {
    // backgroundImage:
    //   "linear-gradient(to right bottom, #388e3c, #4b9c4e, #5daa60, #6fb972, #81c784)",
  },
  cardPending: {
    // backgroundImage:
    //   "linear-gradient(to right bottom, #d32f2f, #e1682a, #e99632, #ecc24d, #ebeb77)",
  },
  cardReject: {
    // backgroundImage:
    //   "linear-gradient(to right bottom, #d32f2f, #de4542, #e95856, #f26a69, #fb7c7c)",
  },
  iconAlign: {
    // position: "absolute",
    // bottom: "6px",
    // right: "12px",
    // fontSize: "3rem",
    // color: "#ffffff",
  },
}));

const ClientDashBoard = (props) => {
  // const { clientId } = props;
  const classes = useStyles();
  const [clientId, setClientId] = React.useState();

  const [facilityCount, setFacilityCount] = React.useState(0);

  const [userCount, setUserCount] = React.useState(0);
  const [formCount, setFormCount] = React.useState(0);
  // const [verifyCount, setVerifyCount] = React.useState(0);
  // const [rejectCount, setRejectCount] = React.useState(0);
  const [state, setState] = React.useState([]);

  React.useEffect(() => {
    getPersonLoggedIN();
  }, []);

  const getPersonLoggedIN = async () => {
    try {
      let tenentid = localStorage.getItem("tenentid");
      let params = {
        db_name: process.env.REACT_APP_DB,
        entity: "client",
        filter: `client.tenantid=='${tenentid}' && client.activestatus==true`,
        return_fields: "client",
      };
      let res = await AxiosCall(
        "post",
        `${process.env.REACT_APP_BASE_URL}/api/read_documents`,
        params
      );
      if (res.result.length > 0) {
        let result = res?.result[0]?._id;
        getFacilityCount(result);
        getFormCount(result);
        getUserCount(tenentid);
        // getVerifiedCount(result);
        // getPendingCount(result);
        // getRejectCount(result);
        getStateCountList(result);
        setClientId(result);
        // setLoading(false);
      } else {
        setClientId(null);
        // setLoading(false);
      }

      // console.log(result);
    } catch (error) {}
  };

  const getFacilityCount = async (clientId) => {
    try {
      let params = {
        db_name: process.env.REACT_APP_DB,
        filter: { clientid: clientId },
        queryid: "1cc21c10-2f50-49a8-a398-d60e3c1e8ce0",
      };
      let res = await AxiosCall(
        "post",
        `${process.env.REACT_APP_ARANGO_URL_READ_QDMQUERIES}`,
        params
      );

      if(res?.collection) {
        let result = res?.collection[0];

        setFacilityCount(result?.facilitycount);
      } else {
        let result = res[0];

        setFacilityCount(result?.facilitycount);
      }

      
    } catch (error) {}
  };

  const getUserCount = async (tenantid) => {
    try {
      let params = {
        db_name: process.env.REACT_APP_DB,
        filter: { tenantid: tenantid },
        queryid: "8e820040-9bd8-4ced-b6fb-9b30add2333a",
      };
      let res = await AxiosCall(
        "post",
        `${process.env.REACT_APP_ARANGO_URL_READ_QDMQUERIES}`,
        params
      );

      if(res?.collection){
        let result = res?.collection[0];

       setUserCount(result?.usercount);
      }else {
        let result = res[0];

       setUserCount(result?.usercount);
      }

      
    } catch (error) {}
  };

  const getFormCount = async (clientId) => {
    try {
      let params = {
        db_name: process.env.REACT_APP_DB,
        filter: { clientid: clientId },
        queryid: "92150fcb-a670-44b7-a124-188be9a3cc03",
      };
      let res = await AxiosCall(
        "post",
        `${process.env.REACT_APP_ARANGO_URL_READ_QDMQUERIES}`,
        params
      );

      if(res?.collection){
        
        let result = res?.collection[0];

        setFormCount(result?.formcount);
      }else {
        
        let result = res[0];

        setFormCount(result?.formcount);
      }

    } catch (error) {}
  };

  // const getVerifiedCount = async (clientId) => {
  //   try {
  //     let params = {
  //       db_name: process.env.REACT_APP_DB,
  //       filter: { clientid: clientId },
  //       queryid: "12ce6749-5226-41bd-ba83-db40a701edea",
  //     };
  //     let res = await AxiosCall(
  //       "post",
  //       `${process.env.REACT_APP_ARANGO_URL_READ_QDMQUERIES}`,
  //       params
  //     );
  //     let result = res?.collection[0];

  //     setVerifyCount(result?.verifiedcount);
  //   } catch (error) {}
  // };

  // const getRejectCount = async (clientId) => {
  //   try {
  //     let params = {
  //       db_name: process.env.REACT_APP_DB,
  //       filter: { clientid: clientId },
  //       queryid: "208af419-fa34-4c4e-9496-4957daa2f118",
  //     };
  //     let res = await AxiosCall(
  //       "post",
  //       `${process.env.REACT_APP_ARANGO_URL_READ_QDMQUERIES}`,
  //       params
  //     );
  //     let result = res?.collection[0];

  //     setRejectCount(result?.rejectedcount);
  //   } catch (error) {}
  // };

  const getStateCountList = async (clientId) => {
    try {
      let params = {
        db_name: process.env.REACT_APP_DB,
        filter: { clientid: clientId },
        queryid: "3b6d7996-30cf-4075-ab2b-b164fd9f33e4",
      };
      let res = await AxiosCall(
        "post",
        `${process.env.REACT_APP_ARANGO_URL_READ_QDMQUERIES}`,
        params
      );

      if(res?.collection){
        
      let result = res?.collection;

      setState(result);
      }else {

        let result = res;

        setState(result);
      }

    } catch (error) {}
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md lg>
        <Card className={`${classes.cardRoot}`}>
          <CardContent style={{ display: "flex" }}>
            {/* <Typography variant={"h5"} gutterBottom>
              Facilities
            </Typography>
            <Typography variant={"h4"}>{facilityCount}</Typography>
            <IoBusinessSharp className={classes.iconAlign} /> */}
            <div style={{ flex: "1 1 auto" }}>
              <Typography variant={"h5"} gutterBottom>
                Facilities
              </Typography>
              <Typography variant={"h4"}>{facilityCount}</Typography>
            </div>
            <div
              style={{
                flex: "0 0 auto",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Avatar className={classes.avatarColor}>
                <IoBusinessSharp />
              </Avatar>
            </div>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6} md lg>
        <Card className={`${classes.cardRoot} ${classes.cardUser}`}>
          <CardContent style={{ display: "flex" }}>
            {/* <Typography variant={"h5"} gutterBottom>
              Active Users
            </Typography>
            <Typography variant={"h4"}>{userCount}</Typography>
            <FaUsersCog className={classes.iconAlign} /> */}
            <div style={{ flex: "1 1 auto" }}>
              <Typography variant={"h5"} gutterBottom>
                Active Users
              </Typography>
              <Typography variant={"h4"}>{userCount}</Typography>
            </div>
            <div
              style={{
                flex: "0 0 auto",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Avatar className={classes.avatarColor}>
                <FaUsersCog />
              </Avatar>
            </div>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6} md lg>
        <Card className={`${classes.cardRoot} ${classes.cardNotify}`}>
          <CardContent style={{ display: "flex" }}>
            {/* <Typography variant={"h5"} gutterBottom>
              Form Notified
            </Typography>
            <Typography variant={"h4"}>{formCount}</Typography>
            <MdOutlineNotificationsActive className={classes.iconAlign} /> */}
            <div style={{ flex: "1 1 auto" }}>
              <Typography variant={"h5"} gutterBottom>
                Form Notified
              </Typography>
              <Typography variant={"h4"}>{formCount}</Typography>
            </div>
            <div
              style={{
                flex: "0 0 auto",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Avatar className={classes.avatarColor}>
                <MdOutlineNotificationsActive />
              </Avatar>
            </div>
          </CardContent>
        </Card>
      </Grid>

      {state?.map((l) => (
        <Grid item xs={12} sm={6} md lg>
          <Card className={`${classes.cardRoot}`}>
            <CardContent style={{ display: "flex" }}>
              {/* <Typography variant={"h5"} gutterBottom>
              Form Verified
            </Typography>
            <Typography variant={"h4"}>{verifyCount}</Typography>
            <MdDoneAll className={classes.iconAlign} /> */}
              <div style={{ flex: "1 1 auto" }}>
                <Typography variant={"h5"} gutterBottom>
                  {l?.State}
                </Typography>
                <Typography variant={"h4"}>{l.Count}</Typography>
              </div>
              <div
                style={{
                  flex: "0 0 auto",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {l?.ICON ? (
                  <Avatar className={classes.avatarColor}>
                    <img
                      style={{ width: "20px", height: "20px" }}
                      src={l?.ICON}
                      alt="icon"
                    />
                  </Avatar>
                ) : (
                  <Avatar className={classes.avatarColor}>
                    <IoNewspaperOutline />
                  </Avatar>
                )}
              </div>
            </CardContent>
          </Card>
        </Grid>
      ))}

      <Grid item lg={12} md={12} xs={12}>
        <Chart
          title="Notification"
          basedOn="period"
          uniqueId={clientId}
          filterKey={"clientid"}
          monthlyQueryId={"ef837411-cbdf-4c0e-9338-ef8374ac6eeb"}
          dateQueryId={"3586ac76-914e-4813-b4be-62e1d797cb42"}
          gridItem={3}
        />
      </Grid>

      {/* <Grid item lg={12} md={12} xl={12} xs={12}>
        <UserChart />
      </Grid> */}
      {/* <Grid item lg={4} md={12} xl={4} xs={12}>
        <RenderSingleChart title="Notified By" variant={"doughNut"} />
      </Grid> */}
    </Grid>
  );
};

export default ClientDashBoard;
