import { makeStyles, Typography } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    background: "aliceblue",
  },
  textResponsive: {
    [theme.breakpoints.up("md")]: {
      fontSize: "1.1rem !important",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "1rem !important",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.8rem !important",
    },
  },
}));

export const EmptyScreen = (props) => {
  const { message } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div
        style={{
          padding: "8px",
          height: "50px",
          width: "50px",
          borderRadius: "50%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#E9EFF8",
          margin: "8px",
        }}
      >
        <img
          style={{
            height: "30px",
            width: "30px",
            objectFit: "contain",
          }}
          src={`/images/icons/box.png`}
          alt={"No Data Imge"}
        ></img>
      </div>
      <Typography
        variant="subtitle1"
        // style={{ fontWeight: "600" }}
        gutterBottom
        noWrap
      >
        {message}
      </Typography>
    </div>
  );
};
