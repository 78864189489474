import React from "react";
import {
  List,
  makeStyles,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Checkbox,
  IconButton,
  Popover,
} from "@material-ui/core";
import CircleCheckedFilled from "@material-ui/icons/CheckCircle";
import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked";
import { Delete, MoreVert } from "@material-ui/icons";
import EditIcon from "@material-ui/icons/Edit";
import PropTypes from "prop-types";
import { useHistory } from "react-router";
//import { giveMeReplacedNotifiyTodoText, giveMeRouteBasedOnTodoNotify } from "../../utils";

export * from './loading';
export * from './todoListLoader';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiList-padding': {
      paddingTop: 0,
      paddingBottom: 0,
    },
    '& .MuiListItemIcon-root': {
      minWidth: 35
    }
  },
}));

export const TodoList = (props) => {

  const classes = useStyles(props);
  const history = useHistory();

  const [checked, setChecked] = React.useState(props?.data?.filter(item => item.is_checked === true).map(item => item.todo_id));
  const [selectedData, setSelectedData] = React.useState({});
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleToggle = (item, isCheck) => () => {
    if (isCheck) {
      if (checked.indexOf(item.todo_id) > -1) {
        const filteredData = checked?.filter(i => i !== item.todo_id);
        setChecked(filteredData);
      } else {
        setChecked([...checked, item.todo_id]);
      }
      props?.statusChange(item.todo_id, true)
    } else {
      //history.push(giveMeRouteBasedOnTodoNotify(item))
    }
  };

  const handleClick = (event, data) => {
    setSelectedData(data)
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  React.useEffect(() => {
    setChecked(props?.data?.filter(item => item.is_checked === true).map(item => item.todo_id));
    // eslint-disable-next-line
  }, [props?.statusChange])

  const isIamChecked = (data, index) => {
    if (checked.indexOf(data.todo_id) > -1) {
      return true
    }
    else {
      return false
    }
  }

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  
  return (
    <div className={`${classes.root}`} style={{height: props?.data.length > 0 ? '500px' : '100%' ,overflow:"scroll"}}>
      <List className={classes.root}>
        {props?.data?.map((_, index) => {

          const labelId = `checkbox-list-label-${_?.todo_id}`;
          return (
            <div key={_?.todo_id} style={{margin:"6px", border: '1px solid #E5E5E5', borderRadius:"8px", background: '#FFFFFF 0% 0% no-repeat padding-box',opacity: 1}}>
              <ListItem
                key={_?.todo_id}
                id={`${"listItem "+ index}`}
                role={undefined}
                dense
                button
              >
                <ListItemIcon>
                  <Checkbox
                    id={`${"checkbox "+ index}`}
                    onClick={handleToggle(_, true)}
                    color="primary"
                    inputProps={{ "aria-labelledby": labelId }}
                    edge="start"
                    checked={isIamChecked(_, index)}
                    tabIndex={-1}
                    disableRipple
                    icon={<CircleUnchecked />}
                    checkedIcon={
                      <CircleCheckedFilled style={{ color: "#F5B800" }} />
                    }
                  />
                </ListItemIcon>
                <ListItemText
                  id={labelId}
                  //primary={giveMeReplacedNotifiyTodoText(_?.todo, _)}
                  secondary={_.entry_id ? "View the Notification" : ""}
                  onClick={handleToggle(_, false)}
                  style={{
                    textDecorationLine: isIamChecked(_, index) ? 'none' : 'none',
                    color: isIamChecked(_, index) ? 'grey' : 'black',
                  }}
                />
                <ListItemSecondaryAction>

                  <div>
                    <IconButton edge="end" id={`${"more "+index}`} aria-describedby={id} onClick={(e) => handleClick(e, _)}>
                      <MoreVert />
                    </IconButton>
                    <Popover
                      id={id}
                      open={open}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                    >
                      <List dense>
                        {/* Edit Icon */}
                        {!props?.hideEditBtn && (
                          <ListItem
                           id={`${"edit"+index}`}
                            dense
                            button
                            onClick={() => {
                              handleClose();
                              props.onEditIconClicked(selectedData);
                            }}
                          >
                            <ListItemIcon className={classes.listItemIcon}>

                              <EditIcon htmlColor="rgba(69, 20, 155, 1)" />
                            </ListItemIcon>
                            <ListItemText primary="Edit" />
                          </ListItem>
                        )}

                        {/* Delete Icon */}
                        {!props?.hideDeleteBtn && (
                          <ListItem
                          id={`${"delete"+index}`}
                            dense
                            button
                            onClick={() => {
                              handleClose();
                              props.onDeleteIconClicked(selectedData);
                            }}
                          >
                            <ListItemIcon className={classes.listItemIcon}>
                              <Delete htmlColor="rgba(69, 20, 155, 1)" />
                            </ListItemIcon>
                            <ListItemText primary="Delete" />
                          </ListItem>
                        )}
                      </List>
                    </Popover>
                  </div>
                </ListItemSecondaryAction>
              </ListItem>
              
            </div>
          );
        })}
      </List>
    </div>
  );
};

TodoList.propTypes = {
  data: PropTypes.array,
  statusChange: PropTypes.func,
  onEditIconClicked: PropTypes.func,
  onDeleteIconClicked: PropTypes.func,
};
