import * as React from "react";
import PropTypes from "prop-types";

const EstimateIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={11.532}
    height={12.746}
    {...props}
  >
    <path
      d="M1.669 0A1.672 1.672 0 0 0 0 1.669v8.8a1.672 1.672 0 0 0 1.669 1.669h3.682a3.951 3.951 0 0 1-.655-.91H1.669a.759.759 0 0 1-.759-.759v-8.8A.759.759 0 0 1 1.669.91h6.373a.759.759 0 0 1 .759.759v3.839a4.015 4.015 0 0 1 .91.258v-4.1A1.672 1.672 0 0 0 8.042 0Zm.9 2.124a.755.755 0 0 0-.747.747v1.845a.754.754 0 0 0 .747.747h4.576a.755.755 0 0 0 .747-.746V2.871a.755.755 0 0 0-.747-.747Zm.164.91h4.249v1.518H2.731Zm5.463 3.035a3.338 3.338 0 1 0 3.338 3.338 3.338 3.338 0 0 0-3.34-3.337Zm-5.614.3a.759.759 0 1 0 .759.759.759.759 0 0 0-.761-.755Zm2.276 0a.759.759 0 0 0-.282 1.463 4.062 4.062 0 0 1 .838-1.223.758.758 0 0 0-.558-.236Zm3.338.607a.3.3 0 0 1 .3.3v.308a1.122 1.122 0 0 1 .862.437.304.304 0 1 1-.51.33.544.544 0 0 0-.417-.165h-.466a.455.455 0 0 0 0 .91H8.5a1.062 1.062 0 1 1 0 2.124v.3a.303.303 0 1 1-.607 0v-.3a1.375 1.375 0 0 1-.973-.391.303.303 0 0 1 .429-.429.76.76 0 0 0 .555.214h.6a.455.455 0 1 0 0-.91h-.531a1.06 1.06 0 0 1-.076-2.117v-.311a.3.3 0 0 1 .297-.296ZM2.58 8.5a.759.759 0 1 0 .759.759.759.759 0 0 0-.759-.759Z"
      fill={props.color}
    />
  </svg>
);

export default EstimateIcon;

EstimateIcon.defaultProps = {
  color: "#2a3c50",
};

EstimateIcon.propTypes = {
  color: PropTypes.string,
};
