import * as React from "react";
import PropTypes from "prop-types";
import { Routes } from "../router/routes";
import { useTheme } from "@material-ui/core";

const CartFilledIcon = (props) => {
  const theme = useTheme();
  const color=
    window.location.pathname === Routes.cartDefinition ||
    window.location.pathname === Routes.cartDefinitionUpsert
      ? theme.sideBar.text.active
      : theme.sideBar.text.nonActive
  return(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18.811}
    height={15.387}
    {...props}
  >
    <g data-name="Group 95590">
      <path
        d="M12.114-.001a2.635 2.635 0 0 0-1.868.771l-3.7 3.7a2.644 2.644 0 0 0 3.738 3.74l.658-.658a2.335 2.335 0 1 0 3.083-3.087 2.638 2.638 0 0 0-1.911-4.466ZM8.759 3.248l2.247 2.247a2.317 2.317 0 0 0-.276 1.1c0 .059 0 .116.009.173a.351.351 0 0 0-.055.042l-.9.9A1.941 1.941 0 0 1 7.04 4.967Zm4.309 1.709a1.636 1.636 0 1 1-1.636 1.636 1.631 1.631 0 0 1 1.636-1.636Zm.577.7a.351.351 0 0 0-.241.106l-1.168 1.166a.354.354 0 1 0 .5.5l1.169-1.169a.351.351 0 0 0-.255-.6Z"
        fill={color}
        data-name="Group 95590"
      />
      <path
        d="M.641 0a.641.641 0 1 0 0 1.282h1.178a.636.636 0 0 1 .629.521L3.954 9.71a2.786 2.786 0 0 0 2.73 2.258h8a2.785 2.785 0 0 0 2.73-2.258l1.378-7.239a.641.641 0 1 0-1.259-.24L16.157 9.47a1.49 1.49 0 0 1-1.47 1.215h-8a1.49 1.49 0 0 1-1.47-1.215L3.708 1.564A1.93 1.93 0 0 0 1.819 0Zm7.052 12.823A1.282 1.282 0 1 0 8.976 14.1a1.282 1.282 0 0 0-1.283-1.277Zm5.984 0A1.282 1.282 0 1 0 14.96 14.1a1.282 1.282 0 0 0-1.283-1.277Z"
        fill={color}
      />
    </g>
  </svg>)
};

export default CartFilledIcon;

CartFilledIcon.defaultProps = {
  color: "#2a60bc",
};

CartFilledIcon.propTypes = {
  color: PropTypes.string,
};
