import {
  Box,
  Card,
  Grid,
  IconButton,
  SvgIcon,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import VideoCallIcon from "@mui/icons-material/VideoCall";
import MedicationIcon from "@mui/icons-material/Medication";
import SearchIcon from "@material-ui/icons/Search";
import Diagnosis from "../../assets/Diagnosis.svg";
import Encounter from "../../assets/Encounter.svg";
import OrderIcon from "../../assets/OrderIcon.svg"
import axios from "axios";
import React from "react";

const useStyles = makeStyles((theme) => ({
  mainCard: {
    display: "flex",
    height: "28rem",
    //width: "40rem",
    margin: "0.8rem",
    // backgroundColor: "#F1F7FC",
  },
  mainGrid: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    //padding: "2rem",
    flexGrow: 1,
  },
  cardGrid: {
    display: "flex",
    justifyContent: "center",
  },
  icondiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "20px",
    height: "43px",
    width: "45px",
    border:"2.5px solid #000000"
  },
  icon: {
    color: "#ffffff",
  },
}));

function Cards(props) {
  const { patientId } = props;
  console.log("props",props.patientId)
  const [encounter, setEncounter] = React.useState("");
  const [medication, setMedication] = React.useState("");
  const [diagnosis, setDiagonsis] = React.useState("");
  const [order, setOrder] = React.useState(" ");

  const encounterCall = async () => {
    let param = {
      db_name: "SurveillancePlatform",
      filter: {
        patientid: props.patientId,
      },
      queryid: "ad3a0d69-c8d2-43c4-aa4d-a64e95990f84",
    };
    let result = await axios
      .post(process.env.REACT_APP_ARANGO_URL_READ_QDMQUERIES, param)
      .then((res) => res);
    setEncounter(result?.data[0]?.count);
  };

  const medicationCall = async () => {
    let param = {
      db_name: "SurveillancePlatform",
      filter: {
        patientid: props.patientId,
      },
      queryid: "fc6c6105-86f1-457a-9b7f-9e8aba32bca6",
    };
    let result = await axios
      .post(process.env.REACT_APP_ARANGO_URL_READ_QDMQUERIES, param)
      .then((res) => res);
    setMedication(result?.data[0]?.count);
  };

  const diagnosisCall = async () => {
    let param = {
      db_name: "SurveillancePlatform",
      filter: {
        patientid:  props.patientId,
      },
      queryid: "e0c35c70-a89a-4136-8e5c-aebebed79a4e",
    };
    let result = await axios
      .post(process.env.REACT_APP_ARANGO_URL_READ_QDMQUERIES, param)
      .then((res) => res);
    setDiagonsis(result?.data[0]?.count);
  };

  const orderCall = async () => {
    let param = {
      db_name: "SurveillancePlatform",
      filter: {
        patientid:  props.patientId,
      },
      queryid: "03f89f35-4a5b-4d5b-8d9f-07a1a7a05e0e",
    };
    let result = await axios
      .post(process.env.REACT_APP_ARANGO_URL_READ_QDMQUERIES, param)
      .then((res) => res);
    setOrder(result?.data[0]?.count);
  };

  React.useEffect(() => {
    encounterCall();
    medicationCall();
    diagnosisCall();
    orderCall();
  }, []);

  const classes = useStyles();
  return (
    <div>
      <Card className={classes.mainCard}>
        <Grid container className={classes.mainGrid}>
          <Grid xs={6} item className={classes.cardGrid}>
            <Card
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "6.25rem",
                width: "17rem",
              }}
            >
              <Grid container style={{ padding: "0px 0px 0px 1rem" }}>
                <Grid item xs={7}>
                  <Grid container>
                    <Typography>Encounters</Typography>
                  </Grid>
                  <Grid container>
                    <h2>{encounter}</h2>
                  </Grid>
                </Grid>
                <Grid
                  xs={4}
                  item
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  <div className={classes.icondiv}>
                    <img style={{height:"32px", width:"32px"}} src={Encounter}/>
                  </div>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid xs={6} item className={classes.cardGrid}>
            <Card
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "6.25rem",
                width: "17rem",
              }}
            >
              <Grid container style={{ padding: "0px 0px 0px 1rem" }}>
                <Grid item xs={7}>
                  <Grid container>
                    <Typography>Medication</Typography>
                  </Grid>
                  <Grid container>
                    <h2>{medication}</h2>
                  </Grid>
                </Grid>
                <Grid
                  xs={4}
                  item
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  <div className={classes.icondiv}>
                    <MedicationIcon  />
                  </div>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid xs={6} item className={classes.cardGrid}>
            <Card
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "6.25rem",
                width: "17rem",
              }}
            >
              <Grid container style={{ padding: "0px 0px 0px 1rem" }}>
                <Grid item xs={7}>
                  <Grid container>
                    <Typography>Diagonsis</Typography>
                  </Grid>
                  <Grid container>
                    <h2>{diagnosis}</h2>
                  </Grid>
                </Grid>
                <Grid
                  xs={4}
                  item
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  <div className={classes.icondiv}>
                    {/* <SearchIcon className={classes.icon} /> */}
                    <img src={Diagnosis}/>
                  </div>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid xs={6} item className={classes.cardGrid}>
            <Card
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "6.25rem",
                width: "17rem",
              }}
            >
              <Grid container style={{ padding: "0px 0px 0px 1rem" }}>
                <Grid item xs={7}>
                  <Grid container>
                    <Typography>Orders</Typography>
                  </Grid>
                  <Grid container>
                    <h2>{order}</h2>
                  </Grid>
                </Grid>
                <Grid
                  xs={4}
                  item
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  <div className={classes.icondiv}>
                    <img style={{height:"28px", width:"28px"}} src={OrderIcon}/>
                  </div>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </Card>
    </div>
  );
}

export default Cards;
