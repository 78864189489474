import * as React from "react";
import PropTypes from "prop-types";
import { Routes } from "../router/routes";
import { useTheme } from "@material-ui/core";

const ThemesIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18.999"
      height="20"
      viewBox="0 0 18.999 20"
    >
      <path
        id="icons8-themes"
        d="M11.108,2A2.751,2.751,0,0,0,8.824,3.2l-.243.36a3.615,3.615,0,0,1,1.932.156L17,6.057l2,.722L12.643,2.471A2.732,2.732,0,0,0,11.108,2ZM9.363,4.5A2.755,2.755,0,0,0,6.654,6.317L6.3,7.29A3.725,3.725,0,0,1,7.75,7h8.908L10.174,4.662A2.741,2.741,0,0,0,9.363,4.5ZM7.75,8A2.761,2.761,0,0,0,5,10.749v8.5A2.761,2.761,0,0,0,7.75,22h13.5A2.761,2.761,0,0,0,24,19.248v-8.5A2.761,2.761,0,0,0,21.249,8Zm0,1.5H17.5v11H7.75a1.239,1.239,0,0,1-1.25-1.25v-8.5A1.239,1.239,0,0,1,7.75,9.5ZM19,9.5h2.25a1.239,1.239,0,0,1,1.25,1.25v8.5a1.239,1.239,0,0,1-1.25,1.25H19Z"
        transform="translate(-5 -1.998)"
        fill={props?.color}
      />
    </svg>
  );
};

export default ThemesIcon;

ThemesIcon.defaultProps = {
  color: "black",
};

ThemesIcon.propTypes = {
  color: PropTypes.string,
};
