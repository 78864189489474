import React from "react";
import { idmapis } from "surveillance-binder";
import { withNavBars } from "../../HOCs";
import ManageLevel from "../manageLevel";

function ViewLevel(props) {
  const [Client_ID, setClient_ID] = React.useState();

  React.useEffect(() => {
    getClientId();
  }, []);

  const getClientId = async (tenentid) => {
    let Client_ID = await idmapis.getclientif_from_tenantid(
      localStorage.getItem("tenentid")
    );
    let _id = Client_ID.result[0]?._id;
    setClient_ID(_id);
  };
  return (
    <>
      {Client_ID && (
        <ManageLevel
          disableaddlevel={true}
          disabledadd1={true}
          disabledadd2={true}
          disabledmore={true}
          clientId={Client_ID}
          tenantid={localStorage.getItem("tenentid")}
          view={true}
        />
      )}
    </>
  );
}

export default withNavBars(ViewLevel);
