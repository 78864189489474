import React from 'react'
import { makeStyles, SvgIcon } from '@material-ui/core'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
    cls1: (props) => ({
        fill: props.isSelected ? theme.palette.text.primary : props?.color ?? '#a5a5a5'
    })
}))

export function Reject(props) {
    const classes = useStyles(props)
    return (
        <SvgIcon x="0px" y="0px"
            viewBox="0 0 426.667 426.667" enable-background="new 0 0 426.667 426.667" fontSize="inherit">
            <g>
                <g>
                    <path
                        className={classes.cls1}
                        d="M213.333,0C95.467,0,0,95.467,0,213.333s95.467,213.333,213.333,213.333S426.667,331.2,426.667,213.333S331.2,0,213.333,0
			z M42.667,213.333c0-94.293,76.373-170.667,170.667-170.667c39.467,0,75.627,13.547,104.533,35.947L78.613,317.867
			C56.213,288.96,42.667,252.8,42.667,213.333z M213.333,384c-39.467,0-75.627-13.547-104.533-35.947L348.053,108.8
			C370.453,137.707,384,173.867,384,213.333C384,307.627,307.627,384,213.333,384z"/>
                </g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
        </SvgIcon>
    )
}

Reject.propTypes = {
    color: PropTypes.string
}