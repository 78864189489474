import React from 'react';
import {Grid} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root:{
        padding:theme.spacing(1)
    }
}))

export const TodoListLoading = (props) =>{

    const classes = useStyles(props);

    return(
        <Grid container direction="row" justify="space-between" alignItems="center" className={classes.root} >
            <Skeleton variant="circle" width={24} height={24} />
            <Skeleton variant="text" style={{flex:1,margin:'0px 10px'}} />
            <Skeleton variant="rect" width={10} height={20} />
      </Grid>
    )
}