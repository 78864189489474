import * as React from "react";
import PropTypes from "prop-types";
import { Routes } from "../router/routes";
import { useTheme } from "@material-ui/core";

const AdminIcon = (props) => {
  const theme = useTheme();
  const color =
    window.location.pathname === Routes?.administration
      ? theme?.sideBar?.text?.active
      : theme?.sideBar?.text?.nonActive;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16.965"
      height="17.4"
      viewBox="0 0 16.965 17.4"
    >
      <path
        d="M9.393,6A2.4,2.4,0,0,0,7,8.393V9.7a2.385,2.385,0,0,0,.758,1.738A2.383,2.383,0,0,0,7,13.178v1.305a2.382,2.382,0,0,0,.755,1.74A2.382,2.382,0,0,0,7,17.963v1.305A2.4,2.4,0,0,0,9.393,21.66h5.462a5.623,5.623,0,0,1-.618-1.305H9.393a1.089,1.089,0,0,1-1.088-1.088V17.963a1.089,1.089,0,0,1,1.088-1.088h4.844a5.608,5.608,0,0,1,.619-1.305H9.393a1.089,1.089,0,0,1-1.088-1.088V13.178A1.089,1.089,0,0,1,9.393,12.09H19.4a1.086,1.086,0,0,1,1.073.941,5.606,5.606,0,0,1,1.319.364v-.218a2.383,2.383,0,0,0-.758-1.742A2.385,2.385,0,0,0,21.79,9.7V8.393A2.4,2.4,0,0,0,19.4,6Zm0,1.305H19.4a1.078,1.078,0,0,1,1.088,1.088V9.7A1.078,1.078,0,0,1,19.4,10.785H9.393A1.078,1.078,0,0,1,8.305,9.7V8.393A1.078,1.078,0,0,1,9.393,7.305ZM19.615,13.83a4.483,4.483,0,0,0-.749.063l-.15.623a1.74,1.74,0,0,1-2.184,1.261l-.524-.155a4.829,4.829,0,0,0-.742,1.366l.389.374a1.741,1.741,0,0,1,0,2.508l-.389.374a4.824,4.824,0,0,0,.742,1.366l.524-.155a1.74,1.74,0,0,1,2.184,1.261l.15.623a4.5,4.5,0,0,0,1.5,0l.15-.623A1.74,1.74,0,0,1,22.7,21.454l.524.155a4.829,4.829,0,0,0,.742-1.366l-.389-.374a1.741,1.741,0,0,1,0-2.508l.389-.374a4.824,4.824,0,0,0-.742-1.366l-.524.155a1.74,1.74,0,0,1-2.184-1.261l-.15-.623A4.483,4.483,0,0,0,19.615,13.83Zm0,3.48a1.305,1.305,0,1,1-1.305,1.305A1.3,1.3,0,0,1,19.615,17.31Z"
        transform="translate(-7 -6)"
        fill={color}
      />
    </svg>
  );
};

export default AdminIcon;

AdminIcon.defaultProps = {
  color: "black",
};

AdminIcon.propTypes = {
  color: PropTypes.string,
};
