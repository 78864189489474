import React from 'react'
import { makeStyles, SvgIcon } from '@material-ui/core'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
    cls1: (props) => ({
        fill: props?.color ?? theme.palette.common.white
    })
}))

export function Notification(props) {
    const classes = useStyles(props)
    return (
        <SvgIcon style={{width:20,height:20}}>
            <path className={classes.cls1}  d="M13.4,2a1.763,1.763,0,0,0-1.762,1.762v.817A7.047,7.047,0,0,0,6.35,11.4v7.05L4,20.8v1.175h7.367a2.351,2.351,0,1,0,4.064,0H22.8V20.8l-2.35-2.35V11.4a7.047,7.047,0,0,0-5.287-6.82V3.762A1.763,1.763,0,0,0,13.4,2Zm0,4.7a4.7,4.7,0,0,1,4.7,4.7v8.023l.2.2H8.5l.2-.2V11.4A4.7,4.7,0,0,1,13.4,6.7Z" transform="translate(-1 -1)"/>
        </SvgIcon>
    )
}

Notification.propTypes = {
    color: PropTypes.string
}