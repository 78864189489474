import * as React from "react";
import PropTypes from "prop-types";

const PediatricIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18.27}
    height={21.442}
    {...props}
  >
    <defs>
      <clipPath id="a">
        <path
          data-name="Rectangle 51808"
          fill="#8895a5"
          d="M0 0h18.27v21.442H0z"
        />
      </clipPath>
    </defs>
    <g data-name="Group 95701">
      <g data-name="Group 95700" clipPath="url(#a)" fill="#8895a5">
        <path
          data-name="Path 95405"
          d="M4.86 19.838a9.264 9.264 0 0 1-1.121-3.61 6.283 6.283 0 0 1 1.085-3.781 2.99 2.99 0 0 0 .243-2.966 9.894 9.894 0 0 0-3.043 1.244c-.23.135-.441.3-.678.424a.83.83 0 0 1-1.183-.207.867.867 0 0 1 .188-1.2c.194-.183.391-.364.6-.528a13.281 13.281 0 0 1 16.33-.025 7.967 7.967 0 0 1 .6.525.87.87 0 0 1 .156 1.3.859.859 0 0 1-1.2.087c-.575-.335-1.148-.679-1.749-.961s-1.212-.479-1.89-.741a3.04 3.04 0 0 0 .142 2.872 6.164 6.164 0 0 1-.1 7.456 1.307 1.307 0 0 0 0 .278c.223.932.127 1.271-.41 1.415a1.459 1.459 0 0 1-1.541-1 1.864 1.864 0 0 1 .342-1.5c.445-.8.99-1.624.379-2.573-1.915 1.269-3.812 1.154-5.9.02a5.16 5.16 0 0 0-.036 1.281 7.247 7.247 0 0 0 .571 1.339 4.305 4.305 0 0 1 .355.706 1.348 1.348 0 0 1-.756 1.552c-.32.133-.974.162-1.068 0a4.446 4.446 0 0 1-.316-1.407m2.364-7.947c.12 1.037.182 1.955.359 2.85a1.055 1.055 0 0 0 .659.69 4.667 4.667 0 0 0 1.7.014 1.057 1.057 0 0 0 .709-.653c.18-.93.237-1.883.349-2.9Z"
        />
        <path
          data-name="Path 95406"
          d="M11.838 2.681a2.705 2.705 0 1 1-5.41-.02 2.705 2.705 0 0 1 5.41.02"
        />
      </g>
    </g>
  </svg>
);

export default PediatricIcon;

PediatricIcon.defaultProps = {
  color: "#2a3c50",
};

PediatricIcon.propTypes = {
  color: PropTypes.string,
};
