import React from "react";
import { Grid, TextField, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
  },
  gridItem: {
    padding: theme.spacing(2),
    "& input[type=number]::-webkit-inner-spin-button,input[type=number]::-webkit-outer-spin-button":
      {
        margin: 0,
        display: "none",
      },
  },
}));

export const NumericValidations = (props) => {
  const { validationState, onChange } = props;

  const classes = useStyles(props);

  const numberValidation = (val) => {
    if (val === "lessThan") {
      if (validationState?.greaterThan?.length > 0) {
        if (
          parseInt(validationState?.lessThan) >
          parseInt(validationState?.greaterThan) + 1
        ) {
          return "";
        } else {
          return `Number should be greater than "${
            parseInt(validationState?.greaterThan) + 1
          }"`;
        }
      }
    } else {
      if (validationState?.lessThan?.length > 0) {
        if (
          parseInt(validationState?.greaterThan) <
          parseInt(validationState?.lessThan) - 1
        ) {
          return "";
        } else {
          return `Number should be less than "${
            parseInt(validationState?.lessThan) - 1
          }"`;
        }
      }
    }
  };

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12} md={6} className={classes.gridItem}>
          <TextField
            value={validationState?.greaterThan ?? ""}
            onChange={(e) => onChange(e.target.value, "greaterThan")}
            fullWidth
            label="Greater Than"
            id="numericValidationStateGreaterThan"
            variant="outlined"
            size="small"
            type="number"
            // error={val?.error?.labelError ?? false}
            helperText={validationState?.greaterThan ? numberValidation() : ""}
          />
        </Grid>
        <Grid item xs={12} md={6} className={classes.gridItem}>
          <TextField
            value={validationState?.lessThan ?? ""}
            onChange={(e) => onChange(e.target.value, "lessThan")}
            fullWidth
            label="Less Than"
            id="numericValidationStateLessThan"
            variant="outlined"
            size="small"
            type="number"
            // error={val?.error?.labelError ?? false}
            helperText={
              validationState?.lessThan ? numberValidation("lessThan") : ""
            }
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default NumericValidations;
