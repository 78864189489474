import * as React from "react";
import PropTypes from "prop-types";

const NoAccessIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={14} height={14} {...props}>
    <path
      d="M7 0a7 7 0 1 0 7 7 7.008 7.008 0 0 0-7-7Zm0 1.05A5.95 5.95 0 1 1 1.05 7 5.942 5.942 0 0 1 7 1.05Zm3.737 1.176-8.52 8.387.953 1.024 8.6-8.421Z"
      fill={props.color}
    />
  </svg>
);

export default NoAccessIcon;

NoAccessIcon.defaultProps = {
  color: "#2a3c50",
};

NoAccessIcon.propTypes = {
  color: PropTypes.string,
};
