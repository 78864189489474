import React from 'react'
import { makeStyles, SvgIcon } from '@material-ui/core'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
    cls1: (props) => ({
        fill: props.isSelected ? theme.palette.text.primary : props?.color ?? '#a5a5a5'
    })
}))

export function Transfer(props) {
    const classes = useStyles(props)
    return (
        <SvgIcon height="512" viewBox="0 0 512 512" width="512" fontSize="inherit">
            <g id="Solid">
                <path className={classes.cls1} d="m48 192h358.059l-39.03 39.029a24 24 0 0 0 33.942 33.942l80-80a24 24 0 0 0 0-33.942l-80-80a24 24 0 0 0 -33.942 33.942l39.03 39.029h-358.059a24 24 0 0 0 0 48z" />
                <path className={classes.cls1} d="m464 320h-358.059l39.03-39.029a24 24 0 0 0 -33.942-33.942l-80 80a24 24 0 0 0 0 33.942l80 80a24 24 0 0 0 33.942-33.942l-39.03-39.029h358.059a24 24 0 0 0 0-48z" />
            </g>
        </SvgIcon>
    )
}

Transfer.propTypes = {
    color: PropTypes.string
}