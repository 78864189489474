import React from "react";
import {
  Button,
  makeStyles,
  Divider,
  Typography,
  Grid,
  IconButton,
} from "@material-ui/core";

import { AlertContext, BackdropContext } from "../../contexts";
import { CustionAddText } from "../../components";
import { DrawerContext } from "../../contexts";

// store
import { actions } from "surveillance-binder";
import { useSelector, useDispatch } from "react-redux";
import { Message, DrawerProps } from "../../utils";
import { AlertProps } from "../../utils/constants";
import { Delete } from "@material-ui/icons";

// Store Action's list
const { UPSERT_MANAGE_LOCATION_LEVEL_CHILD } = actions;

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  header: {
    padding: "10px 20px",
  },
  levelNameParent: {
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  btnParent: {
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

export const AddPlaces = ({
  level = "",
  level_id = "",
  place_name = "",
  level_name = "",
  nextlevelName = "",
  isEdit = false,
  // countryId = "",
  parent_child_level_id = "",
  childlevel,
  onActionCompleted = () => false,
  client_id,
  tenantid,
}) => {
  const classes = useStyles();

  const [state, setState] = React.useState({
    place_name: place_name ?? [],
    level_id: level_id ?? "",
    is_active: true,
    error: {
      place_name: false,
      level_id: false,
    },
  });

  // Declaring Store dispatch
  const dispatch = useDispatch();

  const backdrop = React.useContext(BackdropContext);
  const alert = React.useContext(AlertContext);
  const drawer = React.useContext(DrawerContext);

  const upsertLevel = useSelector(
    (state) => state?.manageLevelSlice?.upsertManageLocationLevelChild
  );

  const onChange = (value) => {
    value = value.map((obj) => ({ ...obj, isActive: true }));
    setState({ ...state, place_name: value });
  };

  const validation = () => {
    if (state?.place_name?.length === 0) {
      state.error = {
        place_name: state?.place_name?.length === 0 ? true : false,
      };
      setState({ ...state });
    } else {
      return true;
    }
  };

  const onDeleteBtnClicked = () => {
    submitForm(true, false);
  };

  const closeDrawer = () => {
    drawer.setDrawer({
      ...drawer,
      direction: DrawerProps.direction.right,
      open: false,
    });
  };

  const submitForm = (skipValidation = false, isActive = true, levelID) => {
    const isValidated = skipValidation ? true : validation();

    if (!isValidated) {
      return false;
    }

    let __params = {
      // countryId: countryId,
      childs: state?.place_name,
      level_id: level_id,
      parent_child_level_id: parent_child_level_id,
      level: level ?? 0,
      child_level: childlevel ?? 0,
      isActive: true,
      client_id: client_id,
      tenantid,
    };

    backdrop.setBackDrop({
      ...backdrop,
      open: true,
      message: isEdit ? Message.update_level : Message.add_level,
    });

    Promise.resolve(
      dispatch(UPSERT_MANAGE_LOCATION_LEVEL_CHILD(__params))
    ).then((res) => {
      backdrop.setBackDrop({ ...backdrop, open: false });
      if (!res.payload.error) {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: isEdit
            ? Message.update_level_success
            : "Location(s) has been added successfully!",
        });

        state.place_name = [];
        onActionCompleted();
        closeDrawer();
      } else {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Something went wrong, Try Again!",
        });
      }
    });
  };

  return (
    <div className={classes.root}>
      <Grid
        container
        direction="column"
        justify="flex-start"
        alignItems="stretch"
      >
        <Grid item>
          <Typography className={classes.header}>
            {isEdit
              ? "Update " + level_name
              : "Add " +
                nextlevelName +
                (level === 1 ? level_name : `(${level_name})`)}
          </Typography>
          <Divider />
        </Grid>

        <Grid item className={classes.levelNameParent}>
          <CustionAddText
            // label={data?.question}
            label={"Add " + nextlevelName}
            options={state.place_name ? state.place_name : []}
            addTag={(value) => onChange(value)}
            // isReadonly={isReadyOnly}
            // isrequired={isrequired}
          />
        </Grid>

        <Grid
          item
          container
          justify={"space-between"}
          className={classes.btnParent}
        >
          <Grid item>
            <Button
              id="AddEditButton"
              variant="contained"
              color="primary"
              disabled={upsertLevel?.loading}
              onClick={() => submitForm(false, true, state.level_id)}
            >
              {isEdit
                ? upsertLevel?.loading
                  ? "Updating..."
                  : "Update " + nextlevelName
                : upsertLevel?.loading
                ? "Adding..."
                : "Add " + nextlevelName}
            </Button>
          </Grid>

          {isEdit && (
            <Grid item>
              <IconButton id="TrashButton" onClick={onDeleteBtnClicked}>
                <Delete color={"error"} />
              </IconButton>
            </Grid>
          )}
        </Grid>
      </Grid>
    </div>
  );
};
