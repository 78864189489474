import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  FormControlLabel,
  Checkbox,
  Button,
} from "@material-ui/core";
import {
  DependencyTable,
  StringValidations,
  NumericValidations,
  DateValidations,
  TableValidations,
  DobValidations,
  DefaultSection,
  MultitagOwn,
} from "../../components";
import { ComponentType } from "../../utils";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme?.spacing(2),
    "& .MuiFormControl-root": {
      width: "100%",
    },
    "& .MuiSelect-select:focus": {
      backgroundColor: "transparent",
    },
    "& .MuiFormHelperText-contained": {
      margin: 0,
      marginTop: 4,
      color: "red",
    },
  },
  gridItem: {
    padding: theme?.spacing(2),
  },
  addDependency: {
    marginTop: 12,
  },
}));

export default function QuestionProperties(props) {
  const classes = useStyles(props);
  const [validationState, setValidationState] = useState(
    props?.data?.validation ?? {}
  );

  const stringComponentType = (type) => {
    const optionComponentsList = ["input_text", "input_textarea"];
    if (optionComponentsList.includes(type)) {
      return true;
    } else {
      return false;
    }
  };

  const dateComponentType = (type) => {
    const optionComponentsList = ["input_date_picker"];
    if (optionComponentsList.includes(type)) {
      return true;
    } else {
      return false;
    }
  };

  const dateOfBirthComponentType = (type) => {
    const optionComponentsList = ["date_of_birth"];
    if (optionComponentsList.includes(type)) {
      return true;
    } else {
      return false;
    }
  };

  const numericComponentType = (type) => {
    const optionComponentsList = ["input_number"];
    if (optionComponentsList.includes(type)) {
      return true;
    } else {
      return false;
    }
  };

  const tableComponentType = (type) => {
    const optionComponentsList = ["custom_table", "custom_table_2"];
    if (optionComponentsList.includes(type)) {
      return true;
    } else {
      return false;
    }
  };

  const defaultSection = (type) => {
    const optionComponentsList = [
      "radio_button",
      "check_box",
      "select_modal_dropdown",
    ];
    if (optionComponentsList.includes(type)) {
      return true;
    } else {
      return false;
    }
  };
  const ownMultiTag = (type) => {
    const optionComponentsList = ["custom_multi_select_tag"];
    if (optionComponentsList.includes(type)) {
      return true;
    } else {
      return false;
    }
  };

  const onChange = (val, key) => {
    validationState[key] = val ? val : "";
    props?.onChangeComponents(validationState, props?.index, "validation");

    setValidationState(validationState);
  };

  React.useEffect(() => {
    if (props?.data?.validation) {
      setValidationState(props?.data?.validation);
    }
    // eslint-disable-next-line
  }, [props?.data?.validation]);

  return (
    <div className={classes.root}>
      <Grid container>
        {defaultSection(props?.data?.component ?? "") && (
          <DefaultSection
            data={props?.data}
            validationState={validationState}
            onChange={onChange}
          />
        )}
        {ownMultiTag(props?.data?.component ?? "") && (
          <MultitagOwn
            data={props?.data}
            validationState={validationState}
            onChange={onChange}
          />
        )}
        {ComponentType(props?.data?.component ?? "") && (
          <React.Fragment>
            <Grid item xs={12}>
              <Typography className={classes.gridItem}>
                {`Dependency Question`}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={props?.data?.has_dependency_qn ?? false}
                    onChange={(e) =>
                      props?.onChangeComponents(
                        !props?.data?.has_dependency_qn ?? false,
                        props?.index,
                        "has_dependency_qn"
                      )
                    }
                    name="hasDependencyQn"
                  />
                }
                label="Show another question / form / section based on answer"
              />
            </Grid>
          </React.Fragment>
        )}
        {props?.data?.has_dependency_qn && (
          <Grid item xs={12} className={classes.gridItem}>
            <DependencyTable
              data={props?.dependency}
              options={props?.options}
              parentSection={props?.parentSection}
              selectedSection={props?.selectedSection}
              quetions={props?.fromData}
              removeDependency={props?.addDeleteComponents}
              updateDependency={props?.updateDependency}
              parentIndex={props?.index}
            />

            <Button
              className={classes.addDependency}
              variant={"outlined"}
              size={"small"}
              color={"primary"}
              onClick={() =>
                props?.addDeleteComponents(props?.data, props?.index, true)
              }
            >
              + Add
            </Button>
          </Grid>
        )}
        {stringComponentType(props?.data?.component ?? "") && (
          <StringValidations
            validationState={validationState}
            onChange={onChange}
          />
        )}
        {numericComponentType(props?.data?.component ?? "") && (
          <NumericValidations
            validationState={validationState}
            onChange={onChange}
          />
        )}
        {dateComponentType(props?.data?.component ?? "") && (
          <DateValidations
            validationState={validationState}
            onChange={onChange}
          />
        )}
        {tableComponentType(props?.data?.component ?? "") && (
          <TableValidations
            onChangeComponents={(data, state) =>
              props?.onChangeComponents(data, props?.index, state)
            }
            data={props?.data}
          />
        )}

        {dateOfBirthComponentType(props?.data?.component ?? "") && (
          <DobValidations
            validationState={validationState}
            onChange={onChange}
          />
        )}
      </Grid>
    </div>
  );
}
