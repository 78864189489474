import React from "react";
import { withRouter } from "react-router-dom";
import { GeneralMaster } from "atp-general-master";
import { withNavBars } from "../../HOCs";
import { withTheme } from "@material-ui/core";

const Generalmaster = (props) => {
  // console.log("props", props);
  return (
    <div>
      <GeneralMaster
        URl= {process.env.REACT_APP_BASE_URL}
        db_name={process.env.REACT_APP_DB}
        metadataId={process.env.REACT_APP_METADATAID}
        metadata_dbname={process.env.REACT_APP_METADATA_DB_NAME}
        theme={props?.theme}
      />
    </div>
  );
};

export default withRouter(withNavBars(withTheme(Generalmaster)));
