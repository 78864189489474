import React from "react";
import { Grid, Paper } from "@material-ui/core";
import Chart from "./charts";
import { AxiosCall } from "../../utils";
import { AuthContext } from "../../contexts";
const NotifierDashBoard = () => {
  const [clientId, setClientId] = React.useState();

  React.useEffect(() => {
    getPersonLoggedIN();
  }, []);

  const getPersonLoggedIN = async () => {
    try {
      let keycloackid = localStorage.getItem("keyclkId");
      let params = {
        db_name: process.env.REACT_APP_DB,
        entity: "Person",
        filter: `Person.keycloackid=='${keycloackid}' && Person.activestatus==true`,
        return_fields: "Person",
      };
      let res = await AxiosCall(
        "post",
        `${process.env.REACT_APP_BASE_URL}/api/read_documents`,
        params
      );
      if (res.result.length > 0) {
        let result = res?.result[0]?._id;
        setClientId(result);

        // setLoading(false);
      } else {
        setClientId(null);
        // setLoading(false);
      }

      // console.log(result);
    } catch (error) {}
  };
  return (
    <Grid container spacing={2}>
      <Grid item lg={12} md={12} xl={12} xs={12}>
        <Chart
          title="Notification"
          basedOn="period"
          uniqueId={clientId}
          filterKey={"notifier"}
          monthlyQueryId={"cf0e293b-1693-4bb7-8a7b-b5943388fb3e"}
          dateQueryId={"ee2994b1-e7e0-4061-af02-fdbb5f4d17f9"}
          gridItem={4}
        />
      </Grid>
    </Grid>
  );
};

export default NotifierDashBoard;
