import React from 'react';
import {
    makeStyles,
    TableContainer,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    Button,
    Checkbox,
    TextField
} from "@material-ui/core";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
    root:{
        
    },
    titleText: {
        fontSize: 12,
        color: '#888790'
      },
      addbtn:{
          marginTop:12
      }
}));
const TableComponent = ({
    options=[],
    addRow

}) => {

    const classes = useStyles();

    const rowData = options?.row;
    const colData = options?.column;

    return (
       <div className={classes.root}>
          <TableContainer>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        {
                            colData && colData?.map((option,index)=>{
                                return(
                                    <TableCell key={index} variant="head">
                                        <Typography variant="body1" className={classes.titleText}>
                                            {option.heading}
                                        </Typography>
                                    </TableCell>
                                )
                            })
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        Object.keys(rowData) && Object.keys(rowData).map((l,i1)=>{
                            return(
                                <TableRow>
                                    {
                                        Object.keys(rowData[l]) && Object.keys(rowData[l]).map((d,i2)=>{
                                             switch (colData[i2].ui_component) {
                                                 case 'sno' :
                                                     return(
                                                        <TableCell>
                                                            <Typography variant="body2">
                                                            {rowData[l][d].sno}
                                                            </Typography>                          
                                                      </TableCell>
                                                     )

                                                  case 'input_text':
                                                     return(
                                                        <TableCell>
                                                            <TextField 
                                                                value={rowData[l][d].value}
                                                                // onChange={(value) => onChange(index, data, value)}
                                                                // label={rowData[l][d].label}
                                                            />
                                                        </TableCell>
                                                     )
                                                    case 'check_box':
                                                        return(
                                                            <TableCell>
                                                                <Checkbox 
                                                                    // checked={false}
                                                                    // onChange={handleChange}
                                                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                                                />
                                                            </TableCell>
                                                        )
                                                    default:
                                                        return <TableCell>
                                                        <Typography variant="body2" className={classes.bodyRowText}>
                                                            {rowData[l][d].sno}
                                                        </Typography>
                                                        </TableCell>
                                              }
                                        })
                                    }
                                </TableRow>
                            )
                        })
                        
                    }
                </TableBody>
            </Table>
          </TableContainer>
          <Button
            className={classes.addbtn}
            variant={"outlined"}
            size={"small"}
            color={"primary"}
            onClick={() => addRow(Object.keys(rowData).length)}
        >
            + Add
        </Button>
       </div>
    )
}
TableComponent.propTypes = {
    option: PropTypes.array, // EX: [{value: "", label: ""}],
    addRow: PropTypes.func
}

export default TableComponent;
