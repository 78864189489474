import React from "react";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import {
    Box,
    Button,
    Dialog,
    Divider,
    Grid,
    IconButton,
    makeStyles,
    Slide,
    TablePagination,
    TextField,
    Tooltip,
    Typography,
    useMediaQuery,
    useTheme,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    withStyles,
} from "@material-ui/core";
import Diagnosis from "./diagnosis";
import PatientContent from "./patientContent";
import Patiantprofile from "./patientprofile";
import PatientHistory from "./patientHistory";

const useStyles = makeStyles((theme) => ({
    cartBody: {
        height: "100%",
    },
    header: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "8px 16px",
        background: theme.palette.background.table,
        height: "54px",
        borderBottom: " 1px solid #e0e0e0",
    },
    header2: {
        height: "54px",
        padding: "0 16px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    header3: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "8px 16px",
        background: theme.palette.background.table,
        height: "54px",
        borderBottom: " 1px solid #e0e0e0",
        [theme.breakpoints.down("sm")]: {
            height: "fit-content",
        },
    },
    subHeading: {
        height: "48px",
        padding: "0 16px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        //position:"fixed"
    },
    subHeadingXs: {
        height: "48px",
        padding: "0 16px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",

        [theme.breakpoints.down("sm")]: {
            flexDirection: "column",
            alignItems: "flex-start",
            height: "fit-content",
        },
    },
    flexBetween: {
        padding: "16px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    content: {
        height: "calc(100% - 108px)",
        background: theme.palette.background.gray,
    },
    sidepanel: {
        padding: theme.spacing(2),

        borderRadius: "8px",
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
        },
    },
    mainpanel: {
        padding: theme.spacing(2),
        height: "100%",
        borderRadius: "8px",
        overflowY: "auto",

        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
        },
    },
    mainBody1: {
        padding: "8px",
        borderRadius: "8px",
        overflowY: "auto",
        height: "calc(100% - 48px)",
        [theme.breakpoints.down("sm")]: {
            height: "calc(100% - 120px)",
        },
    },
    mainBody2: {
        padding: "8px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "calc(100% - 48px)",
        borderRadius: "8px",
    },
    cardParent: {
        background: "#fff",
        borderRadius: "8px",
        height: "100%",
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
        },
    },
    disclaimerBox: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "8px 16px",
        borderRadius: "8px",
        margin: "8px 16px",

        background: `${theme.palette.success.light}96`,
        flex: "1 1 auto",
        color: theme.palette.success.main,
    },
    creditBox: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "8px 16px",
        borderRadius: "8px",
        margin: "8px 16px",

        // background: `${theme.palette.success.light}96`,
        background: "#B38BEA",
        flex: "1 1 auto",
        color: "black",
    },
    footer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "8px 16px",
        background: theme.palette.background.table,
        height: "54px",
        borderBottom: " 1px solid #e0e0e0",
        "@media (max-width:400px)": {
            width: "100%",
            flexDirection: "column",
            // alignItems: "flex-start",
            height: "fit-content",
            borderBottom: "none",
        },
    },
    TablePagination: {
        "& .MuiTablePagination-menuItem": {
            textAlign: "start",
            paddingRight: "8px",
        },
    },
    textResponsive: {
        // fontSize: "0.9rem !important",
        maxWidth: "130px",
        marginRight: "16px !important",

        [theme.breakpoints.down("md")]: {
            fontSize: "0.8rem !important",
            marginRight: "16px !important",
        },
        [theme.breakpoints.down("sm")]: {
            fontSize: "0.8rem !important",
            marginRight: "16px !important",
            maxWidth: "100px",
        },
    },
    discount: {
        color: "#f44336",

        maxWidth: "130px",
        marginRight: "16px !important",

        [theme.breakpoints.down("md")]: {
            fontSize: "0.8rem !important",
            marginRight: "16px !important",
        },
        [theme.breakpoints.down("sm")]: {
            fontSize: "0.8rem !important",
            marginRight: "16px !important",
            maxWidth: "100px",
        },
    },
    maincontent: {
        height: "calc(100vh - 200px)",
        overflow: "auto",
    },
    textResponsive1: {
        // fontSize: "1.1rem !important",
        fontSize: "20 px",
        // color: theme.palette.primary.main,

        [theme.breakpoints.up("md")]: {
            fontSize: "1rem !important",
        },
        [theme.breakpoints.down("md")]: {
            fontSize: "0.8rem !important",
        },
        [theme.breakpoints.down("sm")]: {
            fontSize: "0.7rem !important",
        },
    },
}));

const PatientList = (props) => {
    const { backToSearch, selectedPatient } = props;
    const classes = useStyles();
 
    return (
        <>
            <Box className={classes.cartBody}>
                <Box className={classes.header}>
                    <Typography>
                        <Tooltip title="Back" placement="top">
                            <IconButton>
                                <ArrowBackIcon
                                    onClick={() => backToSearch()}
                                    color="primary"
                                    fontSize="small"
                                />
                            </IconButton>
                        </Tooltip>

            {"Patient Details"}
          </Typography>
          <Box>
            <Typography
              //color="primary"
              variant="body2"
              className={classes?.textResponsive1}
            ></Typography>
          </Box>
        </Box>
        <PatientContent selectedPatient={selectedPatient} patientId={selectedPatient.patientId} />
      </Box>
    </>
  );
};

export default PatientList;
