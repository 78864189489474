/**
 * @author Kameshwaran Murugan
 * @email kamesh@qdmplatforms.com
 * @create date 2021-01-27
 * @modify date 2021-06-29
 * @desc Form Renderer Parent
 */

import React from "react";
// import { withNavBars } from "../../HOCs";
import { FormRenderer } from "./formRenderer";
// import { actions } from "binder-engine";
// import { connect } from "react-redux";
// import { withRouter } from "react-router-dom";
// import {
//   LoadingSection,
//   InternatlServerError,
//   NotFoundSection,
// } from "../../components";
// import { giveMeRoleDataSecurity, Message } from "../../utils";
// import { Routes } from "../../router/routes";
import copy from "fast-copy";

class FormRendererParent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    // this.getAllCitizen();
    // this.getFormDetail();
    // this.getCountryList();
    // this.getPatient();
    // this.setState({ ...this.props.formRendering });
  }

  giveMeStatus = () => {
    switch (this.props.secName) {
      case 0:
        return "notifier_status";
      case 1:
        return "verifier_status";

      case 2:
        return "registration_status";

      case 3:
        return "investigator_status";

      default:
        return "";
    }
  };

  giveMeStatusData = (statusArray, entry) => {
    let toogleData = {
      toggleButtonData: statusArray?.map((_) => {
        if (_.label === "For Confirmation") {
          return {
            ..._,
            label: "Pending",
          };
        }
        if (_.label === "For Investigation") {
          return {
            ..._,
            label: "Review",
          };
        }
        return _;
      }),
      isSelected: entry[this.giveMeStatus()],
      exclusive: true,
      statusUpdateData: entry,
      status: this.giveMeStatus(),
    };
    return toogleData;
  };

  canIshowForm = () => {
    if ([1, 2, 3].indexOf(this.props.secName) > 0) {
      return (
        Object.values(this.props?.form_detail?.data?.answers ?? {}).length > 0
      );
    } else {
      return true;
    }
  };

  render() {
    const {
      form = [],
      form_detail = [],
      tree = [],
      bulkAnswers = {},
      answers = {},
      statusArray = [],
      patientDetail = {},
    } = this.props?.form_detail;
    // const transferDetails = this.props?.form_detail?.data?.transferDetails;

    this.copiedForm = copy(form);

    const formId = this.props.formid;
    const entryId = this.props.entryId;
    const isInvestigator = false;
    const isReadyOnly = true;
    // const dataSecurityMappedCountryAndSelectedlevel =
    //   this.props.userData?.mapped_roles[0]?.data_security ?? {};
    // const mappedLocations =
    //   this.props.userData?.mapped_roles[0]?.mapped_location ?? {};

    return (
      // <div id="divOne">
      <FormRenderer
        verifier_comments_status={this.props?.verifier_comments_status}
        form_detail={form_detail}
        onSaveForm={this.props.onSaveForm}
        // transferDetails={transferDetails}
        tree_view={tree}
        forms={this.props.forms}
        parent_form_id={formId}
        entry_id={entryId}
        isEdit={entryId ? true : false}
        isReadyOnly={isReadyOnly}
        answer={this.props.answer}
        isInvestigator={isInvestigator}
        toggleButtonStatus={
          entryId && this.canIshowForm()
            ? this?.giveMeStatusData(statusArray, bulkAnswers)
            : {}
        }
        secToShow={this?.props?.secToShow}
        secName={this?.props?.secName}
        getPatientDetails={this?.props?.patient_details}
        entryPatientDetails={copy(this?.props?.patient_details)}
        UserRole={this?.props?.UserRole}
        bulkAnswers={bulkAnswers}
        onReloadActionPerformed={this?.props?.onReloadActionPerformed}
        goBack={this?.props?.goBack}
      />
      // </div>
    );
  }
}

export default FormRendererParent;
