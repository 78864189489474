import React from "react";
import { Paper, makeStyles, Divider } from "@material-ui/core";
//import { TabComponent } from '../../components';
import { AddAddress } from "./addAddress";
import { ContactDetail } from "./contactDetails";
// import { AliasDetails } from './aliasDetails';
// import { ContactPersonAddress } from './contactPersonAddress';
import { Tabs, Tab } from "@material-ui/core";
import TabPanel from "@material-ui/lab/TabPanel";
import TabContext from "@material-ui/lab/TabContext";

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    padding: "9px",
  },

  tab: {
    textTransform: "capitalize",
  },
}));

export const AddressDetails = ({ state = {}, updateState = () => false }) => {
  const classes = useStyles();
  const [value, setValue] = React.useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div className={classes.root}>
      <Paper
        style={{
          width: "100%",
          background: "#FFFFFF 0% 0% no-repeat padding-box",
          "box-shadow": "0px 10px 25px #0000000A",
          "border-radius": "8px",
          opacity: 1,
        }}
      >
        <TabContext value={value}>
          <Tabs
            value={value}
            indicatorColor="secondary"
            onChange={handleChange}
          >
            {/* <Tab className={classes.tab} label="Alias" value="1" /> */}
            <Tab className={classes.tab} label="Address" value="1" />
            <Tab className={classes.tab} label="Contact Details" value="2" />
            {/* <Tab className={classes.tab} label="Contact Person Details" value="4" /> */}
          </Tabs>
          <Divider />

          <TabPanel value="1">
            <AddAddress Address={state?.Address} updateAddress={updateState} />
          </TabPanel>
          <TabPanel value="2">
            <ContactDetail
              Contact={state.Contact}
              updateContact={updateState}
              multiContact={state?.EntityType?.label !== "Facility"}
            />
          </TabPanel>
        </TabContext>

        {/* </Grid> */}
      </Paper>
    </div>
  );
};
