import React from "react";
// import { Grid } from "@material-ui/core";
// import { Card1, SubHeader } from "../../components";
import { ManageCard } from "atp-crud";
import { DialogContext } from "../../contexts";
import { withTheme } from "@material-ui/core";
const ListOfClients = (props) => {
  const {
    clientList = [],
    onAddNewClicked = () => false,
    onEditClicked = () => false,
    onDeleteClicked = () => false,
  } = props;
  const dialogContext = React.useContext(DialogContext);
  const onClickAddNewButton = () => {
    onAddNewClicked();
  };

  // const searchfunc = () => {};

  const editfunc = (data) => {
    onEditClicked(data);
  };

  const deletefunc = (data) => {
    dialogContext.setDialog({
      ...dialogContext,
      open: true,
      title: "Are you sure you want to delete this client?",
      body: "",
      positiveBtn: "Confirm",
      negativeBtn: "Cancel",
      onOk: () => confirmDelete(data),
    });
  };

  const confirmDelete = (data) => {
    dialogContext.setDialog({
      ...dialogContext,
      open: false,
    });
    onDeleteClicked(data);
  };

  return (
    <>
      {/* <SubHeader
        title={"Manage Clients"}
        searchPlaceholder={"Search by client name"}
        onClickAddNewButton={onClickAddNewButton}
      />
      <Grid container>
      {
        list.map(l=><Card1
          keyValues={{
            title: l?.clientname,
            email: l?.email,,
            loaction: l?.location,,
          }}
        />)
      }

      </Grid> */}

      <ManageCard
        // globalsrc={"/virus.svg"}
        basedata={clientList}
        title={"Manage Clients"}
        topbuttonName={"Add Client"}
        // hideFilter={false}
        // showSort={true}
        // sortOptions={[
        //   { label: "name", value: "name" },
        //   { label: "age", value: "age" },
        // ]}
        editfunc={editfunc}
        addfunc={onClickAddNewButton}
        // sortfunc={sortfunc}
        // filterfunc={filterfunc}
        deletefunc={deletefunc}
        // searchfunc={searchfunc}
        isMoreBtn={true}
        hideAvatar={true}
        hideEditBtn={false}
        hideDeleteBtn={false}
        hideSearchBar={true}
        divider={true}
        // isFilterApplied={true}
        keysformap={{
          //   src_key: "first_name",
          //   alt_key: "alt",
          title_key: "client_name",
          // cardname_key: "classification.display",
          //   subtitle_key: "icd_10",
          //   url_key: "desigination",
          // email_key: "address.contactby",
        }}
        hidebtn2={true}
        // onBtn2Clicked={() => alert("ji")}
        // Btn2Name={"btn1"}
        hidebtn1={true}
        // onBtn1Clicked={() => alert("ji")}
        // Btn1Name={"okoo"}
        // onActionIconBtnClicked={() => alert("null")}
        show_card={false}
        show_sub_title={false}
        inbuiltsearchfunctinality={false}
        // theme={require("../../themes/default.json")}
        theme={props?.theme}
      />
    </>
  );
};

export default withTheme(ListOfClients);
