import React from "react";
import { withNavBars } from "../../HOCs";
import { withStyles } from "@material-ui/core";
import { actions } from "surveillance-binder";
import { connect } from "react-redux";
import { ListNotificationsV2 } from "./listNotificationsV2";
import { giveMeRoleDataSecurity } from "../../utils";

const styles = (theme) => ({
  root: {
    backgroundColor: "#F8F8F8",
  },
});
class ListNotificationParentV2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sort: "all",
      icd10: [],
      classification: [],
      isFilterApplied: false,
      title: "",
    };
  }

  async componentDidMount() {
    await this.props.GET_FORM_DATA();
    this.getNotificationForms();
  }

  getNotificationForms = (searchKey = "", created_at = false) => {
    const roleDataSecurity = giveMeRoleDataSecurity(this.props.userData);
    const createdBy = this.props.userData.user_profile_id;

    const { classification, icd10 } = this.state;

    let sort = {
      sortField: "form_name",
      sortDirection: "ASC",
      forms: roleDataSecurity.form?.[0] === "all" ? [] : roleDataSecurity.form,
      countryIds:
        roleDataSecurity.country?.[0] === "all" ? [] : roleDataSecurity.country,
    };

    if (created_at) {
      sort.sortField = "updated_at";
      sort.sortDirection = "DESC";
    }

    let formTypeId = "";

    if (this.state.sort !== "all") {
      formTypeId = this.state.sort;
    }

    if (icd10.length) sort.icd10 = icd10.map((_) => _.value);

    if (classification.length)
      sort.classification = classification.map((_) => _.value);

    if (createdBy) {
      sort.created_by = createdBy;
    }

    this.props.GET_ALL_NOTIFIABLE_FORMS({
      formName: searchKey.trim(),
      formTypeId,
      ...sort,
    });
  };

  applyFilter = (data, isFilterApplied = false) => {
    this.setState(
      {
        ...data,
        isFilterApplied,
      },
      () =>
        this.getNotificationForms(
          document.getElementById("ippc_search_bar").value
        )
    );
  };

  getNotifiedEntryByFormId = (form) => {
    // let formId = this?.props?.match?.params?.id;

    this.setState({
      ...this.state,
      title: form.form_name,
    });

    let params = {
      formId: form.form_id,
      type: "notification",
    };

    this.props.GET_ENTRIES_BY_FORM_ID(params);
  };

  onStatusUpdate = async (status, data) => {
    await this.props.UPDATE_ENTRY_STATUS({
      ...data,
      notifier_status: status,
    });

    this.getNotificationForms();
  };

  render() {
    const { classes, getAllNotifiableForms, getEntriesByFormId } = this.props;

    return (
      <div className={classes.root}>
        <ListNotificationsV2
          {...getAllNotifiableForms}
          {...this.state}
          applyFilter={this.applyFilter}
          getNotificationForms={this.getNotificationForms}
          entriesData={getEntriesByFormId}
          getNotifiedEntryByFormId={this.getNotifiedEntryByFormId}
          entriesTitle={this.state.title}
          onStatusUpdate={this.onStatusUpdate}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  getAllNotifiableForms: state?.workflowSlice?.getAllNotifiableForms,
  getEntriesByFormId: state?.workflowSlice?.getEntriesByFormId,
  userData: state.formSlice.getFormData.data ?? {},
});

export default connect(
  mapStateToProps,
  actions
)(withNavBars(withStyles(styles)(ListNotificationParentV2)));
