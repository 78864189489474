import * as React from "react";
import PropTypes from "prop-types";

const TimesheetIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={13} height={12.683} {...props}>
    <path
      d="M1.744 0A1.746 1.746 0 0 0 0 1.744v7.768a1.9 1.9 0 0 0 1.9 1.9h4.145a4.09 4.09 0 0 1-.541-1.268H1.9a.635.635 0 0 1-.634-.634V3.171h9.514v2.094a4.108 4.108 0 0 1 1.268.676v-4.2A1.746 1.746 0 0 0 10.3 0ZM2.7 4.439a.476.476 0 1 0 0 .951h.634a.476.476 0 1 0 0-.951Zm2.537 0a.476.476 0 0 0 0 .951h2.694a4.106 4.106 0 0 1 1.586-.317c.1 0 .189.008.284.014a.474.474 0 0 0-.442-.648Zm4.28 1.268A3.488 3.488 0 1 0 13 9.2a3.488 3.488 0 0 0-3.488-3.493ZM2.7 6.341a.476.476 0 1 0 0 .951h.634a.476.476 0 1 0 0-.951Zm2.537 0a.476.476 0 0 0 0 .951h.626a4.128 4.128 0 0 1 .684-.951Zm3.963.634a.317.317 0 0 1 .317.317v1.9H11.1a.317.317 0 0 1 0 .634H9.2a.317.317 0 0 1-.317-.317v-2.22a.317.317 0 0 1 .317-.313ZM2.7 8.244a.476.476 0 1 0 0 .951h.634a.476.476 0 1 0 0-.951Zm2.537 0a.476.476 0 0 0 0 .951h.159a4.127 4.127 0 0 1 .115-.951Z"
      fill={props.color}
    />
  </svg>
);

export default TimesheetIcon;

TimesheetIcon.defaultProps = {
  color: "#2a3c50",
};

TimesheetIcon.propTypes = {
  color: PropTypes.string,
};
