import {
  Button,
  Grid,
  IconButton,
  makeStyles,
  Switch,
  Paper,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import BusinessIcon from "@material-ui/icons/Business";
import { useDispatch } from "react-redux";
import { actions } from "surveillance-binder";
import {
  Facility,
  FacilityCode,
  Level,
  Levelofcare,
  Address,
} from "../../assets";
import AccountTreeOutlinedIcon from "@material-ui/icons/AccountTreeOutlined";
import LaunchIcon from "@material-ui/icons/Launch";
import { FacilityTabComponent } from "./facilityTabComponent";

const useStyles = makeStyles((theme) => ({
  titleDev: {
    height: "7vh",
    // backgroundColor: "#fff",
    background: "#fff",
    display: "flex",
    alignItems: "center",
    paddingLeft: "4%",
    position: "relative",
    borderLeft: "2px solid #f6f6f6",
  },
  left_arrow: {
    padding: 6,
    backgroundColor: "white",
    position: "absolute",
    left: "-1%",
    bottom: "-26%",
    "& svg": {
      fontSize: 16,
      paddingLeft: 4,
    },
    boxShadow: "0 0px 1px 0 rgb(0 0 0 / 20%)",
    border: "1px solid #dddddd;",
    zIndex: 999,
  },
  rightSide: {
    right: 16,
    textAlign: "right",
    position: "relative",
  },
  leftSide: {
    textAlign: "left",
    left: 24,
    position: "relative",
  },
  Div: {
    paddingTop: "1%",
    display: "flex",
    alignItems: "center",
  },
  hosp_name: {
    fontSize: 16,
    // color: "#000",
    color: "#fff",
    fontWeight: "bold",
  },
  hosp_pic: {
    borderRadius: 20,
  },
  detailTitle: {
    // color: "#000",
    color: "#fff",
    fontWeight: "bold",
    fontSize: 14,
    textAlign: "left",
  },
  detailBox: {
    display: "flex",
    alignItems: "center",
    paddingBottom: "1%",
  },
  detailDiv: {
    marginTop: "2%",
  },
  boxFirstDiv: {
    marginRight: "2%",
    marginBottom: "2%",
  },
  detailData: {
    marginLeft: "2.6vh",
    textAlign: "left",
  },
  iconStyle: {
    fontSize: "18px",
    color: "#0071F2",
    marginRight: 4,
  },
  addressbox: {
    padding: 12,
  },
  root: {
    "& .App1-MuiTabs-root": {
      "& .App1-MuiTabs-scroller": {
        "& .App1-MuiTabs-flexContainer": {
          alignItems: "center",
          justifyContent: "flex-start !important",
          margin: "0px 17px",
          "& .App1-MuiButtonBase-root": {
            marginRight: "2% !important",
          },
        },
      },
    },
  },
  detailDiv: {
    padding: 20,
    paddingBottom: "0px",
  },
  detailDiv1: {
    // padding: 20,
    padding: "16px 20px 10px 20px",
    // borderBottom: "1px solid #e0e0e0"
  },
  wrapper: {
    padding: "0px 20px",
  },
  buttonStyle: {
    background: "#fff",
    color: "#fff",
    "&:hover": {
      background: "#fff",
    },
    gridItem: {
      padding: theme.spacing(0.5),
      "& input[type=number]::-webkit-inner-spin-button,input[type=number]::-webkit-outer-spin-button":
        {
          margin: 0,
          display: "none",
        },
    },
    gridItem1: {
      padding: theme.spacing(1),
      "& input[type=number]::-webkit-inner-spin-button,input[type=number]::-webkit-outer-spin-button":
        {
          margin: 0,
          display: "none",
        },
    },
    Addresscard: {
      width: "100%",
      padding: "10px 0",
      backgroundColor: "#F9F9F9",
      display: "flex",
      alignItems: "center",
      borderRadius: "10px",
    },
    gapL: {
      marginLeft: "28px",
      padding: theme.spacing(0.5, 0),
    },
    align: {
      display: "flex",
      alignItems: "center",
    },
    icon: {
      width: "15px",
      height: "15px",
      marginRight: "6px",
      color: theme.palette.primary.main,
    },
    dashed: {
      height: "0",
      width: "80%",
      borderBottom: "1px dashed #E0E0E0",
    },
    imgStyle: {
      maxWidth: "100%",
      height: "inherit",
    },
  },
  gridItem: {
    padding: theme.spacing(0.5),
    "& input[type=number]::-webkit-inner-spin-button,input[type=number]::-webkit-outer-spin-button":
      {
        margin: 0,
        display: "none",
      },
  },
  gridItem1: {
    padding: theme.spacing(1),
    "& input[type=number]::-webkit-inner-spin-button,input[type=number]::-webkit-outer-spin-button":
      {
        margin: 0,
        display: "none",
      },
  },
  Addresscard: {
    width: "100%",
    padding: "10px 0",
    backgroundColor: "#F9F9F9",
    display: "flex",
    alignItems: "center",
    borderRadius: "10px",
  },
  gapL: {
    marginLeft: "28px",
    padding: theme.spacing(0.5, 0),
  },
  align: {
    display: "flex",
    alignItems: "center",
  },
  icon: {
    width: "15px",
    height: "15px",
    marginRight: "6px",
    color: theme.palette.primary.main,
  },
  dashed: {
    height: "0",
    width: "80%",
    borderBottom: "1px dashed #E0E0E0",
  },
  imgStyle: {
    maxWidth: "100%",
    height: "inherit",
  },
  spacebtw: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));
export const OrgView = (props) => {
  const {
    isform,
    handlekey,
    entity,
    onClickformClick,
    completeForm,
    handleEdit,
  } = props;
  const [entityDetails, setentityDetails] = useState([]);

  const classes = useStyles();
  const dispatch = useDispatch();
  const parent_id = "0001";
  const [edit, setEdit] = React.useState({
    isEdit: false,
    editData: null,
  });
  // const entityDetails = useSelector(
  //   (state) => state?.organizationSlice?.getOrgDetails
  // );

  React.useEffect(() => {
    if (handlekey) {
      getOrgDetails(handlekey);
    }
    if (!isform) {
      setEdit({
        ...edit,
        isEdit: false,
        editData: null,
      });
    }

    //eslint-disable-next-line
  }, [handlekey, isform]);

  const getOrgDetails = async () => {
    const data = await dispatch(
      actions.ORGANIZATION_READ_DETAILS({
        _key: handlekey,
        client_id: props.ClientId,
      })
    );

    setentityDetails([data?.payload?.data]);
  };

  // const handleEdit = (data) => {
  //   setEdit({
  //     ...edit,
  //     isEdit: true,
  //     editData: data,
  //   });
  //   onClickformClick(handlekey, "", true);
  //   // disableAddNew();
  // };

  const UpdateDone = async () => {
    setEdit({
      ...edit,
      isEdit: false,
      editData: null,
    });
    completeForm(false);
    await dispatch(actions.ORGANIZATION_TREE_READ({ isexternal: false }));
  };

  const backButtonClicked = () => {
    setEdit({
      ...edit,
      isEdit: false,
      editData: null,
    });
    completeForm(false);
  };

  return (
    <>
      {isform ? (
        !edit.isEdit ? (
          <div></div>
        ) : (
          <div></div>
        )
      ) : (
        <>
          {entityDetails?.length > 0 ? (
            <>
              <div className={classes.spacebtw}>
                <Typography varient="body1">
                  {entityDetails[0]?.entityName ?? ""}
                </Typography>
                <div style={{ display: "flex" }}>
                  <Button
                    size="medium"
                    variant="contained"
                    color="primary"
                    onClick={(e) => handleEdit(entityDetails[0]._key)}
                    id="addAndUpdateAddressButton"
                    className={classes.marginL}
                    disabled={props.disableAddNew}
                  >
                    {"Edit"}
                  </Button>
                </div>
              </div>
              <Paper
                id={"paper1"}
                style={{
                  width: "100%",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  marginTop: "15px",
                  borderRadius: "8px",
                }}
              >
                <div>
                  <div style={{ display: "flex" }} id={"div1"}>
                    <div
                      style={{
                        width: "125px",
                        height: "125px",
                        background: "#e0e0e0",
                        borderRadius: "10px",
                        margin: "10px 20px 5px 10px",
                      }}
                    >
                      {entityDetails[entityDetails?.length - 1]?.logo?.file && (
                        <img
                          className={classes.imgStyle}
                          src={
                            entityDetails[entityDetails?.length - 1]?.logo?.file
                          }
                          alt="Profile"
                        />
                      )}
                    </div>
                    <div className={classes.root} style={{ width: "100%" }}>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          variant="body1"
                          className={classes.gridItem}
                        >
                          Facility Details
                        </Typography>
                        <span className={classes.dashed}></span>
                      </div>
                      <Grid container>
                        <Grid item lg={4}>
                          <div className={classes.align}>
                            <Facility
                              color="#4448C2"
                              className={classes.icon}
                            />
                            <Typography variant="caption">
                              {"Facility Name"}
                            </Typography>
                          </div>
                          <Typography variant="body1" className={classes.gapL}>
                            {entityDetails[entityDetails?.length - 1]
                              ?.entityName ?? <span>--</span>}
                          </Typography>
                        </Grid>
                        <Grid item lg={4}>
                          <div className={classes.align}>
                            <FacilityCode className={classes.icon} />
                            <Typography variant="caption">
                              {"Facility Code"}
                            </Typography>
                          </div>
                          <Typography variant="body1" className={classes.gapL}>
                            {entityDetails[entityDetails?.length - 1]
                              ?.entityCode ?? <span>--</span>}
                          </Typography>
                        </Grid>
                        <Grid item lg={4}>
                          <div className={classes.align}>
                            <AccountTreeOutlinedIcon className={classes.icon} />
                            <Typography variant="caption">
                              {"Parent Enterprise"}
                            </Typography>
                          </div>
                          <Typography variant="body1" className={classes.gapL}>
                            {entityDetails[entityDetails?.length - 1]
                              ?.parentEntity?.label ? (
                              entityDetails[entityDetails?.length - 1]
                                ?.parentEntity?.label
                            ) : (
                              <span>--</span>
                            )}
                          </Typography>
                        </Grid>
                        <Grid item lg={4}>
                          <div className={classes.align}>
                            <AccountTreeOutlinedIcon className={classes.icon} />
                            <Typography variant="caption">
                              {"Parent Organization"}
                            </Typography>
                          </div>
                          <Typography variant="body1" className={classes.gapL}>
                            {entityDetails[entityDetails?.length - 1]
                              ?.parentOragnization ?? <span>--</span>}
                          </Typography>
                        </Grid>
                        <Grid item lg={4}>
                          <div className={classes.align}>
                            <Levelofcare className={classes.icon} />
                            <Typography variant="caption">
                              {"Level Of Care"}
                            </Typography>
                          </div>
                          <Typography variant="body1" className={classes.gapL}>
                            {entityDetails[entityDetails?.length - 1]
                              ?.levelOfCare?.label ?? <span>--</span>}
                          </Typography>
                        </Grid>
                        <Grid item lg={2}>
                          <div className={classes.align}>
                            <Level className={classes.icon} />
                            <Typography variant="caption">{"Level"}</Typography>
                          </div>
                          <Typography variant="body1" className={classes.gapL}>
                            {entityDetails[entityDetails?.length - 1]?.level ? (
                              entityDetails?.level
                            ) : (
                              <span>--</span>
                            )}
                          </Typography>
                        </Grid>
                        <Grid item lg={2}>
                          <div className={classes.align}>
                            <LaunchIcon className={classes.icon} />
                            <Typography variant="caption">
                              {"External"}
                            </Typography>
                          </div>
                          <Typography variant="body1" className={classes.gapL}>
                            {"No"}
                          </Typography>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                  <Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      lg={12}
                      className={classes.gridItem1}
                    >
                      <div className={classes.align}>
                        <Address className={classes.icon} />
                        <Typography variant="caption">{"Address"}</Typography>
                      </div>
                      {entityDetails[entityDetails?.length - 1]?.address ? (
                        <div>
                          {entityDetails[
                            entityDetails?.length - 1
                          ]?.address?.map((_, i) => {
                            return (
                              <Typography
                                variant="body1"
                                className={classes.gapL}
                              >
                                {i + 1} - &nbsp;
                                {`${_?.address1}, ${_?.address2}, ${_?.address3}, ${_?.city}, ${_?.district}, ${_?.state}, ${_?.country}, ${_?.pincode}.`}
                              </Typography>
                            );
                          })}
                        </div>
                      ) : (
                        <Typography variant="body1" className={classes.gapL}>
                          -
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </div>
              </Paper>

              <Paper>
                <FacilityTabComponent
                  Contact={
                    entityDetails[entityDetails?.length - 1]?.contactData
                  }
                />
              </Paper>
            </>
          ) : (
            <div
              id={`${parent_id}-BusinessIcon-div`}
              style={{ display: "grid", height: "100%" }}
            >
              <div
                id={`${parent_id}-BusinessIcon-sub-div`}
                style={{
                  margin: "auto",
                }}
              >
                <BusinessIcon
                  id={`${parent_id}-BusinessIcon`}
                  style={{ fontSize: 120, color: "#e0e0e0" }}
                />
                <p
                  id={`${parent_id}-Organization-p`}
                  style={{ color: "#d0d0d0", fontSize: 18 }}
                >
                  Select Organization
                </p>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};
