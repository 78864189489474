import React from 'react';
import {
    Grid, Avatar, Typography, Divider, Tooltip
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from 'prop-types';
export * from './loading';
export * from './cardListLoader';

const useStyles = makeStyles((theme) => ({
    root: {
    },
    avatar: {
        width: 30,
        height: 30,
        backgroundColor: theme.palette.secondary.main,
    },
    cardDetails: {
        padding: theme.spacing(2),
        cursor: 'pointer'
    },
    countStyle: {
        fontSize: 12
    }
}));

export const CardList = (props) => {

    const classes = useStyles(props);

    const {
        title = '',
        age = '',
        gender = '',
        mrMs = '',
        firstName = '',
        middleName = '',
        lastName = '',
        phoneAndEmail = '',
        count = 0,
        onCardClicked = false,
        hideCount = false,
        activeForm = "",
        id = "",
        keys = "",
        diseaseName = ""
    } = props;

    return (
        <div className={classes.root}>
            <div
                className={classes.cardDetails}
                onClick={onCardClicked}
                style={{ borderLeft: activeForm === id && '3px solid #F5B800' }}
            >
                <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justify="space-between"
                >
                    {firstName && <Grid item xs={12} id={"gridOne"+keys}>
                        <Tooltip title={`${mrMs ?? ''} ${firstName ?? ''} ${middleName ?? ""} ${lastName ?? ""}`}>
                            <Typography variant="body1" id={"name"+keys}>
                                {`${mrMs ?? ""} ${firstName ?? ""} ${lastName?.[0] ?? ""}`}
                                <Typography variant="caption" style={{ marginLeft: 8 }}>{`${gender?.[0] ?? ""}, ${age ?? "-"}y`}</Typography>
                            </Typography>
                        </Tooltip>
                        <Typography variant="caption" id={"phoneAndEmail"+keys}>{phoneAndEmail ?? ''}</Typography>
                    </Grid>}
                    {title && <Grid item xs={9} id={"gridTwo"+keys}>
                        <Typography id={"title"+keys} variant={"body1"}>{title}</Typography>
                        <Typography id={"diseaseName"+keys} variant={"caption"}>{diseaseName}</Typography>
                    </Grid>}
                    {!hideCount &&
                        <Grid item={3} id={"gridThree"+keys}>
                            <Avatar className={classes.avatar}>
                                <Typography id={"count"+keys} variant="h1" className={classes.countStyle}>{count}</Typography>
                            </Avatar>
                        </Grid>
                    }
                </Grid>
            </div>
            <Divider />
        </div>
    )
}

CardList.propTypes = {
    title: PropTypes.string,
    mrMs: PropTypes.string,
    firstName: PropTypes.string,
    middleName: PropTypes.string,
    lastName: PropTypes.string,
    phoneAndEmail: PropTypes.string,

    age: PropTypes.string,
    gender: PropTypes.string,
    count: PropTypes.number,
    onCardClicked: PropTypes.func,
    hideCount: PropTypes.bool,

}