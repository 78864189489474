import React from "react";
// import { TextField } from '@material-ui/core'
import {
  Typography,
  Grid,
  makeStyles,
  Button,
  IconButton,
  TextField,
} from "@material-ui/core";
import { Select } from "../../components";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import { useSelector } from "react-redux";
import { ValidateEmail } from "../../utils";
import copy from "fast-copy";
const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
  },
  gridItem: {
    padding: theme.spacing(2),
    "& input[type=number]::-webkit-inner-spin-button,input[type=number]::-webkit-outer-spin-button":
      {
        margin: 0,
        display: "none",
      },
  },
}));

export const ContactDetail = ({
  updateContact = () => false,
  Contact = [],
  readOnly = false,
  multiContact = true,
}) => {
  const [state, setState] = React.useState("");
  const [error, setError] = React.useState({
    error: false,
    msg: "",
  });
  // const priorityData = useSelector(
  //     state => state?.organizationSlice?.["0"]
  // )

  const orgContactDetailUseData = useSelector(
    (state) => state?.organizationSlice?.orgContactDetailUse
  );
  const orgContactDetailMode = useSelector(
    (state) => state?.organizationSlice?.orgContactDetailMode
  );
  const addNewContact = () => {
    updateContact("Contact", [
      ...Contact,
      {
        mode: {},
        number: "",
        use: {},
        priority: {},
        error: "false",
        msg: "",
      },
    ]);
  };
  const emailHandler = (value, index, data) => {
    let validation = ValidateEmail(value) ? true : false;
    const erroMsg = copy(data);
    if (!validation && value.length > 0) {
      erroMsg[index]["error"] = "true";
      erroMsg[index]["msg"] = "Enter Valid Email";
      updateContact("Contact", erroMsg);
    } else {
      erroMsg[index]["error"] = "false";
      erroMsg[index]["msg"] = "_";
      updateContact("Contact", erroMsg);
    }
  };
  const urlHandler = (value, index, data) => {
    var expression =
      /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi;
    var regex = new RegExp(expression);
    const erroMsg = copy(data);
    if (!value.match(regex) && value !== "") {
      erroMsg[index]["error"] = "true";
      erroMsg[index]["msg"] = "Enter Valid URL";
      updateContact("Contact", erroMsg);
    } else {
      erroMsg[index]["error"] = "false";
      erroMsg[index]["msg"] = "_";
      updateContact("Contact", erroMsg);
    }
  };

  const updateParentState = (key, value, index, label) => {
    if (key === "mode") {
      setError({
        error: false,
        msg: "",
      });
    }
    const modifiedContact = JSON.parse(JSON.stringify(Contact));
    modifiedContact[index][key] = value;
    updateContact("Contact", modifiedContact);
    setState(value);
    if (label === "email") {
      emailHandler(value, index, modifiedContact);
    }

    if (label === "url") {
      urlHandler(value, index, modifiedContact);
    }
  };

  const onDeleteClicked = (index) => {
    let Updatelist = Contact?.filter((item, i) => i !== index);
    updateContact("Contact", Updatelist);
  };

  const classes = useStyles();
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div style={{ display: "flex" }}>
          <Typography variant="body1">CONTACT DETAILS</Typography>
          <Typography
            variant="caption"
            style={{ color: "red", marginLeft: "2px" }}
          >
            *
          </Typography>
        </div>

        {!readOnly && multiContact && (
          <Button
            id={"AddnewContact"}
            variant="text"
            color="primary"
            onClick={() => addNewContact()}
          >
            +Add New Contact
          </Button>
        )}
      </div>
      {Contact.map((data, index) => (
        <Grid
          container
          key={index}
          direction="row"
          display="flex"
          alignItems="center"
        >
          <Grid item xs={12} sm={6} lg={3} className={classes.gridItem}>
            <Select
              id="mobileno"
              className="basic-single"
              classNamePrefix="select"
              label={"Contact Mode"}
              value={[data.mode]}
              placeholder="Mode"
              option={orgContactDetailMode?.data}
              handleselect={(data) => updateParentState("mode", data, index)}
              isReadonly={readOnly}
            />
          </Grid>
          {data.mode && (
            <Grid item xs={12} sm={6} lg={4} className={classes.gridItem}>
              {
                // data?.mode?.label === "Phone" ? <>
                //     <Mobile
                //         // id={data?.id ?? index}
                //         value={data?.number}
                //         label={"Number"}
                //         handleChange={(value) => updateParentState('number', value, index)}
                //         type={"number"}
                //         isReadonly={readOnly}

                //     />
                // </> :
                <>
                  <Typography gutterBottom variant="body1">
                    {data?.mode?.label ? data?.mode?.label : "Given"}
                  </Typography>
                  <TextField
                    // id="LastName"
                    variant="outlined"
                    size="small"
                    placeholder={
                      data?.mode?.label ? data?.mode?.label : "Given"
                    }
                    fullWidth
                    margin="none"
                    value={data?.number || ""}
                    error={data.error === "true" ? true : false}
                    helperText={data.msg === "_" ? "" : data.msg}
                    onChange={(e) => {
                      if (
                        data?.mode?.label?.toLowerCase() === "phone" ||
                        data?.mode?.label?.toLowerCase() === "sms" ||
                        data?.mode?.label?.toLowerCase() === "fax"
                      ) {
                        let re;
                        if (data?.mode?.label?.toLowerCase() === "fax") {
                          re = /^[0-9\s\-]+$/;
                        } else {
                          re = /^[0-9\b]+$/;
                        }

                        if (e.target.value === "" || re.test(e.target.value)) {
                          updateParentState(
                            "number",
                            e.target.value,
                            index,
                            data?.mode?.label?.toLowerCase()
                          );
                        }
                      } else {
                        updateParentState(
                          "number",
                          e.target.value,
                          index,
                          data?.mode?.label?.toLowerCase()
                        );
                      }
                    }}
                    disabled={readOnly}
                  />
                </>
              }
            </Grid>
          )}
          {data.mode && (
            <Grid item xs={12} sm={6} lg={2} className={classes.gridItem}>
              <Select
                id="use"
                className="basic-single"
                classNamePrefix="select"
                label={"Use"}
                placeholder="Work"
                option={orgContactDetailUseData?.data}
                handleselect={(data) => updateParentState("use", data, index)}
                value={[data.use]}
                isReadonly={readOnly}
              />
            </Grid>
          )}
          {!readOnly && index !== 0 && (
            <Grid
              item
              xs={12}
              sm={6}
              lg={1}
              gutterBottom
              className={classes.gridItem}
            >
              {/* <Typography style={{opacity: "0"}} variant='body1'>C</Typography> */}
              <IconButton
                style={{ marginTop: "22px" }}
                id={"deleteBtn"}
                onClick={() => onDeleteClicked(index)}
              >
                <DeleteOutlineOutlinedIcon color="error" />
              </IconButton>
            </Grid>
          )}
        </Grid>
      ))}
    </div>
  );
};
