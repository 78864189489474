import { checkWithIdm } from "atp-casbin-js";
import React from "react";

export const useCheckWithIDM = (repositry) => {
  //   const { repositry } = props;
  const [hasAccess, setHasAccess] = React.useState(false);

  React.useEffect(() => {
    const checkIDM = async (repname) => {
      let permissionData = atob(localStorage.getItem("permissionData"));
      let roleId = localStorage.getItem("RoleId");
      let listofPermissions = JSON.parse(permissionData);

      let value = await checkWithIdm(
        listofPermissions,
        roleId,
        repname,
        "read"
      );
      console.log(value);
      setHasAccess(value);
    };
    checkIDM(repositry);
  }, [repositry]);

  return hasAccess;
};
