import {
  Box,
  Grid,
  InputBase,
  makeStyles,
  MenuItem,
  Paper,
  styled,
  TextField,
  Typography,
} from "@material-ui/core";
import React from "react";
import Select from "../../../../components/select";
import { AddAddress } from "./addEditAddress";
import { ContactDetail } from "./contactDetails";
const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
  },
  gridItem: {
    padding: theme.spacing(2),
    "& input[type=number]::-webkit-inner-spin-button,input[type=number]::-webkit-outer-spin-button":
      {
        margin: 0,
        display: "none",
      },
  },
  textResponsive: {
    fontWeight: "400",
    fontSize: "1rem",
    flex: "1 1 auto",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.8rem !important",
    },
  },
  heading: {
    margin: "8px",
  },
}));

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: "inherit",
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}));

export const ClientDetails = (props) => {
  const classes = useStyles();
  const { state, updateState = () => false, isEdit } = props;

  return (
    <Paper
      style={{
        width: "100%",
        padding: "10px",
        background: "#FFFFFF 0% 0% no-repeat padding-box",
        "box-shadow": "0px 2px 4px #0000000A",
        "border-radius": "8px",
        opacity: 1,
      }}
      elevation={2}
    >
      <Grid
        container
        direction="row"
        display="flex"
        alignItems="center"
        spacing={2}
      >
        <Grid item xs={12} sm={6} lg={4} className={classes.gridItem}>
          <div style={{ display: "flex" }}>
            <Typography variant="subtitle2" gutterBottom color="textSecondary">
              {"Client Name"}
              <span style={{ color: "red", marginLeft: "2px" }}>*</span>
            </Typography>
          </div>

          <TextField
            id="selectQType"
            variant="outlined"
            size="small"
            name="ClientName"
            placeholder="Client Name"
            fullWidth
            margin="none"
            onChange={(e) => updateState("client_name", e.target.value)}
            value={state?.client_name ?? ""}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={4} className={classes.gridItem}>
          <Typography variant="subtitle2" gutterBottom color="textSecondary">
            {"Client Type"}
            <span style={{ color: "red", marginLeft: "2px" }}>*</span>
          </Typography>

          <Select
            id="selectCountry"
            className="basic-single"
            classNamePrefix="select"
            value={[state?.client_type] ?? ""}
            placeholder="Select Type"
            handleselect={(data) => updateState("client_type", data)}
            option={
              [
                //   { value: "Central Government", label: "Central Government" },
                //   { value: "State Government", label: "State Government" },
              ]
            }
          />
        </Grid>
      </Grid>
      {/* <Box className={classes.heading}>
        <Typography
          variant="body1"
          gutterBottom
          className={classes.textResponsive}
        >
          Contact Details
        </Typography>
      </Box> */}
      <ContactDetail state={state} updateState={updateState} isEdit={isEdit} />
      {/* <Box className={classes.heading}>
        <Typography variant="body1" className={classes.textResponsive}>
          Address
        </Typography>
      </Box> */}
      <AddAddress state={state} updateState={updateState} isEdit={isEdit} />
    </Paper>
  );
};
