import * as React from "react";
import PropTypes from "prop-types";

const StackIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={6.957} {...props}>
    <path
      d="M.6 0a.584.584 0 0 0-.6.567V6.39a.584.584 0 0 0 .6.567h14.8a.584.584 0 0 0 .6-.567V.567a.6.6 0 0 0-1.2 0v4.257H1.2V.567A.584.584 0 0 0 .6 0Zm3.1 2.27a.568.568 0 1 0 0 1.133h7.789a.568.568 0 1 0 0-1.133Z"
      fill={props.color}
    />
  </svg>
);

export default StackIcon;

StackIcon.defaultProps = {
  color: "#000",
};

StackIcon.propTypes = {
  color: PropTypes.string,
};
