import React, { Fragment, useEffect, useState } from "react";
import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import { makeStyles, Typography } from "@material-ui/core";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  datePic: {
    position: "relative",
    "& .MuiTextField-root": {
      width: "100%",
      zIndex: 2,
      "& .MuiOutlinedInput-input": {
        paddingTop: 10.5,
        paddingBottom: 10.5,
      },
    },
    "& .MuiSvgIcon-root": {
      position: "absolute",
      right: 14,
      top: "50%",
      transform: "translateY(-50%)",
      width: 20,
      height: 20,
    },
  },
  errorText: {
    color: "red",
    fontSize: "0.75rem",
  },
}));
const DateTimePickers = (props) => {
  const classes = useStyles(props);

  const [selectedDateTime, setSelectedDateTime] = useState(
    props?.value ? props?.value : null
  );

  useEffect(() => {
    setSelectedDateTime(props?.value ? props?.value : null);
  }, [props?.value]);

  const getLabel = (props) => {
    return (
      <Typography variant="body1" style={{ fontSize: 16 }} gutterBottom>
        {props.label}{" "}
        {props.isrequired && (
          <Typography variant="caption" style={{ color: "red" }}>
            *
          </Typography>
        )}
      </Typography>
    );
  };

  const handleDateChange = (val) => {
    setSelectedDateTime(new Date(val));
    props?.onChange && props.onChange(new Date(val));
  };

  return (
    <div className={classes.root}>
      {getLabel(props)}
      <div className={classes.datePic}>
        <Fragment>
          <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
            <DateTimePicker
              id={props?.id ?? ""}
              inputVariant="outlined"
              value={selectedDateTime}
              onChange={(val) => handleDateChange(val)}
              autoOk={true}
              format={props?.dateTimeFormat}
              readOnly={props?.isReadonly}
              disabled={props?.disabled}
              invalidDateMessage=""
            />
          </MuiPickersUtilsProvider>
        </Fragment>
        <svg
          class="MuiSvgIcon-root"
          viewBox="0 0 24 24"
          aria-hidden="true"
          role="presentation"
        >
          <path d="M17 12h-5v5h5v-5zM16 1v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2h-1V1h-2zm3 18H5V8h14v11z"></path>
          <path fill="none" d="M0 0h24v24H0z"></path>
        </svg>
      </div>
      {props?.errorValidation && props?.errorValidation?.error && (
        <div>
          <Typography variant="caption" className={classes.errorText}>
            {props?.errorValidation?.errorMessage}
          </Typography>
        </div>
      )}
    </div>
  );
};

DateTimePickers.propTypes = {
  // defaultValue: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.string,
  onChange: PropTypes.func,
  isReadonly: PropTypes.bool,
  disabled: PropTypes.bool,
};

DateTimePickers.defaultProps = {
  dateTimeFormat: "DD MM YYYY hh:mm",
};

export default DateTimePickers;
