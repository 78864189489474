import React, { useEffect, useState } from "react";
import { Layout } from "atp-master-layout";
import { NavBarArr } from "../../utils/constants";
import { makeStyles } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../../contexts";
import Config from "../../config";
import { checkWithIdm } from "atp-casbin-js";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "100%",
  },
  content: {
    width: "100%",
    height: "100%",
  },
}));

const Navbar = (props) => {
  const { Component } = props;
  let history = useHistory();
  const classes = useStyles();

  const auth = React.useContext(AuthContext);
  const [userInfo, setUserInfo] = useState("");
  const [navlist, setNavlist] = useState([]);

  useEffect(() => {
    const token = localStorage.getItem("access_token");
    if (token) {
      GetUserName();
      GetRepoList();
    } else {
      history.push("/");
      localStorage.clear();
    }
    GetUserName();
    GetRepoList();
  }, []);

  const GetUserName = () => {
    const token = localStorage.getItem("access_token");
    const parsedToken = token?.split(".")[1];
    const userInfos = JSON.parse(window.atob(parsedToken));
    setUserInfo(userInfos);
    auth.setAuth(userInfo);
  };

  const GetRepoList = async () => {
    let roleid = localStorage.getItem("RoleId");
    let roledata = atob(localStorage.getItem("permissionData"));
    let _roledata = JSON.parse(roledata);
    let repository = _roledata.find(
      (l) => l?.permissionRepo?.route_url === window.location.pathname
    );
    let hasAccessCheck = await checkWithIdm(
      _roledata,
      roleid,
      repository?.permissionRepo?.rep_name,
      "read"
    );

    if (hasAccessCheck) {
      let NlistCheckRole = NavBarArr.map(async (l) =>
        (await checkWithIdm(_roledata, roleid, l?.repo, "read"))
          ? { ...l, read: true }
          : { ...l, read: false }
      );
      Promise.all(NlistCheckRole).then((values) => {
        const Navbarlist = values.filter((li) => li.read === true);
        setNavlist(Navbarlist);
      });
    } else {
      history.push("/");
      localStorage.clear();
    }
  };
  return (
    <div className={classes.content}>
      <Layout
        projectTitle={Config?.project_name}
        title={userInfo?.name}
        sidebar={true}
        translate={true}
        navbarType="list"
        sidebarType="miniVariant"
        language={["English", "Tamil", "Telugu"]}
        navItems={navlist}
        history={history}
        theme={require("../../themes/default.json")}
        {...props}
      >
        <Component {...props} />
      </Layout>
    </div>
  );
};

export default Navbar;
