import * as React from "react";
import PropTypes from "prop-types";

const ScheduleIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={14} height={14} {...props}>
    <path
      d="M3.833 0a3.833 3.833 0 1 0 3.834 3.833A3.841 3.841 0 0 0 3.833 0Zm0 1A2.833 2.833 0 1 1 1 3.833 2.826 2.826 0 0 1 3.833 1Zm-.008.66a.5.5 0 0 0-.492.507v1.666a.5.5 0 0 0 .147.354l.667.667a.5.5 0 1 0 .707-.707l-.52-.52v-1.46a.5.5 0 0 0-.508-.507ZM7.94 2a4.452 4.452 0 0 1 .313 1h3.58A1.168 1.168 0 0 1 13 4.167v.5H8.253a4.487 4.487 0 0 1-.313 1H13v6.167A1.168 1.168 0 0 1 11.833 13H4.167A1.168 1.168 0 0 1 3 11.833v-3.58a4.487 4.487 0 0 1-1-.313v3.893A2.169 2.169 0 0 0 4.167 14h7.667A2.169 2.169 0 0 0 14 11.833V4.167A2.169 2.169 0 0 0 11.833 2ZM8 7a.833.833 0 1 0 .833.833A.833.833 0 0 0 8 7Zm2.833 0a.833.833 0 1 0 .833.833.833.833 0 0 0-.833-.833Zm-5.666 3a.833.833 0 1 0 .833.833.833.833 0 0 0-.833-.833ZM8 10a.833.833 0 1 0 .833.833A.833.833 0 0 0 8 10Z"
      fill={props.color}
    />
  </svg>
);

export default ScheduleIcon;

ScheduleIcon.defaultProps = {
  color: "#6a7888",
};

ScheduleIcon.propTypes = {
  color: PropTypes.string,
};
