import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
  TextField,
} from "@material-ui/core";
import React from "react";
import SearchIcon from "@material-ui/icons/Search";

const useStyle = makeStyles((theme) => ({
  SearchContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: (props) => (props.colored ? "8px" : "0px 8px"),
    height: "54px",
  },
  searchbox: {
    background: (props) => (props.colored ? "#F3F4F7" : "#F3F4F7"),
    padding: (props) => (props.variant === "2" ? "4px" : "8px"),
    borderRadius: "8px",
    display: "flex",
    border: (props) => (props.variant === "2" ? "1px solid #dbdbdb" : "unset"),
  },
  searchbox2: {
    background: (props) => (props.colored ? "#F3F4F7" : "#fff"),
    padding: (props) => (props.variant === "2" ? "2px" : "4px"),
    margin: (props) => (props.margin ? "0px 8px" : "0px"),
    borderRadius: "8px",
    display: "flex",
    border: (props) => (props.variant === "2" ? "1px solid #dbdbdb" : "unset"),
    width: (props) => (props.fullWidth ? "100%" : "300px"),
  },
  inputBase: {
    // width: (props) => (props.fullWidth ? "100%" : "300px"),
    "& fieldset": {
      border: "none",
    },
  },
}));

export const SearchTextField = (props) => {
  const {
    variant = "1",
    value,
    onKeyPress = () => false,
    onHandleChange = () => false,
    onSearchClicked = () => false,
    placeholder,
    colored = false,
    padding = false,
    forPhone,
    margin,
    fullWidth,
    disable = false,
  } = props;
  const classes = useStyle({
    colored,
    padding,
    variant,
    forPhone,
    margin,
    fullWidth,
  });
  return (
    <>
      {variant === "1" && (
        <Grid container className={classes.SearchContainer}>
          <Grid className={classes.searchbox} item xs={12} sm={12} lg={8}>
            <TextField
              id="outlined-basic"
              variant="outlined"
              fullWidth
              autoComplete="off"
              size="small"
              name="states"
              onChange={(e) => onHandleChange(e)}
              placeholder={placeholder}
              className={classes.inputBase}
              value={value}
              autoFocus
              onKeyPress={(e) => onKeyPress(e)}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    style={{ position: "relative", left: "12px" }}
                  >
                    <Button
                      size="small"
                      variant="contained"
                      color="primary"
                      auto="true"
                      onClick={() => onSearchClicked()}
                    >
                      Search
                    </Button>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      )}
      {variant === "2" && (
        <Box className={classes.searchbox2}>
          <TextField
            id="outlined-basic"
            variant="outlined"
            fullWidth
            autoComplete="off"
            size="small"
            name="states"
            onChange={(e) => onHandleChange(e)}
            placeholder={placeholder}
            className={classes.inputBase}
            //   value={value}
            //   disabled={disable}
            autoFocus
            //   onKeyPress={(e) => onKeyPress(e)}
            InputProps={{
              startAdornment: (
                <InputAdornment
                  position="start"
                  // style={{ position: "relative", left: "12px" }}
                >
                  <Divider
                    orientation="vertical"
                    style={{ background: "#000" }}
                  />
                  <SearchIcon fontSize="small" />
                  {/* <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    auto="true"
                    onClick={() => onSearchClicked()}
                  >
                    Search
                  </Button> */}
                </InputAdornment>
              ),
            }}
          />
        </Box>
      )}
    </>
  );
};
