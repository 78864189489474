import React, { useEffect, useContext, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Divider,
  FormControlLabel,
  Checkbox,
  Typography,
  Fab,
  Button,
  Tooltip,
  IconButton,
  TextField,
} from "@material-ui/core";
import { FormatShapes } from "@material-ui/icons";

//store
import { actions } from "surveillance-binder";
import { useDispatch, useSelector } from "react-redux";
import VisibilityIcon from "@material-ui/icons/Visibility";
import AddIcon from "@material-ui/icons/Add";
import { Payment } from "@material-ui/icons";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import Skeleton from "@material-ui/lab/Skeleton";
import SkipComponent from "./skipComponent";
import FormLayer from "./formComponentLayer";
import SectionProperties from "./sectionProperties";

import { ArchivedDialog } from "../../components";
import { ComponentToRender } from "../formRenderer/componentToRender";
import { DrawerComponent } from "../../componentsV2";

// import { useRouteMatch } from "react-router";

import { AlertProps, ComponentType, Message } from "../../utils";
import { AlertContext } from "../../contexts";
import copy from "fast-copy";

const {
  GET_SECTION_COMPONENTS,
  UPSERT_QUESTION,
  GET_FORM_TREE,
  UPSERT_FORM_TEMPLATE,
} = actions;

const useStyles = makeStyles((theme) => ({
  root: {
    height: "calc(100vh - 130px)",
    flexGrow: 1,
    background: theme?.palette?.background?.paper,
    borderTopRightRadius: theme?.spacing(1),
    borderBottomRightRadius: theme?.spacing(1),
    borderLeft: `1px solid ${theme?.palette?.grey[200]}`,
    overflow: "auto",
  },
  titleHeader: {
    padding: 11,
  },
  formFields: {
    paddingBottom: "20px",
    // background: theme.palette.background.default
    background: "rgb(245, 245, 245)",
  },
  inputFiled: {
    marginBottom: "16px",
  },
  selectComponentList: {
    marginTop: 0,
    color: "red",
  },
  addFloatingButton: {
    position: "absolute",
    right: "26px",
    bottom: "30px",
  },
  previewFloatingButton: {
    position: "absolute",
    right: "34px",
    bottom: "104px",
    width: "44px",
    height: "44px",
  },
  previewFloatingButton1: {
    position: "absolute",
    right: "26px",
    bottom: "30px",
  },
  saveButton: {
    textTransform: "capitalize",
  },
  sectionTitle: {
    paddingLeft: 10,
  },
  notFoundSectionGrid: {
    height: "inherit",
  },
  notFoundSectionGridItem: {
    textAlign: "center",
  },
  paymentIcon: {
    height: 100,
    width: 100,
  },
  titleHeaderParent: {
    position: "sticky",
    top: 0,
    background: theme?.palette?.background?.paper,
    zIndex: 2,
  },
}));

const initalSkipComponent = {
  component: "popup_switch", //smart skip
  label: "",
  required: false,
  options: [],
  is_smart: true,
  priority: 0,
};

const initalData = {
  formError: false,
  loading: true,
  name: "",
  skip: false,
  questions: [],
  delQuestion: [],
};

export default function Form(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const alert = useContext(AlertContext);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const [openTemplate, setOpenTemplate] = React.useState(false);
  const [stateTemplate, setStateTemplate] = React.useState({
    templateName: "",
    error: {
      archivedText: false,
    },
  });


  const ID = window.atob(localStorage.getItem("formId"));

  const [fromData, setFromData] = React.useState(initalData);
  const [skipComponentState, setSkipComponentState] =
    React.useState(initalSkipComponent);
  // const match = useRouteMatch();

  const questionsWithoutIds = fromData?.questions?.map(
    ({ id, ...rest }) => rest
  );

  //show or hide dialog
  const [open, setOpen] = React.useState(false);

  const check = props.saveToMove;

  useEffect(() => {
    if (!props.section_id) {
      return false;
    }

    let params = {
      section_id: props.section_id ?? null,
    };

    initalSkipComponent.required = false;

    setFromData(initalData);
    setSkipComponentState(initalSkipComponent);

    Promise.resolve(dispatch(GET_SECTION_COMPONENTS(params))).then((res) => {
      const data = {
        loading: false,
        name: "",
        skip: false,
        questions: [],
        description: "",
        formLayoutType: "",
      };

      if (res?.payload?.data && res?.payload?.data?.[0]?.section_id) {
        data.name = res?.payload?.data?.[0]?.section_name;
        data.skip = res?.payload?.data?.[0]?.is_skippable;
        data.description = res?.payload?.data?.[0]?.description;
        data.formLayoutType = res?.payload?.data?.[0]?.formLayoutType;

        const childUp = res?.payload?.data?.[0]?.questions.map((val) => {
          let tableScheme = {
            column:
              val?.tableScheme?.column.map((_) => {
                return {
                  component_name: _?.component_name,
                  icon: _?.icon,
                  name: _?.name,
                  ui_component: _?.ui_component,
                  options: _?.options,
                };
              }) ?? [],
            row:
              val?.tableScheme?.row.map((_) => {
                return {
                  name: _?.name,
                  validations: _?.validations ?? {},
                };
              }) ?? [],
            tag: val?.tableScheme?.tag,
            ui_component: val?.tableScheme?.ui_component,
          };

          let child = {
            id: val?.id ?? null,
            label: val?.question ?? "",
            is_smart: val?.is_smart ?? false,
            required: val?.is_required ?? false,
            component: val?.ui_component ?? allComponents?.data?.[0]?.value,
            priority: val?.priority ?? 1,
            options: val?.question_options,
            has_dependency_qn: val?.has_dependency_qn,
            tag: val?.tag,
            dependency: val?.dependencies?.map((_) => _) ?? [],
            tableScheme: tableScheme,
            validation: val?.validation ?? {},
            QueryId:val?.QueryId ?? ""
          };

          data.questions.push(child);

          return val;
        });
        data.delQuestion = [];
        setFromData(data);
        return childUp;
      }
    });
  }, [props.section_id]);

  const { loading } = useSelector(
    (state) =>
      state?.formSlice?.upsertQuestion ?? {
        loading: false,
        data: [],
        error: false,
      }
  );

  const allComponents = useSelector(
    (state) =>
      state?.formconf?.get_components ?? {
        loading: false,
        data: [],
        error: false,
      }
  );

  useEffect(() => {
    if (!fromData?.loading) {
      if (Object?.keys?.(props?.templateFormAddStatus)?.length > 0) {
        setFromData({
          ...fromData,
          questions: copy(props?.templatedata?.questions),
        });
        if (props?.templatedata) {
          props?.setTemplateFormAddStatus(null, null);
        }
        if (props?.templatedata?.questions === undefined || null) {
          setFromData({ ...fromData, questions: fromData?.questions });
        }
      }
    }
    // eslint-disable-next-line
  }, [fromData?.loading]);

  useEffect(() => {
    if (props.save) {
      if (isValidation()) {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: "Please fill the required fields",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
        props.handleClose("error");
      } else {
        const params = {
          section_id: props?.section_id ?? null,
          questions: fromData.questions,
          section_detail_id: props?.section_detail_id ?? null,
          is_skippable: fromData.skip,
          section_name: fromData?.name,
          formLayoutType: fromData?.formLayoutType,
          description: fromData?.description,
          is_metadata: false,
        };

        Promise.resolve(dispatch(UPSERT_QUESTION(params))).then((res) => {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.success,
            msg: "Form updated successfully",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
          });
        });
        props.handleClose();
        props.getSectionComponents();
      }
    }
    // eslint-disable-next-line
  }, [props.save]);

  const changeSkipComponent = (type) => {
    if (!type) {
      skipComponentState.required = !type;
      fromData.questions.unshift(skipComponentState);
      props.checkFormEdit(true);
      return setFromData({ ...fromData });
    }
    if (fromData.questions.some((val) => val.component === "popup_switch")) {
      fromData.questions.map((val, index) => {
        if (val.component === "popup_switch") {
          skipComponentState.required = !type;
          return fromData.questions.splice(index, 1);
        } else {
          return false;
        }
      });
      setFromData({ ...fromData });
    } else {
      skipComponentState.required = !type;
      setSkipComponentState({
        ...skipComponentState,
      });
    }
    props.checkFormEdit(true);
  };

  const ChangeSkipQuestion = (event, index) => {
    if (fromData.questions.some((val) => val.component === "popup_switch")) {
      fromData.questions.map((val) => {
        if (val.component === "popup_switch") {
          return (val.label = event.target.value);
        } else {
          return false;
        }
      });
      setFromData({ ...fromData });
      setSkipComponentState({
        ...skipComponentState,
        label: event.target.value,
      });
    } else {
      setSkipComponentState({
        ...skipComponentState,
        label: event.target.value,
      });
    }
    props.checkFormEdit(true);
  };

  const onChangeComponents = (value, index, state) => {
    fromData.questions[index][state] = value;
    setFromData({ ...fromData });

    props.checkFormEdit(true);
  };

  const showPreview = (val) => {
    setOpen(true);
    if (val === true) {
      if (check === true) props.goPreview(val);
      else props.goPreview(val);
    } else props.goPreview(val);
  };

  const addNewComponent = () => {
    let newComp = {
      tag: "",
      label: "",
      QueryId:"",
      question: "",
      dependency: [],
      required: false,
      options: [],
      component: allComponents?.data?.[0]?.value,
      priority: fromData?.questions?.length + 1,
      tableScheme: {
        column: [
          {
            name: "Column 1",
            // ui_component:'input_text',
          },
        ],
        row: [
          {
            name: "Row 1",
          },
        ],
      },
    };
    fromData.questions.push(newComp);
    setFromData({ ...fromData });
    props.checkFormEdit(true);
  };

  const isValidation = () => {
    let isError = false;
console.log("fromData" , fromData)

    fromData?.questions.map((val) => {
      if (
        val?.label?.length < 2 ||
        val?.component?.length < 2 ||
        (ComponentType(val?.component) && val?.options?.length < 2)
      ) {
        let error = {
          labelError: val.label?.length < 2 ? true : false,
          tagError: val.tag?.length < 2 ? true : false,
          componentError: val?.component ? false : true,
          optionError:
            ComponentType(val?.component) && val.options.length < 2
              ? true
              : false,
        };
        val["error"] = error;
        return (isError = true);
      } 

      else if(val?.component == "select_modal_dropdown_qdm")
      {
        
        let error = {
          queryError:val.QueryId?.length < 2 ? true : false,
        }
        val["error"] = error;
        return (isError = error?.queryError);
      }
      
      else {
        val["error"] = {};
        return "";
      }
    });

    if (isError) {
      setFromData({ ...fromData, formError: true });
      return true;
    } else {
      setFromData({ ...fromData, formError: false });
      return false;
    }
  };

  const saveAndUpdate = async (noshowalert) => {
    if (isValidation()) {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: "Please fill the required fields",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
      });
      return Promise.reject();
    } else {
      const params = {
        form_id: props.id,
        section_id: props?.section_id ?? null,
        section_detail_id: props?.section_detail_id ?? null,
        is_skippable: fromData.skip,
        questions: fromData.questions,
        delQuestions: fromData.delQuestion,
        section_name: fromData?.name,
        formLayoutType: fromData?.formLayoutType,
        description: fromData?.description,
        is_metadata: false,
      };

      console.log("params" , params)
      await Promise.resolve(dispatch(UPSERT_QUESTION(params))).then((res) => {
        let resStatus = res.payload.error;
        if (!noshowalert) {
          alert.setSnack({
            ...alert,
            open: true,
            severity: resStatus
              ? AlertProps.severity.error
              : AlertProps.severity.success,
            msg: resStatus
              ? "Form update Failed !"
              : "Form updated Successfully !",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
          });
        }

        const params = {
          form_id: props.id,
        };

        Promise.resolve(dispatch(GET_FORM_TREE(params)));

        props.getSectionComponents();

        props.checkFormEdit(false, true);
        props.setRunUse(!props.runUse);
        // window.location.reload();
      });
    } 
  };

  const addDeleteComponents = (value, index, isAdd = true, removeIndex) => {
    if (isAdd) {
      fromData.questions?.[index]?.dependency?.push({
        parentQuestion: { ...value, dependency: [] },
        option: [],
        type: {},
        target: {},
      });
    } else {
      fromData.questions?.[index]?.dependency?.splice(removeIndex, 1);
    }

    setFromData({ ...fromData });

    props.checkFormEdit(true);
  };

  const updateDependency = (key, value, index, parentIndex) => {
    const newData = copy(fromData);
    newData.questions[parentIndex].dependency[index][key] = value;

    setFromData({ ...newData });

    props.checkFormEdit(true);
  };

  const delectQuestion = (index) => {
    fromData.delQuestion.push(fromData.questions[index]);
    fromData.questions.splice(index, 1);
    setFromData({ ...fromData });
    props.checkFormEdit(true);
  };

  const changeFormSkip = () => {
    fromData.skip = !fromData.skip;
    setFromData({ ...fromData });
    props.checkFormEdit(true);
  };

  const confirmDialog = () => {
    setOpen(false);
  };
  const toggleDrawer = (val) => {
    setDrawerOpen(val);
  };

  const updateData = (value, key) => {
    setFromData({ ...fromData, [key]: value });
  };

  const saveSectionProperties = () => {
    saveAndUpdate();
    toggleDrawer(false);
  };

  //for templates
  const saveAsTemplate = () => {
    setOpenTemplate(true);
  };

  //dialog close
  const handleCloseTemplate = () => {
    setOpenTemplate(false);
    setStateTemplate({ ...stateTemplate, templateName: "" });
  };

  const onChangeInput = (e) => {
    setStateTemplate({ ...stateTemplate, templateName: e.target.value });
  };

  //clicking cancel button on dialog
  const cancelUpdate = () => {
    stateTemplate.error = {
      archivedText: false,
    };
    setStateTemplate({ ...stateTemplate });

    handleCloseTemplate();

    alert.setSnack({
      ...alert,
      open: true,
      severity: AlertProps.severity.success,
      msg: "Save template cancelled !",
      vertical: AlertProps.vertical.top,
      horizontal: AlertProps.horizontal.center,
    });
  };

  //clicking confirm button on dialog
  const confirmUpdate = () => {
    if (stateTemplate.templateName.length === 0) {
      stateTemplate.error = {
        archivedText: stateTemplate?.templateName?.length === 0 ? true : false,
      };
      setStateTemplate({ ...stateTemplate });
    } else {
      handleCloseTemplate();

      const params = {
        formTemplateId: "",
        templateName: stateTemplate?.templateName,
        Questions: questionsWithoutIds,
        isActive: true,
        is_metadata: false,
      };

      Promise.resolve(dispatch(UPSERT_FORM_TEMPLATE(params))).then((res) => {
        let resStatus = res.payload.error;
        let statusMessage = "Template Form Saved Successfully !";
        let statusMessage2 = "Template Form Saving Unsuccessfully !";

        alert.setSnack({
          ...alert,
          open: true,
          severity: resStatus
            ? AlertProps.severity.error
            : AlertProps.severity.success,
          msg: resStatus ? statusMessage2 : statusMessage,
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });

        props?.getTemplateFormsFunction();
      });
    }
  };

  return (
    <div className={classes.root}>
      {props.section_id ? (
        <div>
          <Grid
            className={classes.titleHeaderParent}
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Grid item className={classes.titleHeader}>
              <Typography variant={"body1"}>{"Form Builder "}</Typography>
            </Grid>
            {!(fromData?.loading && fromData?.questions?.length === 0) && (
              <Grid item>
                {fromData?.questions?.length > 0 && (
                  <Button
                    color="primary"
                    disabled={loading}
                    onClick={() => saveAsTemplate()}
                    className={classes.saveButton}
                  >
                    {"Save As Template"}
                  </Button>
                )}

                <Button
                  color="primary"
                  disabled={loading}
                  onClick={() => saveAndUpdate()}
                  className={classes.saveButton}
                >
                  {loading ? "Saving..." : "Save"}
                </Button>
              </Grid>
            )}
          </Grid>
          <Divider />

          {/* Header of form */}
          {!fromData?.loading && (
            <Grid
              className={classes.sectionTitle}
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <Grid item>
                <Typography variant={"body1"}>{fromData?.name}</Typography>
              </Grid>

              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={fromData?.skip ?? false}
                      onChange={changeFormSkip}
                      name="sectionSkip"
                    />
                  }
                  label="Skip"
                />
                <IconButton onClick={() => toggleDrawer(true)}>
                  <SettingsOutlinedIcon />
                </IconButton>
              </Grid>
            </Grid>
          )}
          {/* Header of form END */}
          <Divider />

          <Grid className={classes.formFields}>
            {fromData?.loading && fromData?.questions?.length === 0 ? (
              <>
                <Grid style={{ padding: "20px" }}>
                  <Skeleton variant="text" width={"100%"} height={20} />
                  <Skeleton variant="text" width={"100%"} height={48} />
                </Grid>
                <Divider />
                <Grid style={{ padding: "20px" }}>
                  <Skeleton variant="text" width={"100%"} height={20} />
                  <Skeleton variant="text" width={"100%"} height={48} />
                </Grid>
              </>
            ) : (
              <>
                {/* check form is skip */}
                {fromData?.questions?.filter(
                  (val) => val.component === "popup_switch"
                )[0] ? (
                  <SkipComponent
                    // indexOfno={1}
                    changeSkipComponent={changeSkipComponent}
                    ChangeSkipQuestion={ChangeSkipQuestion}
                    data={
                      fromData?.questions?.filter(
                        (val) => val.component === "popup_switch"
                      )[0]
                    }
                  />
                ) : (
                  <SkipComponent
                    changeSkipComponent={changeSkipComponent}
                    ChangeSkipQuestion={ChangeSkipQuestion}
                    data={skipComponentState}
                  />
                )}
                {/* check form is skip END */}
                <Divider />

                {/* Form layer */}
                <FormLayer
                  indexOfno={
                    fromData?.questions?.filter(
                      (val) => val.component === "popup_switch"
                    )[0]
                      ? 1
                      : 0
                  }
                  fromData={fromData?.questions ?? []}
                  onChangeComponents={onChangeComponents}
                  parentSection={props.parentSection}
                  delectQuestion={delectQuestion}
                  selectedSection={props.selectedSection}
                  getSectionComponents={saveAndUpdate}
                  updateDependency={updateDependency}
                  addDeleteComponents={addDeleteComponents}
                />
                {/* Form layer End */}

                {/* add floating button */}
                <Tooltip title="Preview Form" placement="left">
                  <Fab
                    color="primary"
                    aria-label="preview"
                    className={classes.previewFloatingButton}
                    onClick={() => showPreview(true)} //showPreview(true)
                  >
                    <VisibilityIcon />
                  </Fab>
                </Tooltip>
                <Tooltip title="Add New Question" placement="left">
                  <Fab
                    color="primary"
                    aria-label="add"
                    className={classes.addFloatingButton}
                    onClick={() => addNewComponent()} //addNewComponent()
                  >
                    <AddIcon />
                  </Fab>
                </Tooltip>
              </>
            )}
          </Grid>
        </div>
      ) : (
        <Grid
          className={classes.notFoundSectionGrid}
          container
          direction="row"
          justify="center"
          alignItems="center"
        >
          <Grid item className={classes.notFoundSectionGridItem}>
            <Payment color="disabled" className={classes.paymentIcon} />
            <Typography color="textSecondary">
              {"Select a Form to use the Form Builder"}
            </Typography>
          </Grid>
        </Grid>
      )}
      {/* form dialog */}

      <ArchivedDialog
        open={open}
        height
        dialogTitle={props.formTitle}
        dialogContent={
          fromData.questions?.length > 0 &&
          fromData.questions?.map((_, i) => {
            return (
              <div style={{ marginTop: 20 }}>
                <ComponentToRender
                  data={{
                    id: _.id,
                    priority: _.priority,
                    ui_component: _.component,
                    is_required: _.required,
                    question: _.label,
                    question_options: _.options,
                    tag: _.tag,
                    QueryId:_.QueryId
                  }}
                  isReadyOnly={true}
                  // value={
                  //   formsWithAnswer?.[currentForm?.framed_questions?.[0]?.id]
                  //     ?.answer ?? ''
                  // }
                  index={i}
                />
              </div>
            );
          })
        }
        negativeButton
        negativeButtonText="Cancel"
        negativeButtonAction={confirmDialog}
        //positiveButton
        /* positiveButtonText="Ok"
        positiveButtonAction={confirmDialog} */
      />

      {/* for saveAsTemplate */}

      {
        <ArchivedDialog
          open={openTemplate}
          dialogTitle={"Would you like to Save this Form as template ?"}
          dialogContent={
            <TextField
              autoFocus
              onChange={(e) => onChangeInput(e)}
              value={stateTemplate.templateName}
              fullWidth
              name="Would you like to Save this Form as template"
              label="Enter the Template Name"
              margin="dense"
              error={stateTemplate.error.archivedText}
              helperText={
                stateTemplate.error.archivedText === true
                  ? Message.requiredMessage
                  : ""
              }
            />
          }
          negativeButton
          negativeButtonText="Cancel"
          negativeButtonAction={cancelUpdate}
          positiveButton
          positiveButtonText={"Confirm"}
          positiveButtonAction={confirmUpdate}
        />
      }

      {drawerOpen && (
        <DrawerComponent
          open={drawerOpen}
          forQuestion={false}
          onClose={() => toggleDrawer(false)}
        >
          <SectionProperties
            data={fromData}
            setData={updateData}
            saveAndUpdate={saveSectionProperties}
            close={toggleDrawer}
          />
        </DrawerComponent>
      )}
    </div>
  );
}
