import React from "react";
import {
  makeStyles,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  DialogTitle,
  Divider,
  IconButton,
} from "@material-ui/core";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  root: {},
  dialog: (props) => ({ minWidth: props?.width ? props?.width : 600 }),
  heightDialog: (props) => ({
    minWidth: props?.width ? props?.width : 600,
    minHeight: 700,
    overflowY: "auto",
    maxHeight: 700,
  }),
}));

export const ArchivedDialog = (props) => {
  const classes = useStyles(props);

  const [open, setOpen] = React.useState(props?.open);

  React.useEffect(() => {
    setOpen(props?.open);
  }, [props?.open]);

  const handleClose = () => {
    setOpen(false);
    if (props?.negativeButton) {
      props?.negativeButtonAction();
    }
  };

  return (
    <div className={classes.root}>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        classes={{
          paper: props?.height ? classes.heightDialog : classes.dialog,
        }}
      >
        {!props?.headWithBtn && (
          <DialogTitle id="form-dialog-title">{props?.dialogTitle}</DialogTitle>
        )}
        {props?.headWithBtn && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              <DialogTitle id="form-dialog-title">
                {props?.dialogTitle}
              </DialogTitle>
            </div>
            <div>
              <IconButton onClick={props?.dialogClose}>
                <svg
                  style={{ fill: "black", height: "20px", width: "20px" }}
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                >
                  <path d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z"></path>
                </svg>
              </IconButton>
            </div>
          </div>
        )}
        <Divider />
        <DialogContent>{props?.dialogContent}</DialogContent>
        <DialogActions>
          {props?.negativeButton && (
            <Button
              id="negativeBtn"
              onClick={handleClose}
              variant="contained"
              color="primary"
            >
              {props?.negativeButtonText}
            </Button>
          )}
          {props?.secoundaryButton && (
            <Button
              id="negativeBtn"
              onClick={props?.secoundaryButton}
              color="primary"
            >
              {props?.secoundaryButtonName}
            </Button>
          )}
          {props?.positiveButton && (
            <Button
              id="positiveBtn"
              onClick={props?.positiveButtonAction ?? false}
              color="primary"
            >
              {props?.positiveButtonText}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

ArchivedDialog.propTypes = {
  dialogTitle: PropTypes.string,
  dialogContent: PropTypes.node,
  negativeButton: PropTypes.bool,
  negativeButtonText: PropTypes.string,
  negativeButtonAction: PropTypes.func,
  dialogClose: PropTypes.func,
  positiveButton: PropTypes.bool,
  positiveButtonText: PropTypes.string,
  secoundaryButtonName: PropTypes.string,
  positiveButtonAction: PropTypes.func,
  secoundaryButtonAction: PropTypes.func,
  height: PropTypes.bool,
  headWithBtn: PropTypes.bool,
};

ArchivedDialog.defaultProps = {
  dialogTitle: "Title",
  negativeButtonText: "Cancel",
  positiveButtonText: "Ok",
};
