import * as React from "react";
import PropTypes from "prop-types";

const MasterIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20px"
    height="20px"
    viewBox="0 0 20 20"
    version="1.1"
  >
    <g id="layer1">
      <path d="M 4 1 L 4 6 L 7 6 L 7 1 L 4 1 z M 5 2 L 6 2 L 6 5 L 5 5 L 5 2 z M 0 3 L 0 4 L 3 4 L 3 3 L 0 3 z M 8 3 L 8 4 L 20 4 L 20 3 L 8 3 z M 13 8 L 13 13 L 16 13 L 16 8 L 13 8 z M 14 9 L 15 9 L 15 12 L 14 12 L 14 9 z M 0 10 L 0 11 L 12 11 L 12 10 L 0 10 z M 17 10 L 17 11 L 20 11 L 20 10 L 17 10 z M 4 15 L 4 20 L 7 20 L 7 15 L 4 15 z M 5 16 L 6 16 L 6 19 L 5 19 L 5 16 z M 0 17 L 0 18 L 3 18 L 3 17 L 0 17 z M 8 17 L 8 18 L 20 18 L 20 17 L 8 17 z " />
    </g>
  </svg>
);

export default MasterIcon;

MasterIcon.defaultProps = {
  color: "black",
  secondaryColor: "#3ab012",
};

MasterIcon.propTypes = {
  color: PropTypes.string,
  secondaryColor: PropTypes.string,
};
