import React from "react";
import { Grid, TextField, makeStyles } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
  },
  gridItem: {
    padding: theme.spacing(2),
  },
}));

export const DefaultSection = (props) => {
  const { validationState, onChange } = props;

  const classes = useStyles(props);

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid md={12} sm={12} xs={12}>
          <Autocomplete
            id="tags-outlined"
            size={"small"}
            options={
              props?.data?.options?.map((val) => {
                return { label: val, value: val };
              }) ?? []
            }
            getOptionLabel={(option) => option.label}
            value={validationState?.defaultValue ?? null}
            onChange={(e, newValue) => onChange(newValue, "defaultValue")}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Default Value"
                placeholder="Options"
              />
            )}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default DefaultSection;
