import React from "react";
import {
  Box,
  InputBase,
  styled,
  TextField,
  withStyles,
} from "@material-ui/core";
import {
  Paper,
  Typography,
  Grid,
  makeStyles,
  Button,
  IconButton,
  MenuItem,
} from "@material-ui/core";
import { ValidateEmail } from "../../../../utils";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import { MuiSelect } from "../../../../components/muiSelect/MuiSelect";
import copy from "fast-copy";

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
  },
  gridItem: {
    padding: theme.spacing(2),
    "& input[type=number]::-webkit-inner-spin-button,input[type=number]::-webkit-outer-spin-button":
      {
        margin: 0,
        display: "none",
      },
  },
  hashText: {
    color: "#83859B",
  },
  textResponsive: {
    fontWeight: "600",
    fontSize: "1rem",
    flex: "1 1 auto",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.8rem !important",
    },
  },
  heading: {
    margin: "8px 0px",
  },
}));

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);
export const ContactDetail = (props) => {
  const classes = useStyles(props);
  const {
    state = null,
    updateState = () => false,
    isEdit,
    contactMode,
    Use,
    editData,
  } = props;
  //   const clientstate = React.useContext(ClientData);
  const [Addlist, SetTypeList] = React.useState(
    isEdit ? editData?.contact_details : state?.contact_details ?? []
  );

  const urlHandler = (value, index, data) => {
    var expression =
      /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi;
    var regex = new RegExp(expression);
    const erroMsg = copy(data);
    if (!value.match(regex) && value !== "") {
      erroMsg[index]["error"] = "true";
      erroMsg[index]["msg"] = "Enter Valid URL";
      SetTypeList(erroMsg);
    } else {
      erroMsg[index]["error"] = "false";
      erroMsg[index]["msg"] = "";
      SetTypeList(erroMsg);
    }
  };

  const emailHandler = (value, index, data) => {
    let validation = ValidateEmail(value) ? true : false;
    const erroMsg = copy(data);

    if (!validation && value.length > 0) {
      erroMsg[index]["error"] = "true";
      erroMsg[index]["msg"] = "Enter Valid Email";
      SetTypeList(erroMsg);
    } else {
      erroMsg[index]["error"] = "false";
      erroMsg[index]["msg"] = "";
      SetTypeList(erroMsg);
    }
  };
  const onChange = (key, value, index, label = "") => {
    const listupdate = copy(Addlist);
    const erroMsg = copy(Addlist);
    listupdate[index][key] = value;
    SetTypeList(listupdate);
    if (key === "contact_mode" && (value.length === 0 || value === undefined)) {
      erroMsg[index]["error"] = "false";
      erroMsg[index]["msg"] = "";
      SetTypeList(erroMsg);
    }
    if (label === "email") {
      emailHandler(value, index, listupdate);
    }

    if (label === "url") {
      urlHandler(value, index, listupdate);
    }

    updateState("contact_details", listupdate);
  };

  const addNewContact = () => {
    SetTypeList([
      ...Addlist,
      {
        contact_mode: "",
        contactby: "",
        work: "",
        priority: `${Addlist.length + 1}`,
        error: "false",
        msg: "",
      },
    ]);
    updateState("contact_details", [
      ...Addlist,
      {
        contact_mode: "",
        contactby: "",
        work: "",
        priority: `${Addlist.length + 1}`,
        error: "false",
        msg: "",
      },
    ]);
  };

  const deleteIcon = (id) => {
    let Updatelist = Addlist.filter((item, i) => i !== id);
    SetTypeList(Updatelist);
    updateState("contact_details", Updatelist);
  };

  React.useEffect(() => {
    if (editData) {
      SetTypeList(editData?.contact_details);
    }
  }, [editData]);
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box className={classes.heading}>
          <Typography
            variant="body1"
            gutterBottom
            className={classes.textResponsive}
          >
            Contact Details
            <span style={{ color: "red", marginLeft: "2px" }}>*</span>
          </Typography>
        </Box>

        <Button
          id={"Button1T6"}
          variant="text"
          color="primary"
          onClick={addNewContact}
          // disabled={true}
        >
          + Add New Contact
        </Button>
      </div>
      {Addlist?.map((type, index) => (
        <Grid
          key={index}
          container
          direction="row"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={12} sm={6} lg={3} className={classes.gridItem}>
            <Typography variant="subtitle2" gutterBottom color="textSecondary">
              {"Contact Mode"}
            </Typography>
            {/* <Select
              id="selectCountry"
              classNamePrefix="select"
              // label={"Priority"}
              name="contact_mode"
              fullWidth
              variant="outlined"
              value={type?.contact_mode ?? ""}
              placeholder="Select"
              onChange={(e) => onChange("contact_mode", e, index)}
              input={<BootstrapInput />}
              size="small"
              disabled={type?.priority === 1}
            >
              <MenuItem value={"Email"}>Email</MenuItem>
              <MenuItem value={"Phone"}>Phone</MenuItem>
            </Select> */}
            <MuiSelect
              id={`selectContactmode_${index}`}
              className="basic-single"
              classNamePrefix="select"
              value={contactMode?.data?.find(
                (l) => l.coding._id === type?.contact_mode
              )}
              placeholder="Select"
              name="contact_mode"
              getOptionLabel={(option) => option?.coding?.display ?? {}}
              handleselect={(data) =>
                onChange("contact_mode", data?.coding?._id ?? "", index)
              }
              // label={"coding"}
              option={contactMode?.data ?? []}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4} className={classes.gridItem}>
            <Typography variant="subtitle2" gutterBottom color="textSecondary">
              {contactMode?.data
                ?.find((l) => l.coding._id === type?.contact_mode)
                ?.coding?.display.toUpperCase() ?? "Mode"}
            </Typography>
            <TextField
              id="selectQType"
              variant="outlined"
              size="small"
              placeholder={
                contactMode?.data
                  ?.find((l) => l.coding._id === type?.contact_mode)
                  ?.coding?.display.toUpperCase() ?? "MODE"
              }
              fullWidth
              error={type?.error === "true" ? true : false}
              helperText={type?.msg}
              margin="none"
              onChange={(e) => {
                if (
                  contactMode?.data
                    ?.find((l) => l.coding._id === type?.contact_mode)
                    ?.coding?.display.toLowerCase() === "phone" ||
                  contactMode?.data
                    ?.find((l) => l.coding._id === type?.contact_mode)
                    ?.coding?.display.toLowerCase() === "sms" ||
                  contactMode?.data
                    ?.find((l) => l.coding._id === type?.contact_mode)
                    ?.coding?.display.toLowerCase() === "fax"
                ) {
                  let re;
                  if (
                    contactMode?.data
                      ?.find((l) => l.coding._id === type?.contact_mode)
                      ?.coding?.display.toLowerCase() === "fax"
                  ) {
                    re = /^[0-9-]+$/;
                  } else {
                    re = /^[0-9\b]+$/;
                  }

                  if (e.target.value === "" || re.test(e.target.value)) {
                    onChange(
                      "contactby",
                      e.target.value,
                      index,
                      contactMode?.data
                        ?.find((l) => l.coding._id === type?.contact_mode)
                        ?.coding?.display.toLowerCase() ?? "mode"
                    );
                  }
                } else {
                  onChange(
                    "contactby",
                    e.target.value,
                    index,
                    contactMode?.data
                      ?.find((l) => l.coding._id === type?.contact_mode)
                      ?.coding?.display.toLowerCase() ?? "mode"
                  );
                }
              }}
              name="contactby"
              value={type?.contactby === "_error_" ? "" : type?.contactby ?? ""}
              // disabled={isEdit}
            />
          </Grid>

          <Grid item xs={12} sm={4} lg={2} className={classes.gridItem}>
            <Typography variant="subtitle2" gutterBottom color="textSecondary">
              {"Work"}
            </Typography>
            {/* <Select
              id="selectCountry"
              classNamePrefix="select"
              name="work"
              fullWidth
              variant="outlined"
              // value={type?.work ?? ""}
              value={
                Use?.data?.find((l) => l.coding._id === type?.work)?._id ?? ""
              }
              placeholder="Select"
              onChange={(e) => onChange(e, index)}
              size="small"
              input={<BootstrapInput />}
            >
              {Use?.data?.map((l) => (
                <MenuItem value={l?.coding?._id}>{l?.coding?.display}</MenuItem>
              ))}
            </Select> */}
            <MuiSelect
              id={`selectUse${index}`}
              className="basic-single"
              classNamePrefix="select"
              value={Use?.data?.find((l) => l.coding._id === type?.work)}
              placeholder="Select"
              getOptionLabel={(option) => option?.coding?.display ?? {}}
              handleselect={(data) =>
                onChange("work", data?.coding?._id ?? "", index)
              }
              // label={"coding"}
              option={Use?.data ?? []}
            />
          </Grid>
          <Grid item xs={12} sm={4} lg={2} className={classes.gridItem}>
            <Typography variant="subtitle2" gutterBottom color="textSecondary">
              {"Priority"}
            </Typography>
            <TextField
              id="selectQType"
              variant="outlined"
              size="small"
              // placeholder={type?.Priority}
              fullWidth
              margin="none"
              // onChange={(e) => onChange("email", e, index)}
              // name="email"
              value={type?.priority}
              disabled={true}
            />
          </Grid>

          {type?.priority !== "1" && (
            <Grid item xs={12} sm={1} lg={1}>
              <IconButton
                id={"deleteBtn"}
                style={{ marginTop: "22px" }}
                onClick={() => deleteIcon(index)}
                disabled={type?.priority === "1"}
              >
                <DeleteOutlineOutlinedIcon color="error" />
              </IconButton>
            </Grid>
          )}
        </Grid>
      ))}
    </>
  );
};
