import { Typography } from "@material-ui/core";
import {
  Button,
  LinearProgress,
  Divider,
  Grid,
  makeStyles,
  TextField,
} from "@material-ui/core";
import React, { useContext, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { TabSelect, Mobile, DOB, LocationLevel } from "../../components";
import { PatientDetailCard } from "../../components/patientDetailCard";
import { AlertProps, genderData, personalTitles } from "../../utils/constants";
import { ComponentToRender } from "./componentToRender";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import { AlertContext } from "../../contexts";
// import { actions } from "binder-engine";
import { ValidateEmail } from "../../utils";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: 16,
    borderBottom: `1px solid ${theme.palette.divider}`,
    background: "white",
    color: theme.palette.primary.main,
    zIndex: 99,
  },
  searchResultSection: {
    borderLeft: `1px solid ${theme.palette.divider}`,
    height: "calc(100% - 210px)",
    overflow: "scroll",
  },
  fieldsSection: {
    padding: 16,
    height: "calc(100% - 210px)",
    overflow: "scroll",
    overflowX: "hidden",
  },
  choosPatientHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: 8,
    padding: 16,
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  patientDetailCard: {
    padding: 16,
  },
  hash: {
    color: theme.palette.hash.primary,
  },
}));

export const PatientDetail = ({
  patientDetail = {},
  isEdit = false,
  onPatientSelected = () => false,
  patientData = [],
  upsertPatientData = [],
  citizenData = [],
}) => {
  const [state, setState] = useState(patientDetail);
  const alert = useContext(AlertContext);

  const classes = useStyles();
  // const dispatch = useDispatch();

  // const patientData = useSelector(
  //   (state) => state?.patientSlice?.getAllPatient
  // );

  // const upsertPatientData = useSelector(
  //   (state) => state?.patientSlice?.upsertPatient
  // );

  // const countryData = useSelector(
  //     state => state?.countrySlice?.getAllCountries
  // );

  // const citizenData = useSelector(
  //   (state) => state?.countrySlice?.getAllCitizen
  // );

  const changeState = (key, value) => {
    state[key] = value;

    setState({
      ...state,
    });
    if (key === "email") {
      emailHandler(value);
    }

    searchPatient();
  };
  const [error, seterror] = useState(false);
  const emailHandler = (value) => {
    let validation = ValidateEmail(value) ? true : false;
    if (!validation && value.length > 0) {
      seterror(true);
    } else {
      seterror(false);
    }
  };

  const searchPatient = () => {
    let params = {
      filter: buildPatientSearchFilter(),
    };

    // Promise.resolve(dispatch(actions.GET_ALL_PATIENTS(params)));
  };

  const buildPatientSearchFilter = () => {
    let filter = "";

    if (state?.ID) {
      filter += ` && patient.ID like "${state?.ID}%"`;
    }
    if (state?.patient_name?.first_name) {
      filter += ` && LOWER(patient.patient_name.first_name) like "${state?.patient_name?.first_name.toLowerCase()}%"`;
    }
    if (state?.mobile?.number) {
      filter += ` && patient.mobile.number like "${state?.mobile?.number}%"`;
    }

    if (state?.email) {
      filter += ` && UPPER(patient.email) like "${state?.email.toUpperCase()}%"`;
    }

    state?.country?.patient_details?.components?.forEach((comp) => {
      if (state?.[comp.id]) {
        if (
          ["input_text", "input_textarea", "input_number"].indexOf(
            comp.component
          ) > -1
        ) {
          filter += ` && UPPER(patient["${comp.id}"]) like "${
            state?.[comp.id]
          }%"`;
        }
      }
    });

    return filter;
  };

  const giveMeProperQuestion = (comp) => {
    return {
      ...comp,
      question_id: comp.id,
      ui_component: comp.component,
      is_required: comp.required,
      question: comp.label,
      question_options: comp.options,
      table_scheme: comp.tableScheme,
    };
  };

  const isValidToInsert = () => {
    let isValid = true;
    let Validfirstname =
      state?.patient_name?.first_name?.trim().length === 0 ||
      !state.hasOwnProperty("patient_name")
        ? true
        : false;
    // let Validlastname =
    //   state?.patient_name?.last_name?.trim().length === 0 ||
    //   !state.hasOwnProperty("patient_name")
    //     ? true
    //     : false;
    // let Validmiddlename =
    //   state?.patient_name?.middle_name?.trim().length === 0 ||
    //   !state.hasOwnProperty("patient_name")
    //     ? true
    //     : false;
    let validaddress =
      state?.addressline?.trim().length === 0 ||
      !state.hasOwnProperty("addressline")
        ? true
        : false;
    let valididtype =
      state?.IdType?.value?.length === 0 || !state.hasOwnProperty("IdType")
        ? true
        : false;
    let validid =
      state?.ID?.length === 0 || !state.hasOwnProperty("ID") ? true : false;
    let validgender =
      state?.gender?.length === 0 || !state.hasOwnProperty("gender")
        ? true
        : false;
    let validmobilenumber =
      state?.mobile?.number?.toString()?.length === 0 ||
      !state.hasOwnProperty("mobile")
        ? true
        : false;
    let validdob =
      state?.dob_age?.age?.toString()?.length === 0 ||
      !state.hasOwnProperty("dob_age")
        ? true
        : false;
    let validcountry =
      state?.country?.value?.length === 0 || !state.hasOwnProperty("country")
        ? true
        : false;
    if (
      //   !state?.patient_name?.title?.value ||
      Validfirstname ||
      valididtype ||
      validid ||
      validgender ||
      validmobilenumber ||
      validdob ||
      validcountry ||
      //   Validmiddlename ||
      //   Validlastname ||
      //   !state?.gender ||
      //   !state?.mobile?.number ||
      // !state?.email ||
      //   !state?.dob_age?.dob ||
      //   !state?.addresline ||
      validaddress
      //   !state?.address?.country
      // !state?.country?.value
    ) {
      isValid = false;
    }

    for (
      let index = 0;
      index < state?.country?.patient_details?.components?.length ?? 0;
      index++
    ) {
      const comp = state?.country?.patient_details?.components?.[index];
      if (comp.required && !state?.[comp.id]) {
        isValid = false;
        break;
      }
    }

    return isValid;
  };

  const createPatientAndNavigate = async () => {
    if (!isValidToInsert()) {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.warning,
        msg: `Please Fill the mandatory fields`,
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
      });
      return false;
    }

    let params = {
      ...state,
    };

    onPatientSelected({
      ...state,
      patient_id: null,
      createPatient: true,
      createPatientData: params,
    });
  };

  const navigateToForm = () => {
    onPatientSelected({
      ...state.selectedPatient,
      patient_id: state.patient_id,
    });
  };

  const onPatientSelectedFromList = (data, isSelected) => {
    if (isSelected) {
      setState({
        ...state,
        patient_id: data?.patient_id,
        selectedPatient: data,
      });
    } else {
      setState({
        ...state,
        patient_id: null,
        selectedPatient: null,
      });
    }
  };

  return (
    <div className={classes.root}>
      <Grid container direction={"row"}>
        <Grid item xs={12} className={classes.header}>
          <Typography variant="body1">Patient Detail</Typography>
        </Grid>

        <Grid item xs={12} container direction={"row"}>
          <Grid item xs={12} md={8} className={classes.fieldsSection}>
            <Grid container direction="row" spacing={3}>
              {/* Basic Details */}
              {/* Patient Name */}

              {/* Patient Country */}
              <Grid item xs={12} md={4}>
                <Typography
                  variant="body1"
                  gutterBottom
                  className={classes.hash}
                >
                  ID Type{" "}
                  <Typography color="error" variant="caption">
                    *
                  </Typography>
                </Typography>
                <Select
                  id="country"
                  //   isLoading={citizenData?.loading}
                  isClearable
                  isSearchable
                  value={state?.IdType}
                  placeholder="Select ID Type"
                  options={[
                    { value: "Patient ID", label: "Patient ID" },
                    { value: "National ID", label: "National ID" },
                    { value: "Passport Number", label: "Passport Number" },
                    { value: "IQAMA", label: "IQAMA Number" },
                  ]}
                  onChange={(value) => changeState("IdType", value)}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography
                  variant="body1"
                  gutterBottom
                  className={classes.hash}
                >
                  ID
                  <Typography color="error" variant="caption">
                    *
                  </Typography>
                </Typography>

                <TextField
                  id="ID"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={state?.ID ?? ""}
                  placeholder="ID"
                  onChange={(e) => changeState("ID", e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography
                  variant="body1"
                  gutterBottom
                  className={classes.hash}
                >
                  Choose citizen country{" "}
                  <Typography color="error" variant="caption">
                    *
                  </Typography>
                </Typography>
                <Select
                  id="country"
                  isLoading={citizenData?.loading}
                  isClearable
                  isSearchable
                  value={state?.country}
                  placeholder="Select Country"
                  options={citizenData?.loading ? [] : citizenData?.data ?? []}
                  onChange={(value) => changeState("country", value)}
                />
              </Grid>

              <Grid item xs={12}>
                <Divider variant="fullWidth" />
                {/* <Typography variant="h6">Basic Patient Details</Typography> */}
              </Grid>

              <Grid item xs={12}>
                <Typography
                  variant="body1"
                  gutterBottom
                  className={classes.hash}
                >
                  Patient Name{" "}
                  <Typography color="error" variant="caption">
                    *
                  </Typography>
                </Typography>
                <Grid container direction="row" spacing={2}>
                  <Grid item xs={12} md={3}>
                    <Typography
                      variant="body1"
                      gutterBottom
                      className={classes.hash}
                    >
                      Title{" "}
                      <Typography color="error" variant="caption">
                        *
                      </Typography>
                    </Typography>
                    <Select
                      id="patientName"
                      isClearable
                      isSearchable
                      value={state?.patient_name?.title}
                      //   placeholder="Select Title"
                      options={personalTitles}
                      onChange={(value) =>
                        changeState("patient_name", {
                          ...(state?.patient_name ?? {}),
                          title: value,
                        })
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Typography
                      variant="body1"
                      gutterBottom
                      className={classes.hash}
                    >
                      First Name{" "}
                      <Typography color="error" variant="caption">
                        *
                      </Typography>
                    </Typography>
                    <TextField
                      id="firstName"
                      variant="outlined"
                      size="small"
                      fullWidth
                      value={state?.patient_name?.first_name ?? ""}
                      //   placeholder="First Name"
                      onChange={(e) =>
                        changeState("patient_name", {
                          ...(state?.patient_name ?? {}),
                          first_name: e.target.value,
                        })
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Typography
                      variant="body1"
                      gutterBottom
                      className={classes.hash}
                    >
                      Middle Name{" "}
                    </Typography>
                    <TextField
                      id="MiddleName"
                      variant="outlined"
                      size="small"
                      fullWidth
                      value={state?.patient_name?.middle_name ?? ""}
                      //   placeholder="Middle Name"
                      onChange={(e) =>
                        changeState("patient_name", {
                          ...(state?.patient_name ?? {}),
                          middle_name: e.target.value,
                        })
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Typography
                      variant="body1"
                      gutterBottom
                      className={classes.hash}
                    >
                      Last Name{" "}
                    </Typography>
                    <TextField
                      //   id="LastName"
                      variant="outlined"
                      size="small"
                      fullWidth
                      value={state?.patient_name?.last_name ?? ""}
                      //   placeholder="Last Name"
                      onChange={(e) =>
                        changeState("patient_name", {
                          ...(state?.patient_name ?? {}),
                          last_name: e.target.value,
                        })
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>

              {/* Gender */}
              <Grid item xs={12} md={6}>
                <Typography variant="body1" className={classes.hash}>
                  Gender{" "}
                  <Typography color="error" variant="caption">
                    *
                  </Typography>
                </Typography>
                <TabSelect
                  id="gender"
                  label={""}
                  value={state?.gender}
                  items={genderData}
                  onClick={(value) => changeState("gender", value)}
                  isReadyOnly={false}
                  errorValidation={true ?? {}}
                  disabled={false ?? false}
                />
              </Grid>

              {/* Date of Birth & Age */}
              <Grid item xs={12} md={6}>
                <Typography variant="body1" className={classes.hash}>
                  Date of Birth / Age{" "}
                  <Typography color="error" variant="caption">
                    *
                  </Typography>
                </Typography>
                <DOB
                  id={"DOBFromPatientDetails"}
                  value={state?.dob_age}
                  onChange={(value) => changeState("dob_age", value)}
                  label={""}
                />
              </Grid>

              {/* Email Id */}
              <Grid item xs={12} md={6}>
                <Typography
                  variant="body1"
                  gutterBottom
                  className={classes.hash}
                >
                  Email Id
                </Typography>
                <TextField
                  id="emailID"
                  fullWidth
                  size={"small"}
                  variant={"outlined"}
                  value={state?.email}
                  placeholder={"xxxx@xxx.xx"}
                  onChange={(e) => changeState("email", e.target.value)}
                  error={error}
                  helperText={error ? "Enter Valid Email" : ""}
                />
              </Grid>

              {/* Mobile No */}
              <Grid item xs={12} md={6}>
                <Typography variant="body1" className={classes.hash}>
                  Mobile No.{" "}
                  <Typography color="error" variant="caption">
                    *
                  </Typography>
                </Typography>
                <Mobile
                  idSelect={"mobileformdetailcountrycode"}
                  id={"mobileFromPatientDetails"}
                  value={state?.mobile}
                  label={""}
                  handleChange={(value) => changeState("mobile", value)}
                  type={"number"}
                />
              </Grid>

              {/* House No */}
              <Grid item xs={12} md={12}>
                <Typography
                  variant="body1"
                  gutterBottom
                  className={classes.hash}
                >
                  House No / Street Name{" "}
                  <Typography color="error" variant="caption">
                    *
                  </Typography>{" "}
                </Typography>
                <TextField
                  id="addressFromPatientDetails"
                  fullWidth
                  size={"small"}
                  variant={"outlined"}
                  rowsMax={5}
                  value={state?.addressline}
                  placeholder={"4, Street Name"}
                  onChange={(e) => changeState("addressline", e.target.value)}
                />
              </Grid>

              {/* Address */}

              <Grid item container spacing={2} style={{ display: "flex" }}>
                <Grid item xs={12}>
                  <Typography
                    variant="body1"
                    style={{ fontSize: 16 }}
                    className={classes.hash}
                  >
                    Current Address
                    <Typography variant="caption" style={{ color: "red" }}>
                      *
                    </Typography>
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={8}
                  lg={8}
                  // style={{ borderRight: "1px solid grey" }}
                >
                  <LocationLevel
                    id={"locationLevelFromPatientDetails"}
                    label={"Current Address"}
                    isrequired={true}
                    value={state?.address}
                    handleChange={(value) => changeState("address", value)}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={4} lg={4} spacing={1}>
                  <Typography
                    variant="body2"
                    gutterBottom
                    className={classes.hash}
                  >
                    Pincode{" "}
                  </Typography>
                  <TextField
                    id="addressFromPatientDetails"
                    fullWidth
                    size={"small"}
                    variant={"outlined"}
                    rowsMax={4}
                    value={state?.pincode}
                    onChange={(e) => changeState("pincode", e.target.value)}
                  />
                </Grid>
              </Grid>

              {state?.country?.patient_details?.components?.length > 0 && (
                <>
                  <Grid item xs={12}>
                    <Divider variant="fullWidth" />
                  </Grid>
                  {state?.country?.patient_details?.components?.map(
                    (comp, key) => {
                      return (
                        <Grid key={key} item xs={12} md={6}>
                          <ComponentToRender
                            data={giveMeProperQuestion(comp)}
                            isrequired={comp.required}
                            value={state?.[comp.id] ?? ""}
                            index={key}
                            onChange={(index, data, value) =>
                              changeState(comp.id, value)
                            }
                            // isReadyOnly={checkSectionType()}
                            // errorValidation={errorValidation[comp.id]}
                            // disabled={canIVisibleHere(comp)}
                          />
                        </Grid>
                      );
                    }
                  )}
                </>
              )}
            </Grid>
          </Grid>

          <Grid item xs={12} md={4} className={classes.searchResultSection}>
            {patientData?.loading && <LinearProgress />}
            {patientData?.data?.length > 0 &&
              (state?.email ||
                //state?.mobile?.number ||
                state?.ID ||
                state?.patient_name?.first_name) && (
                <Grid
                  container
                  direction="row"
                  alignItems="flex-start"
                  justify="space-between"
                >
                  <Grid item xs={12}>
                    <div className={classes.choosPatientHeader}>
                      <Typography variant="body1">
                        {"Choose Patient"}
                      </Typography>
                      {!state.patient_id && (
                        <Button
                          id="createPatient"
                          size="small"
                          variant="outlined"
                          color="primary"
                          disabled={upsertPatientData?.loading}
                          onClick={createPatientAndNavigate}
                        >
                          {upsertPatientData?.loading
                            ? "Creating Patient Record..."
                            : "Create Patient & Notify"}
                          <div style={{ marginLeft: 5, display: "flex" }}>
                            <ArrowRightAltIcon />
                          </div>
                        </Button>
                      )}
                      {state.patient_id && (
                        <Button
                          id="NotifySelected"
                          size="small"
                          variant="outlined"
                          color="primary"
                          onClick={navigateToForm}
                        >
                          {"Notify Selected Patient"}
                          <div style={{ marginLeft: 5, display: "flex" }}>
                            <ArrowRightAltIcon />
                          </div>
                        </Button>
                      )}
                    </div>

                    <div className={classes.patientDetailCard}>
                      {patientData?.data?.map((patient, key) => {
                        return (
                          <PatientDetailCard
                            {...patient}
                            data={patient}
                            id={key}
                            onselect={onPatientSelectedFromList}
                            selectedPatient={state.patient_id}
                            showSelectOptions={true}
                          />
                        );
                      })}
                    </div>
                  </Grid>
                </Grid>
              )}

            {(!state?.mobile?.number || patientData?.data?.length === 0) &&
              (!state?.email || patientData?.data?.length === 0) && (
                <div style={{ marginTop: "30%", padding: 16 }}>
                  <Typography
                    variant="subtitle1"
                    color="textSecondary"
                    align="center"
                    gutterBottom
                  >
                    {"No Existing Patient Record Found in IPPC"}
                  </Typography>
                  <Button
                    id="CreatePatientAndNotify"
                    variant="contained"
                    color="primary"
                    disabled={upsertPatientData?.loading}
                    onClick={createPatientAndNavigate}
                    fullWidth
                  >
                    {upsertPatientData?.loading
                      ? "Creating Patient Record..."
                      : "Create Patient & Notify"}
                    <div style={{ marginLeft: 5, display: "flex" }}>
                      <ArrowRightAltIcon />
                    </div>
                  </Button>
                </div>
              )}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
