import * as React from "react";
import PropTypes from "prop-types";

const Diagnosis = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
  <path id="icons8-stethoscope" d="M2.9,1.7A1.2,1.2,0,0,0,1.7,2.9V4.7A4.2,4.2,0,0,0,4.345,8.592a1.792,1.792,0,0,0,.955.8V11.3a2.4,2.4,0,0,0,2.4,2.4h2.4a2.4,2.4,0,0,0,2.4-2.4v-.11a1.8,1.8,0,1,0-1.2,0v.11a1.2,1.2,0,0,1-1.2,1.2H7.7a1.2,1.2,0,0,1-1.2-1.2V9.39a1.792,1.792,0,0,0,.955-.8A4.205,4.205,0,0,0,10.1,4.686V2.9A1.2,1.2,0,0,0,8.9,1.7H7.7V2.9H8.9V4.7a3,3,0,1,1-6-.014V2.9H4.1V1.7Zm9,7.05a.75.75,0,1,1-.75.75A.75.75,0,0,1,11.9,8.75Z" transform="translate(-1.7 -1.7)" 
  fill={props?.color}/>
</svg>
);

export default Diagnosis;

Diagnosis.defaultProps = {
  color: "black",
};

Diagnosis.propTypes = {
  color: PropTypes.string,
};