import * as React from "react";
import PropTypes from "prop-types";

const MoonIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="11.812"
    height="15"
    viewBox="0 0 11.812 15"
  >
    <path
      id="icons8-moon-symbol"
      d="M11.5,4a7.5,7.5,0,1,0,4.055,13.8.563.563,0,0,0,0-.946,6.365,6.365,0,0,1,0-10.717.563.563,0,0,0,0-.946A7.464,7.464,0,0,0,11.5,4Zm0,1.125a6.221,6.221,0,0,1,2.716.679,7.331,7.331,0,0,0,0,11.392,6.221,6.221,0,0,1-2.716.679,6.375,6.375,0,1,1,0-12.75Z"
      transform="translate(-4 -4)"
      fill={props.color}
    />
  </svg>
);

export default MoonIcon;

MoonIcon.defaultProps = {
  color: "#6a7888",
};

MoonIcon.propTypes = {
  color: PropTypes.string,
};
