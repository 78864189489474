import * as React from "react";
import PropTypes from "prop-types";

const DocumentIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={10.54}
    height={13.175}
    {...props}
  >
    <path
      d="M1.482 0A1.49 1.49 0 0 0 0 1.482v10.211a1.49 1.49 0 0 0 1.482 1.482h7.576a1.49 1.49 0 0 0 1.482-1.482V4.776a.494.494 0 0 0-.145-.349l-.005-.005L6.113.145A.494.494 0 0 0 5.764 0Zm0 .988H5.27v2.8A1.49 1.49 0 0 0 6.752 5.27h2.8v6.423a.487.487 0 0 1-.494.494H1.482a.487.487 0 0 1-.494-.494V1.482a.487.487 0 0 1 .494-.494Zm4.776.7 2.6 2.6h-2.1a.487.487 0 0 1-.494-.494Zm-3.129 5.23a.494.494 0 1 0 0 .988h4.282a.494.494 0 1 0 0-.988Zm0 2.306a.494.494 0 1 0 0 .988h2.964a.494.494 0 1 0 0-.988Z"
      fill={props.color}
    />
  </svg>
);

export default DocumentIcon;

DocumentIcon.defaultProps = {
  color: "#000",
};

DocumentIcon.propTypes = {
  color: PropTypes.string,
};
