import * as React from "react";
import PropTypes from "prop-types";

const SchoolIcon = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="14.702" height="14" viewBox="0 0 14.702 14">
    <path id="icons8-school" d="M9.284,2a.5.5,0,0,0-.168.035L2.283,4.7a.5.5,0,1,0,.363.931L9.3,3.036l6.652,2.6a.5.5,0,1,0,.363-.931L9.479,2.034A.5.5,0,0,0,9.284,2ZM4.464,6.333A1.174,1.174,0,0,0,3.3,7.5v6.667a1.174,1.174,0,0,0,1.167,1.167H8.152a1.317,1.317,0,0,0,2.29,0h3.688A1.174,1.174,0,0,0,15.3,14.166V7.5a1.174,1.174,0,0,0-1.167-1.167h-3.5a1.8,1.8,0,0,0-1.415.719,2.144,2.144,0,0,0-1.585-.719Zm0,1H7.631A1.167,1.167,0,0,1,8.8,8.5v5.833H4.464a.159.159,0,0,1-.167-.167V7.5A.159.159,0,0,1,4.464,7.333Zm6.167,0h3.5A.159.159,0,0,1,14.3,7.5v6.667a.159.159,0,0,1-.167.167H9.8V8.166A.826.826,0,0,1,10.631,7.333Z"
     transform="translate(-1.946 -2)" 
     fill={props?.color}/>
  </svg>
);

export default SchoolIcon;

SchoolIcon.defaultProps = {
  color: "#2a60bc",
};

SchoolIcon.propTypes = {
  color: PropTypes.string,
};
