import * as React from "react";
import PropTypes from "prop-types";
import { Routes } from "../router/routes";
import { useTheme } from "@material-ui/core";

const DrugIcon = (props) => {
  const theme = useTheme();
  const color=
    window.location.pathname === Routes.drugmastertable ||
    window.location.pathname === Routes.drugintscreen ||
    window.location.pathname === Routes.alertrestriction
      ? theme.sideBar.text.active
      : theme.sideBar.text.nonActive
  
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={18}
      height={16.782}
      {...props}
    >
      <path
        d="M11.849 0a4.923 4.923 0 0 0-3.492 1.442L1.442 8.358a4.937 4.937 0 0 0 6.982 6.982l1.229-1.23a4.364 4.364 0 1 0 5.758-5.769A4.929 4.929 0 0 0 11.845 0ZM5.581 6.071l4.2 4.2a4.33 4.33 0 0 0-.516 2.052c0 .109.009.217.017.324a.655.655 0 0 0-.1.086l-1.684 1.678a3.627 3.627 0 0 1-5.13-5.13Zm8.051 3.194a3.057 3.057 0 1 1-3.057 3.057 3.048 3.048 0 0 1 3.057-3.058Zm1.079 1.3a.655.655 0 0 0-.45.2l-2.184 2.186a.655.655 0 1 0 .927.927l2.184-2.184a.655.655 0 0 0-.476-1.124Z"
        fill={color}
        data-name="Group 94195"
      />
    </svg>
  );
};

export default DrugIcon;

DrugIcon.defaultProps = {
  color: "#2a60bc",
};

DrugIcon.propTypes = {
  color: PropTypes.string,
};
