import * as React from "react";
import PropTypes from "prop-types";

const WarningIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={12.511}
    height={12.527}
    {...props}
  >
    <defs>
      <clipPath id="a">
        <path
          data-name="Rectangle 50314"
          fill={props.color}
          d="M0 0h12.511v12.527H0z"
        />
      </clipPath>
    </defs>
    <g data-name="Group 95399" clipPath="url(#a)">
      <path
        data-name="Path 95328"
        d="M6.248 4.341a.583.583 0 1 0 .583.583.583.583 0 0 0-.583-.583m-.007 2.035a.437.437 0 0 0-.431.443v3.208a.438.438 0 0 0 .875 0V6.82a.437.437 0 0 0-.431-.444h-.013m0-5.542a.677.677 0 0 1 .619.382l4.74 9.442a.693.693 0 0 1-.617 1l-9.458.032a.693.693 0 0 1-.62-1l4.714-9.473a.675.675 0 0 1 .62-.384m0-.833a1.509 1.509 0 0 0-1.367.847L.161 10.321a1.526 1.526 0 0 0 1.367 2.206l9.455-.032a1.526 1.526 0 0 0 1.358-2.212L7.6.841A1.508 1.508 0 0 0 6.239 0"
        fill={props.color}
      />
    </g>
  </svg>
);

export default WarningIcon;

WarningIcon.defaultProps = {
  color: "#2a3c50",
};

WarningIcon.propTypes = {
  color: PropTypes.string,
};
