import {
  Box,
  CircularProgress,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React from "react";
import { useDispatch } from "react-redux";
import { SearchTextField } from "../../components";
import { ListOfCards } from "./listOfCards";
import { AxiosCall } from "../../utils";
import { withNavBars } from "../../HOCs";
import { Paper, withTheme } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  body: {
    height: "calc(100% - 55px)",
    padding: "8px",
    margin: "16px 0",
  },
  boxCenter: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  captionText: {
    fontWeight: "600",
    color: theme.palette.text.disabled,
  },
  searchText: {
    paddingTop: "16px",
  },
}));

const Patient = (props) => {
  const { updateSelectedPatient } = props;
  const classes = useStyles();
  const [search, setSearch] = React.useState("");
  const [patientList, setPatientList] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [eptyScrnMesg, setEptyScrnMesg] = React.useState(
    "Please search by Patient Id"
  );

  const onHandleChange = (e) => {
    if (e.target.value.length !== 0) {
      setSearch(e.target.value);
    } else {
      setSearch("");
      setPatientList([]);
    }
  };

  const onEnterClicked = (e) => {
    if (e.key === "Enter") {
      onSearchClicked();
    }
  };

  const onSearchClicked = async () => {
    setLoading(true);
    let searchValue = search;

    if (search.trim().length > 0) {
      let params = {
        db_name: process.env.REACT_APP_DB,
        entity: "patients",
        filter: `(LIKE(patients.ID,'%${search}%',true) || LIKE(patients.patient_name.first_name,'%${search}%',true) || LIKE(patients.patient_name.middle_name,'%${search}%',true) || LIKE(patients.patient_name.last_name,'%${search}%',true) || LIKE(patients.mobile.number,'%${search}%',true) || LIKE(patients.patient_id,'%${search}%',true)) && patients.activestatus==true`,
        return_fields:
          "unset(patients,'_rev','updatedate','updatedby','createddate','createdby')",
      };

      let res = await AxiosCall(
        "post",
        process.env.REACT_APP_ARANGO_URL_READ,
        params
      );
      let result = res?.result;
      //setUser(result);

      if (result.length > 0) {
        setPatientList(result);
        setLoading(false);
      } else {
        setLoading(false);
        setPatientList([]);

        setEptyScrnMesg("No data available");
      }
    } else {
      setLoading(false);
      setPatientList([]);
      setEptyScrnMesg("No data available");
    }
  };

  console.log("patientList", patientList);

  return (
    <div className={classes.searchText}>
      <SearchTextField
        value={search}
        onHandleChange={(e) => onHandleChange(e)}
        onKeyPress={(e) => onEnterClicked(e)}
        onSearchClicked={() => onSearchClicked()}
        placeholder="Search by Patient Id"
      />
      <Box className={classes.body}>
        {loading && patientList?.length === 0 && (
          <div className={classes.boxCenter}>
            <CircularProgress />
          </div>
        )}
        {!loading && patientList?.length === 0 && (
          <div className={classes.boxCenter}>
            <Typography variant="body2" className={classes?.captionText}>
              {`" ${eptyScrnMesg} "`}
            </Typography>
          </div>
        )}
        {!loading && patientList?.length > 0 && (
          <ListOfCards
            patientList={patientList}
            updateSelectedPatient={updateSelectedPatient}
          />
        )}
      </Box>
    </div>
  );
};
export default Patient;
