import React from "react";
import { SubHeader } from "../../components/subHeader";
import { Grid, makeStyles, Button } from "@material-ui/core";
import { useHistory } from "react-router";
import { Message } from "../../utils";
import { FacilityDetails } from "./facilityDetails";
import { ClientLevels } from "./clientLevels";
import { AddressDetails } from "./addressDetails";
import { BackdropContext, AlertContext } from "../../contexts";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "surveillance-binder";
import { AlertProps } from "../../utils/constants";
import copy from "fast-copy";

// Store Action's list
const { SET_ORGANIZATION } = actions;

const InitialState = {
  EntityType: {},
  EntityCode: "",
  EntityName: "",
  LevelOfCare: {},
  ParentEntity: {},
  FacilityAdminEmail: "",
  parent_organization: {},
  imageDetails: [],
  dataSecurity: {
    disease: null,
    form: null,
    error: {
      mappingTable: "",
    },
  },
  privileges: {},
  clientlevels: null,
  Address: [],
  Contact: [
    {
      mode: {},
      number: "",
      use: {},
      priority: {},
    },
  ],
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(1),
    height: "inherit",
  },
  marginR: {
    marginRight: theme.spacing(2),
  },
}));

export const CreateFacility = (props) => {
  // Declaring Store dispatch
  const dispatch = useDispatch();
  const { CreateClose, getAlldata, client_id, tenantid } = props;
  const classes = useStyles(props);
  const backdrop = React.useContext(BackdropContext);
  const alert = React.useContext(AlertContext);
  const [state, setState] = React.useState(InitialState);
  const [previlages] = React.useState({});
  //valid check for contact details
  const clientPayload = useSelector(
    (state) => state?.clientSlice?.getClientDetail?.data
  );

  //const userData = useSelector(state => state?.authSlice?.login?.data);

  React.useEffect(() => {
    if (props?.data) {
      getOrganization(props?.data);
    }
  }, []);

  React.useEffect(() => {
    if (!props?.data) {
      if (state?.EntityType?.label === "Facility") {
        setState({
          ...state,
          dataSecurity: props.data_security,
          privileges: previlages,
        });
      }
    }
    // eslint-disable-next-line
  }, [state.EntityType]);

  const getOrganization = async (_key) => {
    backdrop.setBackDrop({ ...backdrop, open: true, message: "Loading" });
    const organization = await dispatch(
      actions.ORGANIZATION_READ_DETAILS({ _key, client_id: props?.client_id })
    );
    // console.log(organization, "organization");

    if (!organization?.error && organization?.payload?.data) {
      const { data } = copy(organization?.payload);
      //   console.log(data, "data");
      const modifyState = {
        EntityType: data?.entityType,
        EntityCode: data?.entityCode ?? "",
        EntityName: data?.entityName ?? "",
        LevelOfCare: data?.levelOfCare,
        PCILocation: data?.PCILocation,
        ParentEntity: data?.parentEntity,
        parent_organization: {},
        imageDetails: data?.logo ?? [],
        dataSecurity: data?.roleDetail?.[0]?.roleDetail?.data_security,
        privileges: data?.roleDetail?.[0]?.roleDetail?.previlages,
        Address: data?.address ?? [],
        Contact: data?.contactData ?? [],
        organization_id: _key,
        role_id: data?.roleDetail?.[0]?.roleDetail?._key,
        clientlevels: data?.mappedLevel,
      };

      setState(modifyState);
    }

    backdrop.setBackDrop({ ...backdrop, open: false });
  };

  const updateState = (key, value) => {
    setState({ ...state, [key]: value });
  };

  const onBackBtnClicked = () => {
    CreateClose();
  };

  const onSaveClicked = () => {
    backdrop.setBackDrop({ ...backdrop, open: true, message: "Loading" });
    let key = props?.data ? props?.data : "";
    Promise.resolve(
      dispatch(
        SET_ORGANIZATION({
          state: { ...state, client_id: props.client_id },
          clientPayload: clientPayload,
          key,
          tenantid: tenantid,
        })
      )
    ).then((res) => {
      backdrop.setBackDrop({ ...backdrop, open: false });

      if (res.payload.error) {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: res.payload.message,
        });
      } else {
        CreateClose();
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: props?.data
            ? "Facility Updated Successfully !!!"
            : "Facility Created Successfully !!!",
        });
        getAlldata(client_id);
      }
    });
  };

  let facilityType;
  let facilityCode;
  let facilityName;
  let levelOfcare;
  let address;
  let contactDetails;
  let ParentEntity;
  let validPrevilages;
  let dataSecurity;

  const validation = () => {
    facilityType =
      Object?.entries(state?.EntityType)?.length === 0 ? true : false;
    facilityCode = state?.EntityCode?.trim().length === 0 ? true : false;
    facilityName = state?.EntityName?.trim().length === 0 ? true : false;
    levelOfcare = Object?.keys(state?.LevelOfCare)?.length === 0 ? true : false;
    address = state?.Address?.length === 0 ? true : false;
    contactDetails =
      state?.Contact?.[0]?.number?.trim()?.length === 0 ? true : false;

    ParentEntity =
      Object?.entries(state?.ParentEntity)?.length === 0 ? true : false;

    // if (state?.EntityType?.label === "Facility") {
    //     validPrevilages = Object?.values(state?.privileges)?.flat()?.length === 0 ? true : false;
    // } else {
    //     validPrevilages = false;
    // }

    // FacilityAdminEmail = state?.FacilityAdminEmail?.trim().length === 0 ? true : false;
    //dataSecurity = (!state?.dataSecurity?.mappingTable?.[0]?.disease) && (!state?.dataSecurity?.mappingTable?.[0]?.form);

    if (facilityType) {
      return false;
    }

    if (state?.EntityType?.label === "Enterprise") {
      if (
        !facilityType &&
        !facilityCode &&
        !facilityName &&
        !levelOfcare &&
        !address &&
        !contactDetails
      ) {
        return true;
      } else {
        return false;
      }
    } else if (state?.EntityType?.label === "Organization") {
      if (
        !facilityType &&
        !facilityCode &&
        !facilityName &&
        !ParentEntity &&
        !address &&
        !contactDetails
      ) {
        return true;
      } else {
        return false;
      }
    } else if (state?.EntityType?.label === "Facility") {
      if (
        !facilityType &&
        !facilityCode &&
        !facilityName &&
        !ParentEntity &&
        !address &&
        !contactDetails
      ) {
        return true;
      } else {
        return false;
      }
    }
  };

  const getValidationErrorMessage = () => {
    if (facilityType) {
      return Message?.requiredFacilityType;
    }

    if (state?.EntityType?.label === "Enterprise") {
      if (
        facilityType &&
        facilityCode &&
        facilityName &&
        levelOfcare &&
        address &&
        contactDetails
      ) {
        return Message.requiredFields;
      } else if (facilityType) {
        return Message?.requiredFacilityType;
      } else if (facilityCode) {
        return Message.requiredFacilityCode;
      } else if (facilityName) {
        return Message.requiredFacilityName;
      } else if (levelOfcare) {
        return Message.requiredLevelOfCare;
      } else if (address) {
        return Message.requiredAddress;
      } else if (contactDetails) {
        return Message.requiredContactDetails;
      } else {
        return Message.requiredFields;
      }
    } else if (state?.EntityType?.label === "Organization") {
      if (
        facilityType &&
        facilityCode &&
        facilityName &&
        ParentEntity &&
        address &&
        contactDetails
      ) {
        return Message.requiredFields;
      } else if (facilityType) {
        return Message?.requiredFacilityType;
      } else if (facilityCode) {
        return Message.requiredFacilityCode;
      } else if (facilityName) {
        return Message.requiredFacilityName;
      } else if (ParentEntity) {
        return Message.requiredParentEntity;
      } else if (address) {
        return Message.requiredAddress;
      } else if (contactDetails) {
        return Message.requiredContactDetails;
      } else {
        return Message.requiredFields;
      }
    } else if (state?.EntityType?.label === "Facility") {
      if (
        facilityType &&
        facilityCode &&
        facilityName &&
        ParentEntity &&
        validPrevilages &&
        address &&
        contactDetails &&
        dataSecurity
      ) {
        return Message.requiredFields;
      } else if (facilityType) {
        return Message?.requiredFacilityType;
      } else if (facilityCode) {
        return Message.requiredFacilityCode;
      } else if (facilityName) {
        return Message.requiredFacilityName;
      } else if (ParentEntity) {
        return Message.requiredParentEntity;
      } else if (validPrevilages) {
        return Message.requiredPrivileges;
      } else if (address) {
        return Message.requiredAddress;
      } else if (contactDetails) {
        return Message.requiredContactDetails;
      } else if (dataSecurity) {
        return Message.requiredMappingTable;
      } else {
        return Message.requiredFields;
      }
    }
  };

  const Submit = () => {
    const isValidated = validation();
    let check = state?.Contact.filter((e) => e?.error);
    if (check.every((e) => e.error === "false")) {
      if (isValidated === false) {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.error,
          msg: getValidationErrorMessage(),
          // msg: "im here"
        });

        return false;
      } else {
        onSaveClicked();
      }
    } else {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.error,
        msg: "Please Check The Error Before Updating",
        // msg: "im here"
      });
    }
  };
  return (
    <div className={classes.root}>
      {/* Sub Header */}
      <Grid
        container
        direction="row"
        alignItems="center"
        justify="space-between"
        style={{ padding: "4px 8px" }}
      >
        <SubHeader
          title={`${props?.data ? "Edit" : "Create"} Facility`}
          onBackBtnClicked={onBackBtnClicked}
          noBackGround={true}
        />
      </Grid>

      {/* Facility Basic Details */}
      <div style={{ height: "calc(100% - 84px)", overflowY: "auto" }}>
        <FacilityDetails
          updateState={updateState}
          state={state}
          isEdit={props?.data}
          client_id={props?.client_id}
        />

        {state?.EntityType?.label === "Facility" ||
        state?.EntityType?.label === "Enterprise" ||
        state?.EntityType?.label === "Organization" ? (
          <>
            {/* Facility Levels */}
            <ClientLevels
              state={state}
              updateState={updateState}
              client_id={client_id}
            />
          </>
        ) : null}
        {/* Address Details */}
        <AddressDetails updateState={updateState} state={state} />
      </div>
      <div style={{ padding: "8px", width: "100%", textAlign: "right" }}>
        <Button
          size="large"
          variant="outlined"
          color="primary"
          className={classes.marginR}
          id="addAndUpdateAddressButton"
          style={{
            border: "1px solid #DEDEDE",
            "border-radius": "8px",
            opacity: 1,
          }}
          onClick={(e) => CreateClose()}
        >
          {"Cancel"}
        </Button>
        <Button
          size="large"
          variant="contained"
          color="primary"
          id="addAndUpdateAddressButton"
          // onClick={() => onSaveClicked()}
          onClick={() => Submit()}
        >
          {props?.data ? "Update" : "Save"}
        </Button>
      </div>
    </div>
  );
};
