import { Tabs, Tab, Paper, Divider, makeStyles } from '@material-ui/core'
import React from 'react'
import TabPanel from '@material-ui/lab/TabPanel';
import TabContext from '@material-ui/lab/TabContext';
import { ContactDetail } from '../createFacility/contactDetails';
const useStyles = makeStyles(theme => ({
    text: {
        textTransform: "capitalize"
    }
}))
export const FacilityTabComponent = (props) => {
    const [value, setValue] = React.useState("1");
    const classes = useStyles()
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Paper style={{ width: "100%", marginTop: "15px", flexGrow: 1, borderRadius: "8px", boxShadow: "0px 10px 25px #0000000A" }} id={"P1"}>
            <TabContext value={value}>
                <Tabs
                    value={value}
                    indicatorColor="primary"
                    onChange={handleChange}
                >
                    <Tab className={classes.text} label="Contact Details" value="1" />
                </Tabs>
                <Divider />
                <TabPanel value="1" ><ContactDetail Contact={props?.Contact} readOnly /></TabPanel>
            </TabContext>
        </Paper>
    )
}
