import React from "react";
import { Grid, makeStyles, Hidden, Button } from "@material-ui/core";
import { FormList } from "./formList";
import { Entries } from "./entries";
import MenuIcon from "@material-ui/icons/Menu";
import { DrawerContext } from "../../contexts";
import { DrawerProps } from "../../utils";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  paper: {
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "1px solid #0000001A",
    borderRadius: 10,
    overflow: "hidden",
    height: "100%",
    // padding:20
  },
  viewSection: {
    padding: "5px 12px",
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 3px 8px #110F471A",
    border: "1px solid #110F471A",
    borderRadius: 7,
    marginBottom: theme.spacing(1),
    "& .MuiButton-textSecondary:hover": {
      background: "#FFFFFF 0% 0% no-repeat padding-box",
    },
  },
}));

export const ListNotificationsV2 = (props) => {
  const classes = useStyles(props);

  let [state, setState] = React.useState({ initialLoading: false });

  let { entriesData, entriesTitle, getNotifiedEntryByFormId, onStatusUpdate } =
    props;
  let { loading } = entriesData;

  const onNewFormClicked = (initialLoading) => {
    setState({
      ...state,
      initialLoading,
    });
  };

  React.useEffect(() => {
    const checkInitialLoading = () => {
      if (!loading) {
        setState({
          initialLoading: false,
        });
      }
    };

    checkInitialLoading();
  }, [loading]);

  const drawerContext = React.useContext(DrawerContext);

  const viewSectionDrawer = () => {
    drawerContext.setDrawer({
      ...drawerContext,
      open: true,
      direction: DrawerProps.direction.left,
      variant: DrawerProps.variant.temporary,
      component: (
        <div style={{ padding: 10 }}>
          <div className={classes.paper}>
            <FormList {...props} closeDrawer onDrawerClosed={onDrawerClosed} />
          </div>
        </div>
      ),
      onClose: () => onDrawerClosed(),
    });
  };

  const onDrawerClosed = () => {
    drawerContext.setDrawer({
      ...drawerContext,
      direction: DrawerProps.direction.left,
      open: false,
    });
  };

  return (
    <>
      {
        <div className={classes.root}>
          <Grid container spacing={1}>
            <Hidden smDown>
              <Grid item xs={12} sm={12} md={3}>
                <div className={classes.paper}>
                  <FormList {...props} onNewFormClicked={onNewFormClicked} />
                </div>
              </Grid>
            </Hidden>

            <Hidden mdUp>
              <Grid item>
                <Button
                  id="viewForms"
                  variant="text"
                  className={classes.viewSection}
                  onClick={viewSectionDrawer}
                  color="secondary"
                >
                  <div style={{ marginRight: 5, display: "flex" }}>
                    {" "}
                    <MenuIcon />{" "}
                  </div>
                  View Forms
                </Button>
              </Grid>
            </Hidden>

            <Grid item xs={12} sm={12} md={9}>
              <div className={classes.paper}>
                <Entries
                  {...entriesData}
                  entriesTitle={entriesTitle}
                  onStatusUpdate={onStatusUpdate}
                  getNotifiedEntryByFormId={getNotifiedEntryByFormId}
                  initialLoading={state.initialLoading}
                />
              </div>
            </Grid>
          </Grid>
        </div>
      }
    </>
  );
};
