import React from "react";
import { withStyles } from "@material-ui/core";
import { ManageLocation } from "./manageLocation";
import { actions, idmapis } from "surveillance-binder";
import { connect } from "react-redux";
import { withRouter } from "react-router";

const styles = (theme) => ({
  root: {
    flex: 1,
    height: "100%",
  },
});

class ManageLevelParent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAdmin: "",
      clientdata: {},
      leveldata: {},
    };
  }

  async componentDidMount() {
    await this.getparentrepodetails();
    this.repoapis();
    this.callFunc();
  }
  repoupsert = async (repid, repname) => {
    let payload = {
      parent_rep: repid,
      unique_id: process.env.REACT_APP_IDM_MANAGELEVEL,
      tenantid: this.props?.tenantid,
      rep_name: repname,
      rep_type: "client",
    };
    let result = await idmapis?.UpsertandUpdate(payload);
    return result?.Result[0]?.properties?.doc;
  };
  getparentrepodetails = async () => {
    let filter = {
      filter: `IDM_Repository.activestatus==true && IDM_Repository.unique_id =='${
        process.env.REACT_APP_IDM_MANAGELEVEL
      }' && IDM_Repository.tenantid =='${
        this?.props?.tenantid ?? "clients/540256733"
      }'`,
    };
    let result = await this.props.PARENT_REPO_DETAILS(filter);
    return this.props.levelrepodetails.data;
  };
  repoapis = async () => {
    let Level_repo_check = await this.Levelrepocheck();
    if (this.props.levelrepodetails?.data?.length === 0) {
      let repid = Level_repo_check?.[0]?.rep_id;
      let codingmasterdetails = await idmapis?.getcodingmaster(
        process.env.REACT_APP_IDM_MANAGELEVEL
      );
      let repname = codingmasterdetails?.result[0]?.display;
      await this.repoupsert(repid, repname);
      await this.getparentrepodetails();
    }
  };
  Levelrepocheck = async () => {
    let filter = {
      filter: `IDM_Repository.activestatus==true && IDM_Repository.unique_id =='${
        this.props.clientId
      }' && IDM_Repository.tenantid =='${
        this?.props?.tenantid ?? "clients/540256733"
      }'`,
    };
    let result = await idmapis.getRepo(filter);
    return result?.result;
  };
  callFunc = async () => {
    // await this.getIsAdmin();
    await this.getManageLocation();
    // await this.getCountryName();
  };

  getIsAdmin = () => {
    if ("mapped_location" in this.props.userData?.mapped_roles[0]) {
      this.setState({
        ...this.state,
        isAdmin: false,
      });
    } else {
      this.setState({
        ...this.state,
        isAdmin: true,
      });
    }
  };

  getManageLocation = () => {
    // let countryId =
    //   this.props?.auth?.data?.mapped_roles[0]?.data_security?.country
    //     ?.country_id;

    this.props.GET_MANAGE_LEVELS({
      // countryId,
      // countryId: this.props?.clientId,
      client_id: this.props?.clientId,
    });
  };

  getCountryName = () => {
    // let countryId = "e68df9ff-a8c7-40e9-91c9-a834891d429d";
    let client_id = this.props?.clientId;

    // let countryId = data.mapped_roles[0].data_security.country.country_id

    this.props.GET_MANAGE_LEVEL_NAME({
      client_id,
    });
  };

  render() {
    const { classes, manageLevels } = this.props;
    // let countryId = this.props?.clientId;
    // let countryId = "e68df9ff-a8c7-40e9-91c9-a834891d429d";

    // this.props?.auth?.data?.mapped_roles[0]?.data_security?.country
    //   ?.country_id;

    //  console.log(this.state.isAdmin,"isadminTestRun");
    //  console.log(this.props.userData?.user_profile_id,"isRun");
    //  console.log(this.props.auth?.data?.mapped_roles[0]?.client_id,"clinet_id");
    // console.log(countryId,"countryID")
    // console.log(this.props.disabledadd1, "disablebt");

    return (
      <div className={classes.root}>
        <ManageLocation
          {...manageLevels}
          // countryId={countryId}
          getManageLocation={this.getManageLocation}
          // // manageLevelName={
          // //   this?.props?.manageLevelName?.data?.[0]?.country ?? ""
          // // }
          client_id={this.props.clientId}
          levelrepodetails={this.props.levelrepodetails.data}
          tenantid={this.props.tenantid}
          disableaddlevel={this.props.disableaddlevel}
          disabledadd1={this.props.disabledadd1}
          disabledadd2={this.props.disabledadd2}
          disabledmore={this.props.disabledmore}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state?.authSlice?.login,
  userData: state?.authSlice?.login?.data,
  manageLevels: state?.manageLevelSlice?.manageLevels,
  levelrepodetails: state?.manageLevelSlice?.parentrepodetail,
  // manageLevelName: state?.manageLevelSlice?.manageLevelName,
});

export default connect(
  mapStateToProps,
  actions
)(withRouter(withStyles(styles)(ManageLevelParent)));
