import React, { useState } from "react";
import {
  IconButton,
  TextField,
  Grid,
  Button,
  LinearProgress,
  Tooltip,
  FormControl,
  Hidden,
  Select,
  MenuItem,
  Badge,
  InputAdornment,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import AddIcon from "@material-ui/icons/Add";

const useStyles = makeStyles((theme) => ({
  textInput: {
    height: 40,
    backgroundColor: "#FFFFFF",
    width: 300,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
    borderRadius: 10,
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#110F471A",
    },
  },
  rowView: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
  },
  filterIcon: {
    height: 40,
    backgroundColor: "#FFFFFF",
    border: (props) =>
      `1px solid ${
        props?.isFilterApplied ? theme.palette.secondary.light : "#110F471A"
      }`,
    // padding: 5,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 10,
    cursor: "pointer",
    marginLeft: 10,
  },
  btn: {
    boxShadow: "0px 5px 23px #00000014",
    fontSize: 13,
    textTransform: "capitalize",
    letterSpacing: 1,
    height: 40,
    minWidth: 150,
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  flexRow: {
    display: "flex",
    flex: 1,
  },
  progressBar: {
    margin: "0px 10px",
  },
  mobViewAddBtn: {
    "& .MuiButton-contained": {
      height: 40,
      backgroundColor: "#FFFFFF",
      border: "1px solid #110F471A",
      color: "#000",
      borderRadius: 10,
      boxShadow: "none",
      marginLeft: 10,
    },
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  formControl: {
    margin: "0px 16px 0px 8px",
  },
  customSelect: {
    padding: "10.500px 14px",
  },
  select: {
    width: 200,
    height: 40,
  },
  searchBar: {
    color: theme.palette.text.primary,
  },
  filterIconBtn: {
    padding: theme.spacing(1),
  },
  mobFullWidth: {
    [theme.breakpoints.down("sm")]: {
      flex: 1,
    },
  },
}));

export const SearchFilter = (props) => {
  const classes = useStyles();
  const [state, setState] = React.useState({
    search: "",
  });

  const { placeholder, buttonName, onActionButton = () => false } = props;

  const changeState = (key, value) => {
    setState({
      ...state,
      [key]: value,
    });

    if (key === "search") {
      props.onSearchChange(value);
    }
  };

  return (
    <div className={classes.rowView}>
      <Grid container>
        <Grid item className={classes.flexRow}>
          <Grid item className={classes.mobFullWidth}>
            <TextField
              autoComplete="off"
              id="ippc_search_bar"
              placeholder={placeholder}
              fullWidth
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                className: classes.textInput,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon className={classes.searchBar} />
                  </InputAdornment>
                ),
              }}
              value={state.search}
              onChange={(e) => changeState("search", e.target.value)}
            />
            {/* <LinearProgress className={classes.progressBar} /> */}
          </Grid>

          {/* {!hideFilter && (
            <Grid item className={classes.filterIcon}>
              <Tooltip
                title={isFilterApplied ? "Filter Applied" : "Click for filter"}
              >
                <Badge
                  color={"secondary"}
                  variant="dot"
                  invisible={!isFilterApplied}
                >
                  <IconButton
                    className={classes.filterIconBtn}
                    onClick={onFilterClicked}
                  >
                    <FilterIcon isFilterApplied={isFilterApplied} />
                  </IconButton>
                </Badge>
              </Tooltip>
            </Grid>
          )} */}
        </Grid>

        {/* {showSort && (
          <Grid item>
            <Hidden xsDown>
              <FormControl variant="outlined" className={classes.formControl}>
                <Select
                  value={sort}
                  onChange={(e) => handleSortChange(e.target.value)}
                  className={classes.select}
                  name="filter"
                  classes={{ outlined: classes.customSelect }}
                >
                  {sortOptions.map((sortOption, key) => (
                    <MenuItem key={key} value={sortOption.value}>
                      {sortOption.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Hidden>
          </Grid>
        )} */}

        <Grid item>
          <Button
            variant="contained"
            color="primary"
            className={classes.btn}
            onClick={onActionButton} //onActionButton
          >
            {buttonName}
          </Button>
          {/* <div className={classes.mobViewAddBtn}>
            <Tooltip arrow title={buttonName} placement="top">
              <Button
                variant="contained"
                color="primary"
                onClick={console.log("Add btn")} //onActionButton
              >
                <AddIcon htmlColor="#45149B" />
              </Button>
            </Tooltip>
          </div> */}
        </Grid>
      </Grid>
    </div>
  );
};
