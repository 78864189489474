import { Box, Grid, makeStyles, Paper } from "@material-ui/core";
import copy from "fast-copy";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { actions } from "surveillance-binder";
import { SubHeader } from "../../components/common/subHeader2";
import { AlertContext } from "../../contexts";
import { Routes } from "../../router/routes";
import { AlertProps } from "../../utils";
import VerticalLinearStepper from "./stepper";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#ffffff",
    padding: "8px 16px",
    height: "100%",
    // borderRadius: "8px",
    boxSizing: " border-box",
  },
  content: {
    width: "100%",
    height: "calc(100% - 53px)",
    overflowY: "auto",
    backgroundColor: "#ffffff",
  },
  container: {
    zIndex: 1,
    position: "relative",
  },
}));
export const AddEditClient = (props) => {
  const classes = useStyles();
  const history = useHistory();
  // const dispatch = useDispatch();
  const { isEdit = false, editData = {}, setClientEdit = () => false } = props;
  const [clientId, setClientId] = React.useState(isEdit ? editData?._id : "");
  const intialState = {
    ...(isEdit && { _key: editData?._key ?? "" }),
    client_name: isEdit ? editData?.client_name : "",
    client_type: isEdit ? editData?.client_type : "",
    contact_details: isEdit
      ? copy(editData?.contact_details)
      : [
          {
            contact_mode: "",
            contactby: "",
            work: "",
            priority: "1",
          },
        ],
    address: isEdit ? editData?.address : [],
  };

  const [state, setState] = React.useState(intialState);

  const updateState = (key, value) => {
    setState((pre) => ({ ...pre, [key]: value }));
  };
  const onBackBtnClicked = () => {
    history.push(Routes.Client);
  };

  React.useEffect(() => {
    // if (isEdit) {
    if (editData) {
      setState(editData);
      setClientId(editData?._id ?? "");

      //   getMappingDisease(editData?._id);
    }
    // }
    //eslint-disable-next-line
  }, [editData]);

  //   const getMappingDisease = async (clientId) => {
  //     try {
  //       debugger;
  //       if (clientId) {
  //         let res = await dispatch(
  //           actions.GET_MAPPED_DISEASE_WITH_CLIENT({ id: clientId })
  //         );
  //         console.log(res);
  //       }
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };
  //   console.log(editData);
  return (
    <>
      <Paper className={classes.root}>
        <SubHeader
          //   searchField={false}
          title={
            isEdit
              ? editData?.client_name?.toUpperCase() ?? "Update Client"
              : "Create New Client"
          }
          hideBackBtn={false}
          backToToolip={"Back To Client"}
          onBackBtnClicked={onBackBtnClicked}
          hideSearch={true}
          hideHeaderBtn={true}
        />
        <Box className={classes.content}>
          <VerticalLinearStepper
            isEdit={isEdit}
            state={state}
            updateState={updateState}
            clientId={clientId}
            setClientId={setClientId}
            editData={editData}
            setClientEdit={setClientEdit}
            // mappedDisease={mappedDisease}
            onBackBtnClicked={onBackBtnClicked}
            // getMappingDisease={getMappingDisease}
            // upsertUpdateClient={upsertUpdateClient}
          />
        </Box>
      </Paper>
    </>
  );
};
