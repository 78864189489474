import * as React from "react";
import PropTypes from "prop-types";

const StackOverflowIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={14.299} {...props}>
    <path
      d="M6.745.005a.6.6 0 0 0-.4.215.547.547 0 0 0 .109.8l6.096 4.425a.62.62 0 0 0 .366.118.614.614 0 0 0 .477-.221.547.547 0 0 0-.109-.8L7.189.117a.624.624 0 0 0-.444-.112ZM4.991 2.898a.611.611 0 0 0-.577.313.552.552 0 0 0 .269.76l6.955 3.281a.618.618 0 0 0 .267.059.605.605 0 0 0 .537-.313.552.552 0 0 0-.269-.76L5.219 2.953a.616.616 0 0 0-.228-.055ZM3.716 6.78a.584.584 0 0 0-.47.441.569.569 0 0 0 .467.67l7.628 1.479a.656.656 0 0 0 .12.012.6.6 0 0 0 .587-.453.568.568 0 0 0-.467-.669L3.954 6.78a.639.639 0 0 0-.238 0ZM.6 7.342a.584.584 0 0 0-.6.567v5.823a.584.584 0 0 0 .6.567h14.8a.584.584 0 0 0 .6-.567V7.909a.6.6 0 0 0-1.2 0v4.257H1.2V7.909a.584.584 0 0 0-.6-.567Zm3.116 2.912a.568.568 0 1 0 0 1.133H11.5a.568.568 0 1 0 0-1.133Z"
      fill={props.color}
    />
  </svg>
);

export default StackOverflowIcon;

StackOverflowIcon.defaultProps = {
  color: "#2a3c50",
};

StackOverflowIcon.propTypes = {
  color: PropTypes.string,
};
