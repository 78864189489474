/**
 * @author AUTHOR_NAME
 * @email AUTHOR_EMAIL
 * @create date
 * @modify date
 * @desc Providing the AuthContext from /src/context which is used in the /src/App.js.
 */

import React from "react";
import { LinearProgress } from "@material-ui/core";
import { NetworkCall } from "./networkcall";
import { initialMessageCatlog } from "atp-multilingual";
import { AuthContext } from "./contexts"
import { PinDropSharp } from "@material-ui/icons";
import { AlertProps } from "./utils";
import {
  LocalStorageKeys,
  NetWorkCallMethods,
  refreshCacheAndReload,
  semverGreaterThan,
} from "./utils";

class AppAuth extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user:[],
      setAuth: () => null
      
    };
  }

  componentDidMount() {
    this.checkForLatestBuild();
    this.refreshAPI();
  }

  refreshAPI = () => {};

  checkForLatestBuild = () => {
    NetworkCall(
      `${window.location.protocol}//${window.location.hostname}${
        window.location.port ? ":" + window.location.port : ""
      }/meta.json`,
      NetWorkCallMethods.get,
      null,
      null,
      false,
      true
    )
      .then((_) => {
        const isVersion = semverGreaterThan(
          _.data.version,
          localStorage.getItem(LocalStorageKeys.version)
        );
        localStorage.setItem(LocalStorageKeys.version, _.data.version);
        if (isVersion) {
          refreshCacheAndReload();
        }
      })
      .catch((err) => {
        console.log("err:", err);
      });
  };

  set = (props) => {
  this.setState({user:props})
  }
  render() {
    

    return (
      <>
       <AuthContext.Provider value={{
        user:this.state.user,
        setAuth: this.set,
      
        }}>
       {this.props.children}
       </AuthContext.Provider>
      </>
    );
  }
}

export default AppAuth;
