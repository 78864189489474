import * as React from "react";
import PropTypes from "prop-types";

const CartIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fontSize={props.fontSize}
    width={props.width ?? "40px"}
    height={props.height ?? "40px"}
    viewBox="0 0 60 60"
    fill="none"
  >
    <path
      d="M17.8571 48C14.5833 48 11.9345 50.7 11.9345 54C11.9345 57.3 14.5833 60 17.8571 60C21.131 60 23.8095 57.3 23.8095 54C23.8095 50.7 21.131 48 17.8571 48ZM47.619 48C44.3452 48 41.6964 50.7 41.6964 54C41.6964 57.3 44.3452 60 47.619 60C50.8929 60 53.5714 57.3 53.5714 54C53.5714 50.7 50.8929 48 47.619 48ZM18.3631 38.25L18.4524 37.89L21.131 33H43.3036C45.5357 33 47.5 31.77 48.5119 29.91L60 8.88L54.8214 6H54.7917L51.5179 12L43.3036 27H22.4107L22.0238 26.19L15.3571 12L12.5298 6L9.73214 0H0V6H5.95238L16.6667 28.77L12.6488 36.12C12.1726 36.96 11.9048 37.95 11.9048 39C11.9048 42.3 14.5833 45 17.8571 45H53.5714V39H19.1071C18.7202 39 18.3631 38.67 18.3631 38.25Z"
      fill="#007AFF"
    />
  </svg>
);

export default CartIcon;

CartIcon.defaultProps = {
  color: "#2a60bc",
};

CartIcon.propTypes = {
  color: PropTypes.string,
};
