import React from 'react'
import { SvgIcon, } from '@material-ui/core'

export function Levelofcare(props) {

    return (
        <SvgIcon viewBox="0 0 9.62 14.921" style={{ fontSize: '1rem', marginRight: "8px" }}>
            <path d="M35.52,38a1.3,1.3,0,0,0-1.267,1.334v.292H32.62A1.661,1.661,0,0,0,31,41.311v1.46a.276.276,0,0,0,.274.269h.206v4.9a1.652,1.652,0,0,0,1.608,1.687h7.825A1.652,1.652,0,0,0,42.52,47.94v-4.9h.206A.276.276,0,0,0,43,42.771v-1.46a1.661,1.661,0,0,0-1.62-1.684H39.56v-.292A1.3,1.3,0,0,0,38.3,38Zm0,.533H38.3a.769.769,0,0,1,.732.8v.292h-4.24v-.292A.77.77,0,0,1,35.52,38.533Zm-2.9,1.627h8.76a1.127,1.127,0,0,1,1.087,1.151v1.2H31.533v-1.2A1.127,1.127,0,0,1,32.62,40.16Zm-.607,2.88h9.973v4.9a1.119,1.119,0,0,1-1.074,1.153H33.088a1.119,1.119,0,0,1-1.074-1.153Zm4.427.773a.267.267,0,0,0-.267.267v.96h-.96a.267.267,0,0,0-.267.267v1.04a.267.267,0,0,0,.267.267h.96V47.6a.267.267,0,0,0,.267.267h1.04a.267.267,0,0,0,.267-.267v-.987h.987A.267.267,0,0,0,39,46.347v-1.04a.267.267,0,0,0-.267-.267h-.987v-.96a.267.267,0,0,0-.267-.267Zm.48,1.855.2.3.1-.3v-.095c0,.147.267,0,.267,0h-.442v.507h-.6l.857.267c-.147,0-.084-.147-.084,0l-.393-.381-.114.238v.143c0-.147.147-.143,0-.143l.507.143-.507-.143.507-.124c.147,0-.293-.359-.293-.507Z" transform="translate(-31 -38)" fill="#4448C2" />

        </SvgIcon>
    )
}