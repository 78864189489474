import * as React from "react";
import PropTypes from "prop-types";

const Pills = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="12.376" height="12.128" viewBox="0 0 12.376 12.128">
  <path id="icons8-pills" d="M11.347,4.785A3.556,3.556,0,0,0,8.825,5.828l-5,5A3.567,3.567,0,0,0,8.872,15.87l.888-.888a3.139,3.139,0,0,1,.664-3.542L7.488,8.5,9.495,6.5A2.62,2.62,0,1,1,13.2,10.2l-.289.29a.462.462,0,0,0-.042.052,3.133,3.133,0,0,1,1.056.266,3.563,3.563,0,0,0-2.578-6.026Zm1.288,6.379a2.525,2.525,0,1,0,2.525,2.525A2.528,2.528,0,0,0,12.636,11.165Zm.789,1.262a.473.473,0,0,1,.335.808l-1.578,1.578a.473.473,0,0,1-.669-.669l1.578-1.578A.472.472,0,0,1,13.424,12.427Z" transform="translate(-2.785 -4.785)" 
  fill={props?.color}/>
</svg>
);

export default Pills;

Pills.defaultProps = {
  color: "black",
};

Pills.propTypes = {
  color: PropTypes.string,
};