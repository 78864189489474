import * as React from "react";
import PropTypes from "prop-types";

const SunIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15.959"
    height="15.968"
    viewBox="0 0 15.959 15.968"
  >
    <path
      id="icons8-sun"
      d="M11.97,3.978a.6.6,0,0,0-.589.607v1.2a.6.6,0,1,0,1.2,0v-1.2a.6.6,0,0,0-.608-.607ZM6.754,6.143a.6.6,0,0,0-.417,1.028l.846.846A.6.6,0,1,0,8.03,7.17l-.846-.846A.6.6,0,0,0,6.754,6.143Zm10.433,0a.6.6,0,0,0-.411.181l-.846.846a.6.6,0,0,0,.847.847l.846-.846a.6.6,0,0,0-.436-1.028ZM11.979,7.578a4.389,4.389,0,1,0,4.389,4.389A4.4,4.4,0,0,0,11.979,7.578Zm0,1.2a3.192,3.192,0,1,1-3.192,3.192A3.183,3.183,0,0,1,11.979,8.775ZM4.6,11.368a.6.6,0,1,0,0,1.2H5.8a.6.6,0,1,0,0-1.2Zm13.565,0a.6.6,0,1,0,0,1.2h1.2a.6.6,0,1,0,0-1.2ZM7.595,15.735a.6.6,0,0,0-.411.181l-.846.846a.6.6,0,1,0,.847.847l.846-.846a.6.6,0,0,0-.436-1.028Zm8.751,0a.6.6,0,0,0-.417,1.028l.846.846a.6.6,0,1,0,.847-.847l-.846-.846A.6.6,0,0,0,16.346,15.735ZM11.97,17.544a.6.6,0,0,0-.589.607v1.2a.6.6,0,1,0,1.2,0v-1.2a.6.6,0,0,0-.608-.607Z"
      transform="translate(-4 -3.978)"
      fill={props.color}
    />
  </svg>
);

export default SunIcon;

SunIcon.defaultProps = {
  color: "#fff",
};

SunIcon.propTypes = {
  color: PropTypes.string,
};
