import React from 'react'
import { makeStyles, SvgIcon } from '@material-ui/core'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
    cls1: (props) => ({
        fill: props.isSelected ? theme.palette.text.primary : props?.color ?? '#a5a5a5'
    })
}))

export function Archived(props) {
    const classes = useStyles(props)
    return (
        <SvgIcon viewBox="0 0 12.205 13.561" fontSize="inherit">
            <path className={classes.cls1} d="M4.712,2A1.366,1.366,0,0,0,3.356,3.356v.678H2.678a.678.678,0,1,0,0,1.356h.678V6.746H2.678a.678.678,0,0,0,0,1.356h.678V9.458H2.678a.678.678,0,0,0,0,1.356h.678v1.356H2.678a.678.678,0,0,0,0,1.356h.678V14.2a1.366,1.366,0,0,0,1.356,1.356h8.136A1.366,1.366,0,0,0,14.2,14.2V3.356A1.366,1.366,0,0,0,12.849,2Zm0,1.356h8.136V14.2H4.712ZM6.068,5.39V6.746h5.424V5.39Z" transform="translate(-2 -2)"/>
        </SvgIcon>
    )
}

Archived.propTypes = {
    color: PropTypes.string
}