import * as React from "react";
import PropTypes from "prop-types";

const DownwardIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={10} height={6} {...props}>
    <path
      d="M5 3.69 1.607.277a.938.938 0 0 0-1.332 0 .952.952 0 0 0 0 1.34l4.097 4.121a.883.883 0 0 0 1.255 0l4.1-4.121a.952.952 0 0 0 0-1.34.938.938 0 0 0-1.332 0Z"
      fill={props.color}
    />
  </svg>
);

export default DownwardIcon;

DownwardIcon.defaultProps = {
  color: "#2A3C50",
};

DownwardIcon.propTypes = {
  color: PropTypes.string,
};
