import React, { useEffect, useState } from "react";
import {
  Grid,
  Card,
  FormControl,
  MenuItem,
  Select,
  Typography,
  Divider,
} from "@material-ui/core";
import { AxiosCall } from "../../utils";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  mainCard: {
    display: "flex",
    height: "28rem",
    //width: "37rem",
    margin: "0.8rem",
  },
  mainGrid: {
    display: "flex",
    flexGrow: 1,
  },
}))

function Diagnosis(props) {
  console.log("inProps", props);
  const [gender, setGender] = useState("Male");
  const [data, setData] = useState({});
  const [patientID, setPatientID] = useState(props.patientId);
  const [clientId, setClientId] = useState(props.clientId);

  //   console.log("REACT_APP_GETPATIENT", process.env.REACT_APP_GETPATIENT_API);
  const classes = useStyles();
  useEffect(() => {
    async function getPateintDetails() {
      let params = {
        db_name: "SurveillancePlatform",
        entity: "patients",
        patientid: `${patientID}`,
        clientid: `${clientId}`,
      };
      let res = await AxiosCall(
        "post",
        " https://registry.ainqaplatform.in/getpatienttransaction",
        params
      );

      console.log(res, "reseeew");
      setGender(res.gender);

      let newObj = {};
      Object.keys(res).forEach(function (key) {
        let newKey = key.replace(/\s/g, ""); // remove spaces from the key
        newObj[newKey] = res[key]; // assign the value to the new key
      });

      setData(newObj);
    }

    getPateintDetails();
  }, []);

  //   console.log("data23", data);

  return (


      <Card
        className={classes.mainCard}
      >
          <Grid container xs={12} spacing={2} className={classes.mainGrid}>
            <Typography style={{ fontSize: "22px", padding: "20px" }}>
              My Diagnosis
            </Typography>
            {Object.keys(data).length !== 0 ? (
            <Grid container spacing={2} className={classes.detailGrid}>
              <Grid item xs={8}>
                <div style={{ paddingTop: "40px" }}>
                  {gender === "Male" ? (
                    <>
                      <img
                        style={{ height: "270px", width: "200px" }}
                        src="/images/human-body-frontal.jpg"
                        alt="human-body-frontal"
                      />
                      <img
                        style={{ height: "270px", width: "200px" }}
                        src="/images/human-body.jpg"
                        alt="human-body"
                      />
                    </>
                  ) : (
                    <>
                      <img
                        style={{
                          paddingLeft: "20px",
                          height: "270px",
                          width: "300px",
                        }}
                        src="/images/female2.jpg"
                        alt="female-body"
                      />
                    </>
                  )}
                </div>
              </Grid>
              <Grid item xs={4}>
                <div
                  style={{
                    height: "400px",
                    paddingRight: "50px",
                    overflow: "auto",
                    //width: "170px",
                  }}
                >
                  {/* <div>
              <Typography style={{ fontSize: "16px", marginTop: "10px" }}>
                Investigation
              </Typography>
              <Typography style={{ fontSize: "13px", color: "#9D9999" }}>
                {"---"}
              </Typography>
            </div> */}
                  <div>
                    <Typography style={{ fontSize: "16px", marginTop: "10px" }}>
                      Diagnosis
                    </Typography>
                    <Typography style={{ fontSize: "13px", color: "#9D9999" }}>
                      {data.Diagnosis}
                    </Typography>
                  </div>
                  <div>
                    <Typography style={{ fontSize: "16px", marginTop: "10px" }}>
                      Treatment
                    </Typography>
                    <Typography
                      style={{
                        fontSize: "13px",
                        color: "#9D9999",
                        whiteSpace: "initial",
                      }}
                    >
                      {data.Treatment}
                    </Typography>
                  </div>
                  <div>
                    <Typography style={{ fontSize: "16px", marginTop: "10px" }}>
                      Medication
                    </Typography>
                    <Typography style={{ fontSize: "13px", color: "#9D9999" }}>
                      {data.MedicationOrder}
                    </Typography>
                  </div>
                  <div>
                    <Typography style={{ fontSize: "16px", marginTop: "10px" }}>
                      Vital
                    </Typography>
                    <Typography style={{ fontSize: "13px", color: "#9D9999" }}>
                      {data.Vital}
                    </Typography>
                  </div>
                  <div>
                    <Typography style={{ fontSize: "16px", marginTop: "10px" }}>
                      Lab Order
                    </Typography>
                    <Typography style={{ fontSize: "13px", color: "#9D9999" }}>
                      {data.LabOrder}
                    </Typography>
                  </div>
                  <div>
                    <Typography style={{ fontSize: "16px", marginTop: "10px" }}>
                      Radiology order
                    </Typography>
                    <Typography style={{ fontSize: "13px", color: "#9D9999" }}>
                      {data.Radiologyorder}
                    </Typography>
                  </div>
                </div>
              </Grid>
            </Grid>)
            :
            (<Grid container spacing={2} className={classes.detailGrid}>
                <Typography
                  style={{
                    fontSize: "13px",
                    color: "#9D9999",
                    paddingTop: "150px",
                    paddingLeft: "200px",
                  }}
                >
                  Diagnosis not yet started for this patient
                </Typography>
              </Grid>
              )}
          </Grid>
      </Card>

  
  );
}

export default Diagnosis;
