/**
 * @author Kameshwaran Murugan
 * @email kamesh@qdmplatforms.com
 * @create date 2021-01-28
 * @modify date 2021-08-30
 * @desc Component To Render
 */

import React from 'react';
import { FormComponents } from "../../utils";
import {
    TabSelect,
    Select,
    Input,
    // DateTimePickers,
    CustionAddText,
    Notify,
    CheckBox,
    RadioButton,
    Location,
    CustomTableFormRender,
    CustomTableFixedRowFormRender,
    Mobile,
    LocationLevel,
    DOB,
    UploadReports,
    ImageWithCheckBox,
    ImageUpload,
    DatePicker,
    TimePicker,
    DateTimePicker
} from "../../components";

export const ComponentToRender = ({
    index,
    data,
    value,
    isReadyOnly = false,
    onChange = () => false,
    isrequired,
    errorValidation,
    disabled,
    GetOptions
}) => {
    const [valuess, setValuess] = React.useState({});

    React.useEffect(async () => {
        if (GetOptions != undefined) {
            
            const data = await GetOptions
            setValuess(data)
        }

    }, [GetOptions])

    switch (data.ui_component) {
        case FormComponents.single_tab_select:
            return <TabSelect
                id={data?.id ?? index}
                label={data?.question}
                value={[value]}
                items={data?.question_options?.map(_ => _) ?? []}
                onClick={(value) => onChange(index, data, value)}
                isReadyOnly={isReadyOnly}
                isrequired={isrequired}
                errorValidation={errorValidation ?? {}}
                disabled={disabled ?? false}
            />;

        case FormComponents.multi_tab_select:
            return <TabSelect
                id={data?.id ?? index}
                label={data?.question}
                value={value}
                items={data?.question_options?.map(_ => _) ?? []}
                onClick={(value) => onChange(index, data, value)}
                multiple={true}
                isReadyOnly={isReadyOnly}
                isrequired={isrequired}
                errorValidation={errorValidation ?? {}}
                disabled={disabled ?? false}
            />

        case FormComponents.multi_select_tag:

            let allValues = value ? value.map(_ => _.value) : []

            return <Select
                id={data?.id ?? index}
                label={data?.question}
                multiple
                value={value ? value : []}
                handleselect={(value) => onChange(index, data, value)}
                option={data?.question_options?.map(_ => {
                    return {
                        value: _,
                        label: _
                    }
                }).filter(_ => allValues.indexOf(_.value) === -1)}
                isReadonly={isReadyOnly}
                isrequired={isrequired}
                errorValidation={errorValidation ?? {}}
                disabled={disabled ?? false}
            />
        case FormComponents.select_modal_dropdown_qdm:
            return <Select
                id={data?.id ?? index}
                label={data?.question}
                value={value ? [value] : undefined}
                handleselect={(value) => onChange(index, data, value)}
                option={value != undefined ? valuess : []}
                isReadonly={isReadyOnly}
                isrequired={isrequired}
                errorValidation={errorValidation ?? {}}
                disabled={disabled ?? false}
            />
        case FormComponents.select_modal_dropdown:
            return <Select
                id={data?.id ?? index}
                label={data?.question}
                value={value ? [value] : []}
                //handleselect={(value) => onChange(index, data, value)}
                option={data?.question_options?.map(_ => {
                    return {
                        value: _,
                        label: _
                    }
                })}
                isReadonly={isReadyOnly}
                isrequired={isrequired}
                errorValidation={errorValidation ?? {}}
                disabled={disabled ?? false}
            />

        case FormComponents.input_number:
            return <Input
                id={data?.id ?? index}
                type={"number"}
                value={value}
                onChange={(value) => onChange(index, data, value)}
                label={data?.question}
                isReadonly={isReadyOnly}
                isrequired={isrequired}
                data={data}
                //validation use
                decimalNo={data?.validation?.decimalNo}

                errorValidation={errorValidation ?? {}}
                disabled={disabled ?? false}
            />

        case FormComponents.input_text:
            return <Input
                id={data?.id ?? index}
                value={value}
                onChange={(value) => onChange(index, data, value)}
                label={data?.question}
                isReadonly={isReadyOnly}
                isrequired={isrequired}
                data={data}
                errorValidation={errorValidation ?? {}}
                disabled={disabled ?? false}
            />

        case FormComponents.input_textarea:
            return <Input
                id={data?.id ?? index}
                value={value}
                onChange={(value) => onChange(index, data, value)}
                multiline
                label={data?.question}
                isReadonly={isReadyOnly}
                isrequired={isrequired}
                data={data}
                errorValidation={errorValidation ?? {}}
                disabled={disabled ?? false}
            />

        case FormComponents.input_date_picker:
            return <Input
                id={data?.id ?? index}
                type={"date"}
                value={disabled ? "" : value}
                onChange={(value) => onChange(index, data, value)}
                label={data?.question}
                isReadonly={isReadyOnly}
                isrequired={isrequired ?? false}
                data={data}
                errorValidation={errorValidation ?? {}}
                disabled={disabled ?? false}
            />
        // return <DateTimePickers
        //     id={data?.id ?? index}
        //     multiline
        //     value={value?.length > 0 ? value : data?.validation?.currentDate}
        //     onChange={(value) => onChange(index, data, value)}
        //     label={data?.question}
        //     isReadonly={isReadyOnly}
        //     isrequired={isrequired}
        //     data={data}
        //     errorValidation={errorValidation ?? {}}
        //     disabled={disabled ? disabled : data?.validation?.currentDate ?? false}
        // />
        case FormComponents.input_time_picker:
            return <Input
                id={data?.id ?? index}
                type={"time"}
                value={value}
                onChange={(value) => onChange(index, data, value)}
                label={data?.question}
                isReadonly={isReadyOnly}
                isrequired={isrequired}
                data={data}
                errorValidation={errorValidation ?? {}}
                disabled={disabled ?? false}
            />
        case FormComponents.input_datetime_picker:
            return <Input
                id={data?.id ?? index}
                type={"datetime-local"}
                value={disabled ? "" : value}
                onChange={(value) => onChange(index, data, value)}
                label={data?.question}
                isReadonly={isReadyOnly}
                isrequired={isrequired ?? false}
                data={data}
                errorValidation={errorValidation ?? {}}
                disabled={disabled ?? false}
            />

        case FormComponents.custom_multi_select_tag:
            return <CustionAddText
                id={data?.id ?? index}
                label={data?.question}
                options={value ? value : []}
                addTag={(value) => onChange(index, data, value)}
                isReadonly={isReadyOnly}
                isrequired={isrequired}
                errorValidation={errorValidation ?? {}}
                disabled={disabled ?? false}
            />

        case FormComponents.label:
            return <Notify
                id={data?.id ?? index}
                text={data?.question}
            />

        case FormComponents.note:
            return <Notify
                id={data?.id ?? index}
                text={data?.question}
                notes
            />

        case FormComponents.popup_switch:
            return <TabSelect
                id={data?.id ?? index}
                label={data?.question}
                value={[value]}
                items={["Yes", "No"]}
                onClick={(value) => onChange(index, data, value)}
                isrequired={isrequired}
                errorValidation={errorValidation ?? {}}
                disabled={disabled ?? false}

            />;

        case FormComponents.check_box:
            return <CheckBox
                id={data?.id ?? index}
                heading={data?.question}
                option={data?.question_options?.map(_ => {
                    return {
                        value: _,
                        label: _
                    }
                })}
                direction="row"
                checked={value?.length > 0 ? value : []}
                handleChange={(value) => onChange(index, data, value)}
                isReadonly={isReadyOnly}
                isrequired={isrequired}
                errorValidation={errorValidation ?? {}}
                disabled={disabled ?? false}

            />
        case FormComponents.radio_button:
            return <RadioButton
                id={data?.id ?? index}
                heading={data?.question}
                option={data?.question_options?.map(_ => {
                    return {
                        value: _,
                        label: _
                    }
                })}
                direction="row"
                value={value}
                handleChange={(value) => onChange(index, data, value)}
                isReadonly={isReadyOnly}
                isrequired={isrequired}
                errorValidation={errorValidation ?? {}}
                disabled={disabled ?? false}
            />
        case FormComponents.location:
            return <Location
                id={data?.id ?? index}
                value={value}
                label={data?.question}
                handleChange={(value) => onChange(index, data, value)}
                isReadonly={isReadyOnly}
                isrequired={isrequired}
                errorValidation={errorValidation ?? {}}
                disabled={disabled ?? false}
            />
        case FormComponents.custom_table:

            return <CustomTableFormRender
                id={data?.id ?? index}
                heading={data?.question}
                data={data}
                handleChange={(value) => onChange(index, data, value)}
                tableData={value?.tableData?.length === 0 ? [] : value?.tableData}
                isReadonly={isReadyOnly}
                isrequired={isrequired}
                errorValidation={errorValidation ?? []}
                disabled={disabled ?? false}
            />
        case FormComponents.custom_table_2:
            return <CustomTableFixedRowFormRender
                id={data?.id ?? index}
                heading={data?.question}
                data={data}
                handleChange={(value) => onChange(index, data, value)}
                tableData={value?.tableData?.length === 0 ? [] : value?.tableData}
                isReadonly={isReadyOnly}
                isrequired={isrequired}
                errorValidation={errorValidation ?? []}
                disabled={disabled ?? false}
            />
        case FormComponents.mobile_no:
            return <Mobile
                id={data?.id ?? index}
                value={value}
                label={data?.question}
                handleChange={(value) => onChange(index, data, value)}
                type={"number"}
                isReadonly={isReadyOnly}
                isrequired={isrequired}
                data={data}
                errorValidation={errorValidation ?? {}}
                disabled={disabled ?? false}
            />

        case FormComponents.location_level:
            return <LocationLevel
                id={data?.id ?? index}
                label={data?.question}
                value={value}
                handleChange={(value) => onChange(index, data, value)}
                isReadonly={isReadyOnly}
                isrequired={isrequired}
                errorValidation={errorValidation ?? {}}
                disabled={disabled ?? false}
            />
        case FormComponents.date_of_birth:
            return <DOB
                id={data?.id ?? index}
                value={value}
                onChange={(value) => onChange(index, data, value)}
                label={data?.question}
                isReadonly={isReadyOnly}
                isrequired={isrequired}
                data={data}
                errorValidation={errorValidation ?? {}}
                disabled={disabled ?? false}
            />
        case FormComponents.file_upload:
            return <UploadReports
                id={data?.id ?? index}
                onChange={(value) => onChange(index, data, value)}
                value={value}
                single={false}
                label={data?.question}
                isReadonly={isReadyOnly}
                isrequired={isrequired}
                errorValidation={errorValidation ?? {}}
            // disabled={disabled ?? false}
            />
        case FormComponents.Image_upload:

            return <ImageWithCheckBox
                id={data?.id ?? index}
                heading={data?.question}
                image64={data?.file}
                option={data?.question_options?.map(_ => {
                    return {
                        value: _,
                        label: _
                    }
                })}
                direction="row"
                checked={value?.length > 0 ? value : []}
                handleChange={(value) => onChange(index, data, value)}
                isReadonly={isReadyOnly}
                isrequired={isrequired}
                errorValidation={errorValidation ?? {}}
                disabled={disabled ?? false}

            />
        case FormComponents.Image_only_upload:

            return <ImageUpload
                id={data?.id ?? index}
                heading={data?.question}
                image64={data?.file}
                isrequired={isrequired}
                errorValidation={errorValidation ?? {}}
            // disabled={disabled ?? false}
            />
        default:
            return <></>;
    }
}

