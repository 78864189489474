import * as React from "react";
import PropTypes from "prop-types";

const HoldingHandWithPillIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16.037"
    height="13.485"
    {...props}
  >
    <path
      data-name="icons8-hand-with-a-pill (1)"
      d="M15.077 5.1a4.535 4.535 0 0 0-1.657.7c-.723.375-1.391.732-1.816.992a2.18 2.18 0 0 1-2.128 2.682H6.743a.547.547 0 0 1 0-1.093h2.734a1.089 1.089 0 0 0 .718-1.912 1.626 1.626 0 0 0-.9-.275H7.441a7.953 7.953 0 0 1-1.828-.2 5.323 5.323 0 0 0-1.3-.158c-2.672 0-4.2 3.281-4.261 3.42a.546.546 0 0 0 .058.551l2.551 3.46a.544.544 0 0 0 .439.221.548.548 0 0 0 .53-.408 1.373 1.373 0 0 1 1.29-1.051 17.752 17.752 0 0 1 2.159.18 17.977 17.977 0 0 0 2.215.184c1.007 0 1.732-.772 3.827-2.916 2.661-2.723 2.916-2.961 2.916-3.5a.917.917 0 0 0-.96-.873M5.831 0h2.551v3.645H5.831a1.822 1.822 0 1 1 0-3.645Zm8.019 1.822a1.823 1.823 0 0 1-1.822 1.822H9.476V0h2.551a1.823 1.823 0 0 1 1.823 1.822Z"
      fill={props.color}
    />
  </svg>
);

export default HoldingHandWithPillIcon;

HoldingHandWithPillIcon.defaultProps = {
  color: "#2a3c50",
};

HoldingHandWithPillIcon.propTypes = {
  color: PropTypes.string,
};
