import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import './a4.css';
import jsPDF from 'jspdf';
import { actions } from "surveillance-binder";
import { connect } from "react-redux";
import copy from "fast-copy";
export class AnnexForm extends Component {
    constructor(props) {
        super(props)

        this.state = {
            listData: [],
            list: [],
            nameInput: [],
            nameInput2: [],
            accoumpanyNameInput: [],
            dataList: [],
            accoumpanyNameInput2: [],
            identityCardInput: [],
            hospitalNumInput: [],
            // dateAdmissionInput:[],
            raceEthinicInput: [],
            subEthinicInput: [],
            originInput: [],
            telephoneInput: [],
            currentAddressInput: [],
            currentAddressInput1: [],
            currentAddressInput2: [],
            secondaryAddressInput: [],
            secondaryAddressInput1: [],
            // list[""] = nameOfMedicalPractitioner[0].answers[0].answer.answer;
            nameOfMedicalPractitionerInput: [],

            medicalPractitionerInput: [],
            nameAndAddressOfHospitalInput: [],
            nameAndAddressOfHospitalInput1: [],
            ageInput: [],
            checkboxstate: {
                fields: "",
                citizenShip: "",
                Gender: "",
                Contact_Mode: "",
                Case_Detection_Classfication: "",
                Patient_Status: "",
                Laboratory_investigation: "",
                Diagnosis_Status: "",
                Entry: ""
            }



        }
    }


    componentDidMount() {
        this.getannexdata();
    }







    getannexdata = async () => {
        let dat = await copy(this.props.getData.data[0]);
        const state = this.state;
        this.setState({ ...state, dataList: dat })

        var list = [];
        // const fullName = (this.state?.dataList?.questions?.filter(el => el.question_name === "Full Name") || this.state.dataList.filter(el => el.question_name === "Name"));
        const accompanyName = this.state?.dataList?.questions?.filter(el => el.question_name === "Accompany by(Mother/Father/Guardian)");

        const identityCard = this.state?.dataList?.questions?.filter(el => el.question_name === "Identity Card Number");
        const field = this.state?.dataList?.questions?.filter(el => el.question_name === "field");
        const hospitalNumber = this.state?.dataList?.questions?.filter(el => el.question_name === "Hospital/Clinic Registration Number");
        const ward = this.state?.dataList?.questions?.filter(el => el.question_name === "Ward");
        const dateAdmission = this.state?.dataList?.questions?.filter(el => el.question_name === "Date Of Admission");
        const citizen = this.state?.dataList?.questions?.filter(el => el.question_name === "Citizenship");
        const raceEthnic = this.state?.dataList?.questions?.filter(el => el.question_name === "Race/Ethnic");
        const countryOrigin = this.state?.dataList?.questions?.filter(el => el.question_name === "Country Of Origin");
        const subethnic = this.state?.dataList?.questions?.filter(el => el.question_name === "Sub Ethnic");
        const entry = this.state?.dataList?.questions?.filter(el => el.question_name === "Entry");
        
        const occupation = this.state?.dataList?.questions?.filter(el => el.question_name === "Occupation");
        const telephoneNo = this.state?.dataList?.mobile.number;
        const currentAddress = this.state?.dataList?.questions?.filter(el => el.question_name === "location");
        const addressoOfEmployer = this.state?.dataList?.questions?.filter(el => el.question_name === "Address of Employer/school/college/university");
        
        const caseDetection = this.state?.dataList?.questions?.filter(el => el.question_name === "Case detection classification");
        const statusOfPatient = this.state?.dataList?.questions?.filter(el => el.question_name === "Status of patient");
        const dateOfOnset = this.state?.dataList?.questions?.filter(el => el.question_name === "Date of onset");
        const laboratoryInvestigationResult = this.state?.dataList?.questions?.filter(el => el.question_name === "Laboratory Investigation Result");
        const diagnosisStatus = this.state?.dataList?.questions?.filter(el => el.question_name === "Diagnosis Status");
        const dateOfDiagnosis = this.state?.dataList?.questions?.filter(el => el.question_name === "Date of Diagnosis");
        const relevantClinicalInformation = this.state?.dataList?.questions?.filter(el => el.question_name === "Relevant Clinical Information");
        const Comment = this.state?.dataList?.questions?.filter(el => el.question_name === "Comment");
        const nameOfMedicalPractitioner = this.state?.dataList?.questions?.filter(el => el.question_name === "Name of Medical Practitioner");
     
        const nameAndAddressOfHospital = this.state?.dataList?.questions?.filter(el => el.question_name === "Name and Address of Hospital/clinic");
        const dateOfNotification = this.state?.dataList?.questions?.filter(el => el.question_name === "Date of Notification");
        const contactMode = this.state?.dataList?.questions?.filter(el => el.question_name === "Contact Mode");
        
        const dateOfSpecimen = this.state?.dataList?.questions?.filter(el => el.question_name === "Date of specimen taken:")


        list["fullName"] = this.state?.dataList?.patient_name.first_name + ' ' + this.state?.dataList?.patient_name.middle_name + ' ' + this.state?.dataList?.patient_name.last_name;
        list["accompanyName"] = accompanyName[0].answers[0].answer.answer;
        list["identityCard"] = identityCard[0].answers[0].answer.answer;
        list["field"] = this.state?.dataList?.questions?.filter(el => el.question_name === "field");
        list["hospitalNumber"] = hospitalNumber[0].answers[0].answer.answer;
        list["ward"] = ward[0].answers[0].answer.answer;
        list["dateAdmission"] = dateAdmission[0].answers[0].answer.answer.split("-");
        list["dateOfSpecimen"] = dateOfSpecimen[0].answers[0].answer.answer.split("-");
        list["citizen"] = this.state?.dataList?.questions?.filter(el => el.question_name === "Citizenship");
        list["raceEthnic"] = raceEthnic[0].answers[0].answer.answer;
        list["countryOrigin"] = countryOrigin[0].answers[0].answer.answer;

        list["subethnic"] = subethnic[0].answers[0].answer.answer;
        list["entry"] = this.state?.dataList?.questions?.filter(el => el.question_name === "Entry");
        list["gender"] = this.state?.dataList?.questions?.filter(el => el.question_name === "Gender");
        list["dateOfBirth"] = this.state?.dataList?.dob_age.dob.split("-");
        list["age"] = this.state?.dataList?.dob_age.age.toString();

        list["occupation"] = occupation[0].answers[0].answer.answer;
        list["telephoneNo"] = telephoneNo;
        list["currentAddress"] = currentAddress[0].answers[0].answer.answer;
        list["addressoOfEmployer"] = addressoOfEmployer[0].answers[0].answer.answer;
        list["diseaseDiagnosis"] = this.state?.dataList?.questions?.filter(el => el.question_name === "Disease Diagnosis");
        list["caseDetection"] = this.state?.dataList?.questions?.filter(el => el.question_name === "Case detection classification");
        list["statusOfPatient"] = this.state?.dataList?.questions?.filter(el => el.question_name === "Status of patient");
        list["dateOfOnset"] = dateOfOnset[0].answers[0].answer.answer.split("-");
        list["laboratoryInvestigationResult"] = this.state?.dataList?.questions?.filter(el => el.question_name === "Laboratory Investigation Result");
        list["diagnosisStatus"] = this.state?.dataList?.questions?.filter(el => el.question_name === "Diagnosis Status");
        list["dateOfDiagnosis"] = dateOfDiagnosis[0].answers[0].answer.answer.split("-")
        list["relevantClinicalInformation"] = relevantClinicalInformation[0].answers[0].answer.answer;
        list["Comment"] = Comment[0].answers[0].answer.answer;
        list["nameOfMedicalPractitioner"] = nameOfMedicalPractitioner[0].answers[0].answer.answer;
        list["nameAndAddressOfHospital"] = nameAndAddressOfHospital[0].answers[0].answer.answer;
        list["dateOfNotification"] = dateOfNotification[0].answers[0].answer.answer.split("-");


        this.setState(
            { ...state, list: list })

        let nameInput = [];
        let nameInput2 = [];
        //const accoumpanyName = [];
        let accoumpanyNameInput = [];
        let accoumpanyNameInput2 = [];
        let identityCardInput = [];
        let hospitalNumInput = [];
        // dateAdmissionInput:[],
        let raceEthinicInput = [];
        let subEthinicInput = [];
        let originInput = [];
        let telephoneInput = [];
        let currentAddressInput = [];
        let currentAddressInput1 = [];
        let currentAddressInput2 = [];
        let nameOfMedicalPractitionerInput = [];
        let secondaryAddressInput = [];
        let secondaryAddressInput1 = [];
        let nameAndAddressOfHospitalInput = [];
        let nameAndAddressOfHospitalInput1 = [];
        let ageInput = [];

        for (let j = 0; j <= 31; j++) {
            nameInput.push(
                <td style={{ borderBottom: "0px" }} id="td">{list.fullName[j]}</td>
            )
        }
        for (let j = 32; j < 64; j++) {
            nameInput2.push(
                <td id="td">{list.fullName[j]}</td>
            )
        }


        for (let j = 0; j < 3; j++) {
            ageInput.push(
                <td id="td">{list.age[j]}</td>
            )
        }



        //accompany
        for (let j = 0; j <= 28; j++) {
            accoumpanyNameInput.push(
                <td style={{ borderBottom: "0px" }} id="td">{list.accompanyName[j]}</td>
            )
        }


        for (let j = 29; j <= 57; j++) {
            accoumpanyNameInput2.push(
                <td id="td">{list.accompanyName[j]}</td>
            )
        }



        //identityCard
        for (let j = 0; j < 18; j++) {
            identityCardInput.push(
                <td id="td">{list.identityCard[j]}</td>
            )
        }
        // hospitalNumber
        for (let j = 0; j < list.hospitalNumber.length; j++) {
            hospitalNumInput.push(
                <td id="td">{list.hospitalNumber[j]}</td>
            )
        }

        // raceEthnic
        for (let j = 0; j < 12; j++) {
            raceEthinicInput.push(
                <td id="td">{list.raceEthnic[j]}</td>
            )
        }

        //countryOrigin
        for (let j = 0; j <= 10; j++) {
            originInput.push(
                <td id="td">{list.countryOrigin[j]}</td>
            )
        }
        // telephoneNo
        for (let j = 0; j <= 12; j++) {
            telephoneInput.push(
                <td id="td">{list.telephoneNo[j]}</td>
            )
        }
        // currentAddress
        for (let j = 0; j <= 16; j++) {


            currentAddressInput.push(

                <td style={{ borderBottom: "0px" }} id="td">{list.currentAddress.address[j]}</td>
            )
        }
        for (let j = 17; j <= 37; j++) {
            currentAddressInput1.push(
                <td style={{ borderBottom: "0px" }} id="td">{list.currentAddress.address[j]}</td>
            )
        }
        for (let j = 38; j <= 58; j++) {
            currentAddressInput2.push(
                <td id="td">{list.currentAddress.address[j]}</td>
            )
        }
        // addressoOfEmployer
        for (let j = 0; j <= 17; j++) {
            secondaryAddressInput.push(
                <td style={{ borderBottom: "0px" }} id="td">{list.addressoOfEmployer[j]}</td>
            )
        }
        for (let j = 18; j <= 35; j++) {
            secondaryAddressInput1.push(
                <td id="td">{list.addressoOfEmployer[j]}</td>
            )
        }



        for (let j = 0; j < 12; j++) {
            subEthinicInput.push(
                <td id="td">{list.subethnic[j]}</td>
            )
        }

        for (let j = 0; j < 23; j++) {
            nameOfMedicalPractitionerInput.push(
                <td id="td">{list.nameOfMedicalPractitioner[j]}</td>
            )
        }



        for (let j = 0; j < 22; j++) {
            nameAndAddressOfHospitalInput.push(
                <td id="td">{list.nameAndAddressOfHospital[j]}</td>
            )
        }

        for (let j = 23; j < 45; j++) {
            nameAndAddressOfHospitalInput1.push(
                <td id="td">{list.nameAndAddressOfHospital[j]}</td>
            )
        }

     
        this.setState({
            ...state,
            nameInput: nameInput,
            nameInput2: nameInput2,
            accoumpanyNameInput: accoumpanyNameInput,
            accoumpanyNameInput2: accoumpanyNameInput2,
            identityCardInput: identityCardInput,
            hospitalNumInput: hospitalNumInput,
            // dateAdmissionInput:[],
            raceEthinicInput: raceEthinicInput,
            subEthinicInput: subEthinicInput,
            // const subEthinic = [];
            originInput: originInput,
            telephoneInput: telephoneInput,
            currentAddressInput: currentAddressInput,
            currentAddressInput1: currentAddressInput1,
            currentAddressInput2: currentAddressInput2,
            secondaryAddressInput: secondaryAddressInput,
            secondaryAddressInput1: secondaryAddressInput1,
            nameOfMedicalPractitionerInput: nameOfMedicalPractitionerInput,
            nameAndAddressOfHospitalInput: nameAndAddressOfHospitalInput,
            nameAndAddressOfHospitalInput1: nameAndAddressOfHospitalInput1,
            ageInput: ageInput,

            checkboxstate: {
                fields: field[0]?.answers[0]?.answer?.answer[0],
                citizenShip: citizen[0]?.answers[0]?.answer?.answer[0],
                Gender: this.state.dataList.gender,
                Contact_Mode: contactMode[0]?.answers[0]?.answer?.answer.value,
                Case_Detection_Classfication: caseDetection[0].answers[0].answer.answer[0],
                Patient_Status: statusOfPatient[0]?.answers[0]?.answer?.answer,
                Laboratory_investigation: laboratoryInvestigationResult[0]?.answers[0]?.answer?.answer[0],
                Diagnosis_Status: diagnosisStatus[0]?.answers[0]?.answer?.answer[0],
                Entry: entry[0]?.answers[0]?.answer?.answer[0]
            }
        })
        if (this.props.isValue === 1) {
            this.downloadpdf(this.props.patientname);
            this.props.onDownloadClicked();
        }
        else if (this.props.isValue === 2) {
            var printContents = document.getElementById("pdf").innerHTML;
            // var originalContents = document.body.innerHTML;
            document.body.innerHTML = printContents;
            window.print();
            this.props.onPrint();

        }







    }


    downloadpdf = (name) => {
        const pdf = new jsPDF("p", "pt", "a4")
        pdf.html(document.getElementById('pdf'), {
            callback: function (pdf) {
                pdf.save(`${name}.pdf`);
            }
        })
    }

    render() {

        const { list, nameInput, nameInput2,
            accoumpanyNameInput, accoumpanyNameInput2,
            ageInput,

            identityCardInput,
            hospitalNumInput,
            // dateAdmissionInput,
            raceEthinicInput,
            subEthinicInput,
            originInput,
            telephoneInput,
            currentAddressInput, currentAddressInput1, currentAddressInput2,
            secondaryAddressInput, secondaryAddressInput1,
            checkboxstate,
            nameOfMedicalPractitionerInput,
            nameAndAddressOfHospitalInput,
            nameAndAddressOfHospitalInput1
        } = this.state;
        return (

            <div className="wrapper">

                <div id="pdf">
                    <div style={{ border: "0.1px solid #000", height: "100%", padding: "3px", boxSizing: "border-box " }}>
                        <div style={{ textAlign: "center", marginBottom: "2px" }} >
                            <p >
                                Form
                            </p>
                            <p >(Regulation 2)</p>

                            <p className="parahead">
                                PREVENTION AND CONTROL OF INFECTIOUS DISEASE  ACT 1988
                            </p>
                            <p className="parahead" >
                                PREVENTION AND CONTROL OF INFECTIOUS DISEASE (NOTICE FORM) (AMENDMENT) REGULATIONS  2011
                            </p>



                            <div style={{ display: "flex", justifyContent: "flex-end", marginRight: "10px" }}>
                                <div style={{ textAlign: "left" }}>
                                    <p >
                                        Notification Form: Rev/2010
                                    </p>
                                    <p >
                                        {`Serial No: 92093`}
                                    </p>
                                </div>

                            </div>
                            <h3 style={{ marginTop: "-5px", letterSpacing: "0.5px" }}>
                                NOTIFICATION OF COMMUNICABLE DISEASE TO BE REPORTED
                            </h3>
                            <i >
                                ( Section 10,Prevention And Control of Communicable Disease Act, 1988)
                            </i>


                        </div>
                        <hr />
                        <h3 style={{ marginBottom: "3px" }}>
                            A. PATIENT INFORMATION
                        </h3>
                        <hr />
                        <div style={{ padding: "2px 0" }}>

                            <div className="patientinfo1">
                                <p >
                                    1. Full Name(CAPTIAL LETTER):
                                </p>
                                <div >
                                    <table >
                                        <tbody>
                                            <tr >


                                                {/* <td>{this.state.list.FullNameValue[0]}</td> */}



                                                {/* {list.FullNameValue ? list.FullNameValue.map((c) =>
                                            <td>
                                                {c}
                                            </td>
                                        ) : ''} */}
                                                {nameInput}
                                            </tr>
                                            <tr>
                                                {nameInput2}
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="patientinfo1">
                                <p style={{ marginLeft: "10px" }} >
                                    Accompany by (Mother/Father/Guardian):<br />
                                    <i>
                                        ( If under age/without Identity Card)
                                    </i>
                                </p>
                                <div  >
                                    <table >
                                        <tbody>
                                            <tr>
                                                {accoumpanyNameInput}

                                            </tr>
                                            <tr>
                                                {accoumpanyNameInput2}

                                            </tr>
                                        </tbody>
                                    </table>
                                </div>


                            </div>
                        </div>
                        <hr />
                        {/* Identity Card Number */}
                        <div style={{ padding: "2px 0" }}>

                            <div className="patientinfo2">
                                <div className="patientinfo22">
                                    <p>
                                        2. Identity Card Number /
                                        Trevalling Document:<br />
                                        <i style={{ marginLeft: "80px" }}>(For Non Citizen)</i>
                                    </p>
                                    <div>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    &nbsp;
                                                    &nbsp;
                                                    &nbsp;
                                                    {identityCardInput}
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                </div>



                                <div className="flexR">
                                    <div className='checkbox'>
                                        {
                                            (checkboxstate?.fields === "Self") ?
                                                <div className='checkboxtick'></div> : ""
                                        }
                                    </div>
                                    {/* <input type="checkbox" checked={(checkboxstate?.fields === "Self") ? true : false} /> */}
                                    <p>Self</p>
                                </div>
                                <div className="flexR">
                                    <div className='checkbox'>
                                        {
                                            (checkboxstate?.fields === "Accompany By") ?
                                                <div className='checkboxtick'></div> : ""
                                        }
                                    </div>
                                    {/* <input type="checkbox" checked={(checkboxstate?.fields === "Accompany By") ? true : false} /> */}

                                    <p>Accompany by</p>
                                </div>
                            </div>
                            <div>
                                <div className="patientinfo2">
                                    <div className="flexR" >
                                        <p style={{ marginLeft: "10px" }} >
                                            Hospital/Clinc Reg.Number:
                                        </p>
                                        <div>
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        {hospitalNumInput}

                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <div className="flex">
                                        <p>
                                            Ward:
                                        </p>
                                        <div style={{ width: "50px", textAlign: "center", borderBottom: "1px dotted #000" }}>
                                            {list.ward}
                                        </div>
                                    </div>
                                    <div className="flexR">
                                        <p>
                                            Date of Admission:&nbsp;
                                        </p>
                                        <div>
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        {/* <td>2</td>
                                                    <td>0</td> */}

                                                        <td id="td">{list.dateAdmission ? list.dateAdmission[2].split("")[0] : ''}</td>
                                                        <td id="td">{list.dateAdmission ? list.dateAdmission[2].split("")[1] : ''}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <p>&nbsp; / &nbsp;</p>
                                        <div>
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td id="td">{list.dateAdmission ? list.dateAdmission[1].split("")[0] : ''}</td>
                                                        <td id="td">{list.dateAdmission ? list.dateAdmission[1].split("")[1] : ''}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <p>&nbsp; / &nbsp;</p>
                                        <div>
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td id="td">{list.dateAdmission ? list.dateAdmission[0].split("")[0] : ''}</td>
                                                        <td id="td">{list.dateAdmission ? list.dateAdmission[0].split("")[1] : ''}</td>
                                                        <td id="td">{list.dateAdmission ? list.dateAdmission[0].split("")[2] : ''}</td>
                                                        <td id="td">{list.dateAdmission ? list.dateAdmission[0].split("")[3] : ''}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Citizenship */}
                        <hr />
                        <div className="question3">

                            <div className="flexRH-F">
                                {/* 3. */}

                                <div style={{ padding: "2px", width: "100%" }}>
                                    <p>
                                        3. Citizenship:
                                    </p>
                                    <div style={{ marginLeft: "13px" }}>
                                        <p>
                                            Citizen
                                        </p>
                                        {/* YES */}
                                        <div className="flexRG">
                                            <div className="flexstart">
                                                <div className="flexR1">
                                                    <div className='checkbox'>
                                                        {
                                                            (checkboxstate?.citizenShip === "YES") ?
                                                                <div className='checkboxtick'></div> : ""
                                                        }
                                                    </div>
                                                    {/* <input type="checkbox" checked={(checkboxstate?.citizenShip === "YES") ? true : false} /> */}
                                                    <p>
                                                        Yes
                                                    </p>
                                                </div>

                                            </div>
                                            <div style={{ width: "100%" }} >
                                                <div className="flex_spacebtw">
                                                    <p>
                                                        Race/Ethnic:&nbsp;
                                                    </p>
                                                    <div>
                                                        <table>
                                                            <tbody>
                                                                <tr>
                                                                    {raceEthinicInput}
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>


                                                </div>

                                                <div className="flex_spacebtw">
                                                    <p>
                                                        Sub Ethnic:
                                                    </p>
                                                    <div style={{ paddingTop: "1px" }}>
                                                        <table>
                                                            <tbody>
                                                                <tr>
                                                                    &nbsp;
                                                                    &nbsp;
                                                                    {subEthinicInput}
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>

                                                </div>
                                                <i>
                                                    ( For Aborigines, Native of Sabah/Sarawak)
                                                </i>
                                            </div>


                                        </div>
                                        {/* No */}
                                        <div className="flexRG">
                                            <div className="flexstart ">
                                                <div className="flexR ">
                                                    <div className='checkbox'>
                                                        {
                                                            (checkboxstate?.citizenShip === "NO") ?
                                                                <div className='checkboxtick'></div> : ""
                                                        }
                                                    </div>
                                                    {/* <input type="checkbox" checked={(checkboxstate?.citizenShip === "NO") ? true : false} /> */}
                                                    <p>
                                                        No
                                                    </p>
                                                </div>

                                            </div>
                                            <div>

                                                <div className="flex_spacebtw">
                                                    <p>
                                                        Country of origin:&nbsp;
                                                    </p>
                                                    <div >
                                                        <table>
                                                            <tr>
                                                                {originInput}
                                                            </tr>
                                                        </table>
                                                    </div>
                                                </div>
                                                <p>
                                                    status of
                                                </p>
                                                <div className="flex_spacebtw">
                                                    <p>
                                                        Entry:
                                                    </p>
                                                    <div className="flexR">
                                                        <div className='checkbox'>
                                                            {
                                                                (checkboxstate?.Entry === "Legal") ?
                                                                    <div className='checkboxtick'></div> : ""
                                                            }
                                                        </div>
                                                        {/* <input type="checkbox" checked={(checkboxstate?.Entry === "Legal" && checkboxstate?.fields === "NO") ? true : false} /> */}
                                                        <p>
                                                            Legal
                                                        </p>
                                                    </div>
                                                    <div className="flexR">
                                                        <div className='checkbox'>
                                                            {
                                                                (checkboxstate?.Entry === "Illegal") ?
                                                                    <div className='checkboxtick'></div> : ""
                                                            }
                                                        </div>
                                                        {/* <input type="checkbox" checked={(checkboxstate?.Entry === "Illegal" && checkboxstate?.fields === "NO") ? true : false} /> */}
                                                        <p>
                                                            Illegal
                                                        </p>
                                                    </div>
                                                    <div className="flexR">
                                                        <div className='checkbox'>
                                                            {
                                                                (checkboxstate?.Entry === "Permanent Resident") ?
                                                                    <div className='checkboxtick'></div> : ""
                                                            }
                                                        </div>
                                                        {/* <input type="checkbox" checked={(checkboxstate?.Entry === "Permanent Resident" && checkboxstate?.fields === "NO") ? true : false} /> */}
                                                        <p>
                                                            Permanent Resident
                                                        </p>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                            {/* 4 5 6 7 */}
                            <div className="flexRH-Fborder">
                                <div style={{ display: "flex", flexDirection: "column", gap: "8px", padding: "3px 13px 0 13px" }}>
                                    <div className="flexR">
                                        <p>
                                            4. Gender:
                                        </p>
                                        <div className="flexR">
                                            <div className='checkbox'>
                                                {
                                                    (checkboxstate?.Gender === "Male") ?
                                                        <div className='checkboxtick'></div> : ""
                                                }
                                            </div>
                                            {/* <input type="checkbox" checked={(checkboxstate?.Gender === "Male") ? true : false} /> */}
                                            <p style={{ marginRight: "10px" }}>
                                                Male
                                            </p>
                                        </div>
                                        <div className="flexR">
                                            <div className='checkbox'>
                                                {
                                                    (checkboxstate?.Gender === "Female") ?
                                                        <div className='checkboxtick'></div> : ""
                                                }
                                            </div>
                                            {/* <input type="checkbox" checked={(checkboxstate?.Gender === "Female") ? true : false} /> */}
                                            <p>
                                                Female
                                            </p>
                                        </div>
                                    </div>
                                    <div className="flexR">
                                        <p>5. Date of Birth: &nbsp;</p>
                                        <div>
                                            <table>
                                                <tr>
                                                    &nbsp;
                                                    <td id="td">{list.dateOfBirth ? list.dateOfBirth[2].split("")[0] : ''}</td>
                                                    <td id="td">{list.dateOfBirth ? list.dateOfBirth[2].split("")[1] : ''}</td>
                                                </tr>
                                            </table>
                                        </div>
                                        <p>&nbsp; / &nbsp;</p>
                                        <div>
                                            <table>
                                                <tr>
                                                    <td id="td">{list.dateOfBirth ? list.dateOfBirth[1].split("")[0] : ''}</td>
                                                    <td id="td">{list.dateOfBirth ? list.dateOfBirth[1].split("")[1] : ''}</td>
                                                </tr>
                                            </table>
                                        </div>
                                        <p>&nbsp; / &nbsp;</p>
                                        <div>
                                            <table>
                                                <tr>
                                                    <td id="td">{list.dateOfBirth ? list.dateOfBirth[0].split("")[0] : ''}</td>
                                                    <td id="td">{list.dateOfBirth ? list.dateOfBirth[0].split("")[1] : ''}</td>
                                                    <td id="td">{list.dateOfBirth ? list.dateOfBirth[0].split("")[2] : ''}</td>
                                                    <td id="td">{list.dateOfBirth ? list.dateOfBirth[0].split("")[3] : ''}</td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="flex_spacebtw">

                                        <div className="flexR">
                                            <p> 6. Age: &nbsp; </p>
                                        </div>
                                        <div style={{ display: "flex", alignItems: "center", marginLeft: "10px" }}>
                                            <table>
                                                <tr>
                                                    {/* <td id="td"></td>
                                                    <td id="td"></td>
                                                    <td id="td"></td> */}

                                                    {ageInput}
                                                </tr>
                                            </table>
                                        </div>
                                        <div style={{ display: "flex", alignItems: "center", marginLeft: "15px" }}>
                                            <div className="flexR">
                                                <div className='checkbox'>
                                                    {
                                                        (checkboxstate?.dob === "Year") ?
                                                            <div className='checkboxtick'></div> : ""
                                                    }
                                                </div>
                                                {/* <input type="checkbox" /> */}
                                                <p>
                                                    Year
                                                </p>
                                            </div>
                                            <div className="flexR">
                                                &nbsp;
                                                <div className='checkbox'>
                                                    {
                                                        (checkboxstate?.dob === "Month") ?
                                                            <div className='checkboxtick'></div> : ""
                                                    }
                                                </div>
                                                {/* <input type="checkbox" /> */}
                                                <p>
                                                    Month
                                                </p>
                                            </div>
                                            <div className="flexR">
                                                &nbsp;
                                                <div className='checkbox'>
                                                    {
                                                        (checkboxstate?.dob === "Day") ?
                                                            <div className='checkboxtick'></div> : ""
                                                    }
                                                </div>
                                                {/* <input type="checkbox" /> */}
                                                <p>
                                                    Day
                                                </p>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="flexR">
                                        <div>
                                            <div className="flexR">
                                                <p>
                                                    7. Occupation:
                                                </p>
                                                <div style={{ width: "80px", borderBottom: "1px dotted #000" }}>
                                                    {list.occupation}
                                                </div>
                                            </div>
                                            <div>
                                                <i>
                                                    (
                                                    If unemployed, please state self reference
                                                    )
                                                </i>
                                            </div>
                                        </div>


                                    </div>
                                </div>

                            </div>
                        </div>
                        <hr />
                        {/* 8.phone & other */}
                        <div style={{ padding: "2px 0" }}>
                            <div className="phoneinfo">
                                <p>
                                    8. Telephone No. :
                                </p>
                                <div className="flex_spacebtw">
                                    <div className="flexR">
                                        <div className='checkbox'>
                                            {
                                                (checkboxstate?.Contact_Mode === "Resident") ?
                                                    <div className='checkboxtick'></div> : ""
                                            }
                                        </div>
                                        {/* <input type="checkbox" checked={(checkboxstate?.Contact_Mode === "Resident") ? true : false} /> */}
                                        <p>
                                            Resident
                                        </p>
                                    </div>
                                    <div className="flexR">
                                        <div className='checkbox'>
                                            {
                                                (checkboxstate?.Contact_Mode === "Hphone") ?
                                                    <div className='checkboxtick'></div> : ""
                                            }
                                        </div>
                                        {/* <input type="checkbox" checked={(checkboxstate?.Contact_Mode === "Hphone") ? true : false} /> */}
                                        <p>
                                            H.phone
                                        </p>
                                    </div>
                                    <div className="flexR">
                                        <div className='checkbox'>
                                            {
                                                (checkboxstate?.Contact_Mode === "Office") ?
                                                    <div className='checkboxtick'></div> : ""
                                            }
                                        </div>
                                        {/* <input type="checkbox" checked={(checkboxstate?.Contact_Mode === "Office") ? true : false} /> */}
                                        <p>
                                            Office &nbsp;
                                        </p>
                                    </div>
                                </div>

                                <div  >
                                    <table>
                                        <tr>
                                            {telephoneInput}
                                        </tr>
                                    </table>
                                </div>

                            </div>
                            <i>
                                &nbsp; (Contact Purposes)
                            </i>
                            <div>
                                <div className="patientinfo3">
                                    <div className='flex'>
                                        {/* <p>9. Current Address</p> */}
                                        <div >
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td className='tr1' colspan="6">9.Current Address:&nbsp;</td>
                                                        {currentAddressInput}
                                                    </tr>

                                                    <tr>
                                                        <td className='tr2' colspan="1"></td>
                                                        &nbsp;
                                                        {currentAddressInput1}
                                                    </tr>
                                                    <tr>
                                                        <td className='tr2' colspan="1"></td>
                                                        &nbsp;
                                                        {currentAddressInput2}
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <div style={{ marginLeft: "5px" }}>
                                        <p>
                                            10. Address of Employer/School/Colleg/university:
                                        </p>
                                        <div style={{ marginLeft: "10px" }} >
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        {secondaryAddressInput}
                                                    </tr>
                                                    <tr>
                                                        {secondaryAddressInput1}
                                                    </tr>
                                                </tbody>

                                            </table>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <hr />
                        <h3 style={{ marginBottom: "3px" }}>
                            B. DISEASE DIAGNOSIS
                        </h3>
                        <hr />
                        <div style={{ padding: "2px 0" }}>
                            <div className="diseaseDiagonsis">
                                <div style={{ paddingLeft: "14px" }}>
                                    <div className="flexR1">
                                        <div className='checkbox'>
                                            {
                                                (checkboxstate?.diseaseDiagonsis === "Poliomyelitis") ?
                                                    <div className='checkboxtick'></div> : ""
                                            }
                                        </div>
                                        <p>
                                            1. Poliomyelitis
                                        </p>
                                    </div>
                                    <div className="flexR1">
                                        <div className='checkbox'>
                                            {
                                                (checkboxstate?.diseaseDiagonsis === "Poliomyelitis") ?
                                                    <div className='checkboxtick'></div> : ""
                                            }
                                        </div>
                                        <p>
                                            2. Viral Hepatitis A
                                        </p>
                                    </div>
                                    <div className="flexR1">
                                        <div className='checkbox'>
                                            {
                                                (checkboxstate?.diseaseDiagonsis === "Poliomyelitis") ?
                                                    <div className='checkboxtick'></div> : ""
                                            }
                                        </div>
                                        <p>
                                            3. Viral Hepatitis B
                                        </p>
                                    </div>
                                    <div className="flexR1">
                                        <div className='checkbox'>
                                            {
                                                (checkboxstate?.diseaseDiagonsis === "Poliomyelitis") ?
                                                    <div className='checkboxtick'></div> : ""
                                            }
                                        </div>
                                        <p>
                                            4. Viral Hepatitis C
                                        </p>
                                    </div>
                                    <div className="flexR1">
                                        <div className='checkbox'>
                                            {
                                                (checkboxstate?.diseaseDiagonsis === "Poliomyelitis") ?
                                                    <div className='checkboxtick'></div> : ""
                                            }
                                        </div>
                                        <p>
                                            5. Viral Hepatitis <i>(Others)</i>
                                        </p>
                                    </div>
                                    <div className="flexR1">
                                        <div className='checkbox'>
                                            {
                                                (checkboxstate?.diseaseDiagonsis === "Poliomyelitis") ?
                                                    <div className='checkboxtick'></div> : ""
                                            }
                                        </div>
                                        <p>
                                            6. AIDS
                                        </p>
                                    </div>
                                    <div className="flexR1">
                                        <div className='checkbox'>
                                            {
                                                (checkboxstate?.diseaseDiagonsis === "Poliomyelitis") ?
                                                    <div className='checkboxtick'></div> : ""
                                            }
                                        </div>
                                        <p>
                                            7. Chancroid
                                        </p>
                                    </div>
                                    <div className="flexR1">
                                        <div className='checkbox'>
                                            {
                                                (checkboxstate?.diseaseDiagonsis === "Poliomyelitis") ?
                                                    <div className='checkboxtick'></div> : ""
                                            }
                                        </div>
                                        <p>
                                            8. Cholera
                                        </p>
                                    </div>
                                    <div className="flexR1">
                                        <div className='checkbox'>
                                            {
                                                (checkboxstate?.diseaseDiagonsis === "Poliomyelitis") ?
                                                    <div className='checkboxtick'></div> : ""
                                            }
                                        </div>
                                        <p>
                                            9. Dengue Fever
                                        </p>
                                    </div>
                                    <div className="flexR1">
                                        <div className='checkbox'>
                                            {
                                                (checkboxstate?.diseaseDiagonsis === "Poliomyelitis") ?
                                                    <div className='checkboxtick'></div> : ""
                                            }
                                        </div>
                                        <p>
                                            10. Dengue Haemorrhagic Fever
                                        </p>
                                    </div>
                                    <div className="flexR1">
                                        <div className='checkbox'>
                                            {
                                                (checkboxstate?.diseaseDiagonsis === "Poliomyelitis") ?
                                                    <div className='checkboxtick'></div> : ""
                                            }
                                        </div>
                                        <p>
                                            11. Diphtheria
                                        </p>
                                    </div>
                                    <div className="flexR1">
                                        <div className='checkbox'>
                                            {
                                                (checkboxstate?.diseaseDiagonsis === "Poliomyelitis") ?
                                                    <div className='checkboxtick'></div> : ""
                                            }
                                        </div>
                                        <p>
                                            12. Dysentery
                                        </p>
                                    </div>
                                    <div className="flexR1">
                                        <div className='checkbox'>
                                            {
                                                (checkboxstate?.diseaseDiagonsis === "Poliomyelitis") ?
                                                    <div className='checkboxtick'></div> : ""
                                            }
                                        </div>
                                        <p>
                                            13. Ebola
                                        </p>
                                    </div>
                                    <div className="flexR1">
                                        <div className='checkbox'>
                                            {
                                                (checkboxstate?.diseaseDiagonsis === "Poliomyelitis") ?
                                                    <div className='checkboxtick'></div> : ""
                                            }
                                        </div>
                                        <p>
                                            14. Food Poisoning
                                        </p>
                                    </div>
                                    <div className="flexR1">
                                        <div className='checkbox'>
                                            {
                                                (checkboxstate?.diseaseDiagonsis === "Poliomyelitis") ?
                                                    <div className='checkboxtick'></div> : ""
                                            }
                                        </div>
                                        <p>
                                            15. Gonorrhoea
                                        </p>
                                    </div>
                                </div>
                                <div style={{ paddingLeft: "55px" }}>
                                    <div className="flexR1">
                                        <div className='checkbox'>
                                            {
                                                (checkboxstate?.diseaseDiagonsis === "Poliomyelitis") ?
                                                    <div className='checkboxtick'></div> : ""
                                            }
                                        </div>
                                        <p>
                                            16. Hand, Food and Mouth Disease
                                        </p>
                                    </div>
                                    <div className="flexR1">
                                        <div className='checkbox'>
                                            {
                                                (checkboxstate?.diseaseDiagonsis === "Poliomyelitis") ?
                                                    <div className='checkboxtick'></div> : ""
                                            }
                                        </div>
                                        <p>
                                            17. Human Immuodeficiency Virus Infectious
                                        </p>
                                    </div>
                                    <div className="flexR1">
                                        <div className='checkbox'>
                                            {
                                                (checkboxstate?.diseaseDiagonsis === "Poliomyelitis") ?
                                                    <div className='checkboxtick'></div> : ""
                                            }
                                        </div>
                                        <p>
                                            18. Leprosy (Multibacillary)
                                        </p>
                                    </div>
                                    <div className="flexR1">
                                        <div className='checkbox'>
                                            {
                                                (checkboxstate?.diseaseDiagonsis === "Poliomyelitis") ?
                                                    <div className='checkboxtick'></div> : ""
                                            }
                                        </div>
                                        <p>
                                            19. Leprosy (Paucibacillary)
                                        </p>
                                    </div>
                                    <div className="flexR1">
                                        <div className='checkbox'>
                                            {
                                                (checkboxstate?.diseaseDiagonsis === "Poliomyelitis") ?
                                                    <div className='checkboxtick'></div> : ""
                                            }
                                        </div>
                                        <p>
                                            20. Leptospirosis
                                        </p>
                                    </div>
                                    <div className="flexR1">
                                        <div className='checkbox'>
                                            {
                                                (checkboxstate?.diseaseDiagonsis === "Poliomyelitis") ?
                                                    <div className='checkboxtick'></div> : ""
                                            }
                                        </div>
                                        <p>
                                            21. Malaria-Vivax
                                        </p>
                                    </div>
                                    <div className="flexR1">
                                        <div className='checkbox'>
                                            {
                                                (checkboxstate?.diseaseDiagonsis === "Poliomyelitis") ?
                                                    <div className='checkboxtick'></div> : ""
                                            }
                                        </div>
                                        <p>
                                            22. Malaria-Falciparum
                                        </p>
                                    </div>
                                    <div className="flexR1">
                                        <div className='checkbox'>
                                            {
                                                (checkboxstate?.diseaseDiagonsis === "Poliomyelitis") ?
                                                    <div className='checkboxtick'></div> : ""
                                            }
                                        </div>
                                        <p>
                                            23. Malaria-Malariae
                                        </p>
                                    </div>
                                    <div className="flexR1">
                                        <div className='checkbox'>
                                            {
                                                (checkboxstate?.diseaseDiagonsis === "Poliomyelitis") ?
                                                    <div className='checkboxtick'></div> : ""
                                            }
                                        </div>
                                        <p>
                                            24. Malaria-Others
                                        </p>
                                    </div>
                                    <div className="flexR1">
                                        <div className='checkbox'>
                                            {
                                                (checkboxstate?.diseaseDiagonsis === "Poliomyelitis") ?
                                                    <div className='checkboxtick'></div> : ""
                                            }
                                        </div>
                                        <p>
                                            25. Meales
                                        </p>
                                    </div>
                                    <div className="flexR1">
                                        <div className='checkbox'>
                                            {
                                                (checkboxstate?.diseaseDiagonsis === "Poliomyelitis") ?
                                                    <div className='checkboxtick'></div> : ""
                                            }
                                        </div>
                                        <p>
                                            26. Plague
                                        </p>
                                    </div>
                                    <div className="flexR1">
                                        <div className='checkbox'>
                                            {
                                                (checkboxstate?.diseaseDiagonsis === "Poliomyelitis") ?
                                                    <div className='checkboxtick'></div> : ""
                                            }
                                        </div>
                                        <p>
                                            27. Rabies
                                        </p>
                                    </div>
                                    <div className="flexR1">
                                        <div className='checkbox'>
                                            {
                                                (checkboxstate?.diseaseDiagonsis === "Poliomyelitis") ?
                                                    <div className='checkboxtick'></div> : ""
                                            }
                                        </div>
                                        <p>
                                            28. Relapsing Fever
                                        </p>
                                    </div>
                                    <div className="flexR1">
                                        <div className='checkbox'>
                                            {
                                                (checkboxstate?.diseaseDiagonsis === "Poliomyelitis") ?
                                                    <div className='checkboxtick'></div> : ""
                                            }
                                        </div>
                                        <p>
                                            29. Syphilis-Congential
                                        </p>
                                    </div>
                                    <div className="flexR1">
                                        <div className='checkbox'>
                                            {
                                                (checkboxstate?.diseaseDiagonsis === "Poliomyelitis") ?
                                                    <div className='checkboxtick'></div> : ""
                                            }
                                        </div>
                                        <p>
                                            30. Syphilis_Acquired
                                        </p>
                                    </div>
                                </div>
                                <div style={{ paddingLeft: "15px" }}>
                                    <div className="flexR1">
                                        <div className='checkbox'>
                                            {
                                                (checkboxstate?.diseaseDiagonsis === "Poliomyelitis") ?
                                                    <div className='checkboxtick'></div> : ""
                                            }
                                        </div>
                                        <p>
                                            31. Tetanus Neonatorum
                                        </p>
                                    </div>
                                    <div className="flexR1">
                                        <div className='checkbox'>
                                            {
                                                (checkboxstate?.diseaseDiagonsis === "Poliomyelitis") ?
                                                    <div className='checkboxtick'></div> : ""
                                            }
                                        </div>
                                        <p>
                                            32. Tetanus (Other)
                                        </p>
                                    </div>
                                    <div className="flexR1">
                                        <div className='checkbox'>
                                            {
                                                (checkboxstate?.diseaseDiagonsis === "Poliomyelitis") ?
                                                    <div className='checkboxtick'></div> : ""
                                            }
                                        </div>
                                        <p>
                                            33. Typhus-Scrub
                                        </p>
                                    </div>
                                    <div className="flexR1">
                                        <div className='checkbox'>
                                            {
                                                (checkboxstate?.diseaseDiagonsis === "Poliomyelitis") ?
                                                    <div className='checkboxtick'></div> : ""
                                            }
                                        </div>
                                        <p>
                                            34. Tuberculosis-PTB Smear Positive
                                        </p>
                                    </div>
                                    <div className="flexR1">
                                        <div className='checkbox'>
                                            {
                                                (checkboxstate?.diseaseDiagonsis === "Poliomyelitis") ?
                                                    <div className='checkboxtick'></div> : ""
                                            }
                                        </div>
                                        <p>
                                            35. Tuberculosis-PTB Smear Negative
                                        </p>
                                    </div>
                                    <div className="flexR1">
                                        <div className='checkbox'>
                                            {
                                                (checkboxstate?.diseaseDiagonsis === "Poliomyelitis") ?
                                                    <div className='checkboxtick'></div> : ""
                                            }
                                        </div>
                                        <p>
                                            36. Tuberculosis-Extra Pulmonary
                                        </p>
                                    </div>
                                    <div className="flexR1">
                                        <div className='checkbox'>
                                            {
                                                (checkboxstate?.diseaseDiagonsis === "Poliomyelitis") ?
                                                    <div className='checkboxtick'></div> : ""
                                            }
                                        </div>
                                        <p>
                                            37. Typhoid-Salmonella Typhi
                                        </p>
                                    </div>
                                    <div className="flexR1">
                                        <div className='checkbox'>
                                            {
                                                (checkboxstate?.diseaseDiagonsis === "Poliomyelitis") ?
                                                    <div className='checkboxtick'></div> : ""
                                            }
                                        </div>
                                        <p>
                                            38. Typhoid-Paratyphoid
                                        </p>
                                    </div>
                                    <div className="flexR1">
                                        <div className='checkbox'>
                                            {
                                                (checkboxstate?.diseaseDiagonsis === "Poliomyelitis") ?
                                                    <div className='checkboxtick'></div> : ""
                                            }
                                        </div>
                                        <p>
                                            39. Viral Encephalitis-Japanese
                                        </p>
                                    </div>
                                    <div className="flexR1">
                                        <div className='checkbox'>
                                            {
                                                (checkboxstate?.diseaseDiagonsis === "Poliomyelitis") ?
                                                    <div className='checkboxtick'></div> : ""
                                            }
                                        </div>
                                        <p>
                                            40. Viral Encephalitis-Nipah
                                        </p>
                                    </div>
                                    <div className="flexR1">
                                        <div className='checkbox'>
                                            {
                                                (checkboxstate?.diseaseDiagonsis === "Poliomyelitis") ?
                                                    <div className='checkboxtick'></div> : ""
                                            }
                                        </div>
                                        <p>
                                            41. Viral Encephalitis-(Other)
                                        </p>
                                    </div>
                                    <div className="flexR1">
                                        <div className='checkbox'>
                                            {
                                                (checkboxstate?.diseaseDiagonsis === "Poliomyelitis") ?
                                                    <div className='checkboxtick'></div> : ""
                                            }
                                        </div>
                                        <p>
                                            42. Whooping Cough / Perutussis
                                        </p>
                                    </div>
                                    <div className="flexR1">
                                        <div className='checkbox'>
                                            {
                                                (checkboxstate?.diseaseDiagonsis === "Poliomyelitis") ?
                                                    <div className='checkboxtick'></div> : ""
                                            }
                                        </div>
                                        <p>
                                            43. Yellow Fever
                                        </p>
                                    </div>
                                    <div className="flexR1">
                                        <div className='checkbox'>
                                            {
                                                (checkboxstate?.diseaseDiagonsis === "Poliomyelitis") ?
                                                    <div className='checkboxtick'></div> : ""
                                            }
                                        </div>
                                        <div className="flexR1">
                                            <p>44.Others please specify:</p>
                                            <div style={{ width: "55px", borderBottom: "1px dotted #000" }}>
                                                &nbsp;
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>

                        </div>
                        <hr />
                        <h3 style={{ lineHeight: "10px", marginBottom: "3px" }}>
                            {/* <h3 > */}
                            Beside by written notification,the following disease must be notified by telephone within 24 hours,such as:- Acute Poliomyelitis<br />,Cholera,Dengue,Diptheria,Ebola,Food&nbsp;Poisoning,Plague,Rabies&nbsp;and&nbsp;Yellow&nbsp;Fever
                        </h3>
                        <hr />
                        <div className="grid">
                            {/* 1 */}
                            <div style={{ borderRight: "1px solid #000", borderBottom: "1px solid #000", padding: "1px 1px 0 1px" }}>
                                <p>
                                    11. Case detection classification:
                                </p>
                                <div className="MarginLeft">
                                    <div style={{ display: "flex", paddingBottom: "2px" }}>
                                        <div className="flexR">
                                            <div className='checkbox'>
                                                {
                                                    (checkboxstate?.Case_Detection_Classfication === "Case") ?
                                                        <div className='checkboxtick'></div> : ""
                                                }
                                            </div>
                                            {/*   ecked={(checkboxstate?.Case_Detection_Classfication === "Case") ? true : false} /> */}
                                            <p>
                                                Case
                                            </p>
                                        </div>
                                        <div className="flexR">
                                            <div className='checkbox'>
                                                {
                                                    (checkboxstate?.Case_Detection_Classfication === "Contact") ?
                                                        <div className='checkboxtick'></div> : ""
                                                }
                                            </div>
                                            {/* <input type="checkbox" checked={(checkboxstate?.Case_Detection_Classfication === "Contact") ? true : false} /> */}
                                            <p>
                                                Contact
                                            </p>
                                        </div>
                                        <div className="flexR">
                                            <div className='checkbox'>
                                                {
                                                    (checkboxstate?.Case_Detection_Classfication === "FOMEMA") ?
                                                        <div className='checkboxtick'></div> : ""
                                                }
                                            </div>
                                            {/* <input type="checkbox" checked={(checkboxstate?.Case_Detection_Classfication === "FOMEMA") ? true : false} /> */}
                                            <p>
                                                FOMEMA*
                                            </p>
                                        </div>
                                    </div>

                                    <div className="flexR">
                                        <div className='checkbox'>
                                            {
                                                (checkboxstate?.Case_Detection_Classfication === "Screening Test") ?
                                                    <div className='checkboxtick'></div> : ""
                                            }
                                        </div>
                                        {/* <input type="checkbox" checked={(checkboxstate?.fields === "Screening Test") ? true : false} /> */}
                                        <p>
                                            Screening Test
                                        </p>
                                        <div style={{ width: "60px", borderBottom: "1px dotted #000" }}>
                                            &nbsp;
                                        </div>
                                    </div>

                                </div>

                            </div>
                            {/* 2 */}
                            <div>
                                <div style={{ borderRight: "1px solid #000", borderBottom: "1px solid #000", padding: "2px 6px" }}>
                                    <p>
                                        12. Status of Patient:

                                    </p>
                                    <div className="MarginLeft">

                                        <div className="flexR1">
                                            <div className='checkbox'>
                                                {
                                                    (checkboxstate?.Patient_Status === "Alive ") ?
                                                        <div className='checkboxtick'></div> : ""
                                                }
                                            </div>
                                            {/* <input type="checkbox" checked={(checkboxstate?.Patient_Status === "Alive ") ? true : false} /> */}
                                            <p>
                                                Live/Alive
                                            </p>
                                        </div>



                                        <div style={{ display: "flex" }} >
                                            <div className='checkbox'>
                                                {
                                                    (checkboxstate?.Patient_Status === "Dead ") ?
                                                        <div className='checkboxtick'></div> : ""
                                                }
                                            </div>
                                            {/* <input type="checkbox" checked={(checkboxstate?.Patient_Status === "Dead ") ? true : false} /> */}
                                            <div style={{ marginLeft: "5px" }} >
                                                <p>
                                                    Died
                                                </p>
                                            </div>
                                            <div>
                                                <table>
                                                    <tr>
                                                        <td id="td"></td>
                                                        <td id="td"></td>
                                                    </tr>
                                                </table>
                                            </div>
                                            <div> <p>&nbsp; - &nbsp;</p></div>

                                            <div>
                                                <table>
                                                    <tr>
                                                        <td id="td"></td>
                                                        <td id="td"></td>
                                                    </tr>
                                                </table>
                                            </div>
                                            <div> <p>&nbsp; - &nbsp;</p></div>
                                            <div>
                                                <table>
                                                    <tr>
                                                        <td id="td"></td>
                                                        <td id="td"></td>
                                                        <td id="td"></td>
                                                        <td id="td"></td>
                                                    </tr>
                                                </table>
                                            </div>

                                        </div>

                                    </div>

                                </div>
                            </div>
                            {/* 3 */}
                            <div style={{ borderBottom: "1px solid #000", padding: "2px" }}>
                                <p>
                                    13. Date of Onset:&nbsp;
                                </p>
                                <div className="flexMarginLeft">

                                    <div>
                                        <table>
                                            <tr>
                                                <td id="td">{list.dateOfOnset ? list.dateOfOnset[2].split("")[0] : ''}</td>
                                                <td id="td">{list.dateOfOnset ? list.dateOfOnset[2].split("")[1] : ''}</td>
                                            </tr>
                                        </table>
                                    </div>
                                    <p>&nbsp; - &nbsp;</p>
                                    <div>
                                        <table>
                                            <tr>
                                                <td id="td">{list.dateOfOnset ? list.dateOfOnset[1].split("")[0] : ''}</td>
                                                <td id="td">{list.dateOfOnset ? list.dateOfOnset[1].split("")[1] : ''}</td>
                                            </tr>
                                        </table>
                                    </div>
                                    <p>&nbsp; - &nbsp;</p>
                                    <div>
                                        <table>
                                            <tr>
                                                <td id="td">{list.dateOfOnset ? list.dateOfOnset[0].split("")[0] : ''}</td>
                                                <td id="td">{list.dateOfOnset ? list.dateOfOnset[0].split("")[1] : ''}</td>
                                                <td id="td">{list.dateOfOnset ? list.dateOfOnset[0].split("")[2] : ''}</td>
                                                <td id="td">{list.dateOfOnset ? list.dateOfOnset[0].split("")[3] : ''}</td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>

                            </div>
                            {/* 4 */}
                            <div style={{ borderRight: "1px solid #000", padding: "2px" }}>
                                <p>
                                    14. Laboratory Investigation:
                                </p>
                                <div className="MarginLeft">
                                    <div className="flex">
                                        <p>
                                            Investigation: (i)
                                        </p>
                                        <div style={{ fontSize: "7.3px", width: "60px", borderBottom: "1px dotted #000" }}>
                                            {"AAAAA"}
                                        </div>
                                    </div>
                                    <div className="flex">
                                        <p>
                                            (ii)
                                        </p>
                                        <div style={{ fontSize: "7.3px", width: "60px", borderBottom: "1px dotted #000" }}>
                                            {"AAAAA"}
                                        </div>
                                        <p>
                                            (iii)
                                        </p>
                                        <div style={{ fontSize: "7.3px", width: "60px", borderBottom: "1px dotted #000" }}>
                                            {"AAAAA"}
                                        </div>
                                    </div>
                                    <div>
                                        <p>
                                            Date of specimen taken:
                                        </p>
                                        <div className="flexR">

                                            <div>
                                                <table>
                                                    <tr>
                                                        {/* <td>2</td>
                                                    <td>0</td> */}

                                                        <td id="td">{list.dateOfSpecimen ? list.dateOfSpecimen[2].split("")[0] : ''}</td>
                                                        <td id="td">{list.dateOfSpecimen ? list.dateOfSpecimen[2].split("")[1] : ''}</td>
                                                    </tr>
                                                </table>
                                            </div>
                                            <p>&nbsp; - &nbsp;</p>
                                            <div>
                                                <table>
                                                    <tr>
                                                        <td id="td">{list.dateOfSpecimen ? list.dateOfSpecimen[1].split("")[0] : ''}</td>
                                                        <td id="td">{list.dateOfSpecimen ? list.dateOfSpecimen[1].split("")[1] : ''}</td>
                                                    </tr>
                                                </table>
                                            </div>
                                            <p>&nbsp; - &nbsp;</p>
                                            <div>
                                                <table>
                                                    <tr>
                                                        <td id="td">{list.dateOfSpecimen ? list.dateOfSpecimen[0].split("")[0] : ''}</td>
                                                        <td id="td">{list.dateOfSpecimen ? list.dateOfSpecimen[0].split("")[1] : ''}</td>
                                                        <td id="td">{list.dateOfSpecimen ? list.dateOfSpecimen[0].split("")[2] : ''}</td>
                                                        <td id="td">{list.dateOfSpecimen ? list.dateOfSpecimen[0].split("")[3] : ''}</td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            {/* 5 */}
                            <div style={{ borderRight: "1px solid #000", padding: "2px 6px" }}>
                                <p>
                                    15. Laboratory investigation result:
                                </p>
                                <div className="MarginLeft">

                                    <div className="flexR1">
                                        <div className='checkbox'>
                                            {
                                                (checkboxstate?.Laboratory_investigation === "positive") ?
                                                    <div className='checkboxtick'></div> : ""
                                            }
                                        </div>
                                        {/* <input type="checkbox" checked={(checkboxstate?.Laboratory_investigation === "positive") ? true : false} /> */}
                                        <p>
                                            Positive
                                        </p>
                                        (<div style={{ fontSize: "7.3px", width: "100px", borderBottom: "1px dotted #000" }}>
                                            unknown
                                        </div>)
                                    </div>



                                    <div className="flexR1">
                                        <div className='checkbox'>
                                            {
                                                (checkboxstate?.Laboratory_investigation === "negative") ?
                                                    <div className='checkboxtick'></div> : ""
                                            }
                                        </div>
                                        {/* <input type="checkbox" checked={(checkboxstate?.Laboratory_investigation === "Negative") ? true : false} /> */}
                                        <p>
                                            negative
                                        </p>
                                    </div>
                                    <div className="flexR1">
                                        <div className='checkbox'>
                                            {
                                                (checkboxstate?.Laboratory_investigation === "Pending") ?
                                                    <div className='checkboxtick'></div> : ""
                                            }
                                        </div>
                                        {/* <input type="checkbox" checked={(checkboxstate?.Laboratory_investigation === "Pending") ? true : false} /> */}
                                        <p>
                                            pending
                                        </p>
                                    </div>
                                </div>

                            </div>
                            {/* 6 */}
                            <div style={{ padding: "2px" }}>
                                <p>
                                    16. Diagnosis Status:
                                </p>
                                <div className="MarginLeft">
                                    <div className="flexR1">
                                        <div className='checkbox'>
                                            {
                                                (checkboxstate?.Diagnosis_Status === "Provisional") ?
                                                    <div className='checkboxtick'></div> : ""
                                            }
                                        </div>
                                        {/* <input type="checkbox" checked={(checkboxstate?.Diagnosis_Status === "Provisional") ? true : false} /> */}
                                        <p>
                                            Provisional/Supected
                                        </p>

                                    </div>
                                    <div className="flexR">
                                        <div className='checkbox'>
                                            {
                                                (checkboxstate?.Diagnosis_Status === "Confirmed") ?
                                                    <div className='checkboxtick'></div> : ""
                                            }
                                        </div>
                                        {/* <input type="checkbox" checked={(checkboxstate?.Diagnosis_Status === "Confirmed") ? true : false} /> */}
                                        <p>
                                            Confirmed
                                        </p>

                                    </div>
                                    <div>
                                        <p>
                                            Date of Diagnosis:
                                        </p>
                                        <div className="flexR1">

                                            <div>
                                                <table>
                                                    <tr>
                                                        {/* <td>2</td>
                                                    <td>0</td> */}

                                                        <td id="td">{list.dateOfDiagnosis ? list.dateOfDiagnosis[2].split("")[0] : ''}</td>
                                                        <td id="td">{list.dateOfDiagnosis ? list.dateOfDiagnosis[2].split("")[1] : ''}</td>
                                                    </tr>
                                                </table>
                                            </div>
                                            <p>&nbsp; - &nbsp;</p>
                                            <div>
                                                <table>
                                                    <tr>
                                                        <td id="td">{list.dateOfDiagnosis ? list.dateOfDiagnosis[1].split("")[0] : ''}</td>
                                                        <td id="td">{list.dateOfDiagnosis ? list.dateOfDiagnosis[1].split("")[1] : ''}</td>
                                                    </tr>
                                                </table>
                                            </div>
                                            <p>&nbsp; - &nbsp;</p>
                                            <div>
                                                <table>
                                                    <tr>
                                                        <td id="td">{list.dateOfDiagnosis ? list.dateOfDiagnosis[0].split("")[0] : ''}</td>
                                                        <td id="td">{list.dateOfDiagnosis ? list.dateOfDiagnosis[0].split("")[1] : ''}</td>
                                                        <td id="td">{list.dateOfDiagnosis ? list.dateOfDiagnosis[0].split("")[2] : ''}</td>
                                                        <td id="td">{list.dateOfDiagnosis ? list.dateOfDiagnosis[0].split("")[3] : ''}</td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        {/* section 5 */}
                        <hr />
                        <div style={{ padding: "1px 0" }}>
                            <div className="flexR ">
                                <div className="flex">
                                    <p>
                                        17.&nbsp;
                                    </p>
                                    <p>
                                        Relevant Clinical <br /> Information
                                    </p>
                                </div>

                                <div style={{ fontSize: "7.3px", margin: "0 10px", width: "300px", height: "20px", border: "0.1px solid #000", padding: "0 1px" }}>
                                    {list.relevantClinicalInformation}
                                </div>
                                <div style={{ display: "flex" }}>
                                    <p >
                                        18. Comment :&nbsp;
                                    </p>
                                    <div style={{ fontSize: "7.3px", margin: "0 10px", width: "100px", height: "20px", border: "0.1px solid #000", padding: "0 1px" }}>
                                        {list.Comment}
                                    </div>
                                </div>


                            </div>
                        </div>
                        {/*  C. NOTIFIER */}
                        <hr />
                        <h3 style={{ marginBottom: "3px" }} >
                            C. NOTIFIER
                        </h3>
                        <hr />

                        <div >
                            <div className="flex">
                                <p>
                                    19. Name of Medical Practitioner: &nbsp;
                                </p>


                                <div style={{ marginLeft: "10px" }}>
                                    <table>
                                        <tr>
                                            {nameOfMedicalPractitionerInput}
                                        </tr>
                                    </table>
                                </div>


                            </div>
                            <div className="flex">
                                <p>
                                    20. Name and address of Hospital/Clinic:&nbsp;
                                </p>
                                <div>
                                    <table>
                                        <tr>

                                            {nameAndAddressOfHospitalInput}

                                        </tr>
                                        <tr style={{ borderTop: "0px" }}>

                                            {nameAndAddressOfHospitalInput1}

                                        </tr>
                                    </table>
                                </div>

                            </div>
                            <div className="flex">
                                <p>
                                    21. Date of Notification: &nbsp;
                                </p>
                                <div className="flexR">

                                    <div>
                                        <table>
                                            <tr>
                                                {/* <td>2</td>
                                                    <td>0</td> */}

                                                <td id="td">{list.dateOfNotification ? list.dateOfNotification[2].split("")[0] : ''}</td>
                                                <td id="td">{list.dateOfNotification ? list.dateOfNotification[2].split("")[1] : ''}</td>
                                            </tr>
                                        </table>
                                    </div>
                                    <p>&nbsp; - &nbsp;</p>
                                    <div>
                                        <table>
                                            <tr>
                                                <td id="td">{list.dateOfNotification ? list.dateOfNotification[1].split("")[0] : ''}</td>
                                                <td id="td">{list.dateOfNotification ? list.dateOfNotification[1].split("")[1] : ''}</td>
                                            </tr>
                                        </table>
                                    </div>
                                    <p>&nbsp; - &nbsp;</p>
                                    <div>
                                        <table>
                                            <tr style={{ borderRight: "none" }}>
                                                <td id="td">{list.dateOfNotification ? list.dateOfNotification[0].split("")[0] : ''}</td>
                                                <td id="td">{list.dateOfNotification ? list.dateOfNotification[0].split("")[1] : ''}</td>
                                                <td id="td">{list.dateOfNotification ? list.dateOfNotification[0].split("")[2] : ''}</td>
                                                <td id="td">{list.dateOfNotification ? list.dateOfNotification[0].split("")[3] : ''}</td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <Grid container justify='flex-end'>


                            <div div style={{ textAlign: "center", marginRight: "20px" }}>
                                <div style={{ borderBottom: "1px dotted #000" }}>

                                </div>
                                <i>
                                    Signature of <br /> Medical Practitioner
                                </i>
                            </div>


                        </Grid>


                    </div>
                </div>
            </div >


        )
    }
}
const mapStateToProps = (state) => ({
    getData: state?.annex7Slice?.getviewpdfannex7,
});

export default connect(mapStateToProps, actions)(AnnexForm);

